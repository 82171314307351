import getWeek from 'date-fns/getWeek';
import { StationWeather, StationWeatherYear } from '../../../graphql/queries/weather/weather.types';
import { SelectOption } from '../../Select/Select.types';
import { WeatherChartFrequency, WeatherChartType, WeatherSeriesData } from './WeatherChart.types';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const groupByFrequency = ({
  data,
  frequency,
}: {
  data?: StationWeatherYear | null;
  frequency: WeatherChartFrequency;
}): WeatherSeriesData[] => {
  if (!data || !frequency) {
    return [];
  }

  if (frequency === 'weekly') {
    // first, get label from each entry using the date
    const formattedData =
      data.values?.reduce((acc, row) => {
        const date = new Date(row?.date);
        if (date instanceof Date) {
          const weekIndex = getWeek(date);
          if (weekIndex) {
            // init change only, will be updated later on
            acc.push({ x: weekIndex.toString(), value: row?.value || null, change: 0, name: row?.date });
          }
        }

        return acc;
      }, [] as WeatherSeriesData[]) || [];

    const weeks = Array.from({ length: 52 }, (_, i) => i + 1);
    const groupedData = weeks.map((week) => {
      const weekValues = formattedData.reduce((acc, row) => {
        if (row.x === week.toString() && row.value) {
          acc += row.value;
        }
        return acc;
      }, 0);
      // init change only, will be updated later on
      return { x: week.toString(), value: weekValues || null, change: 0, name: data.year?.toString() || '' };
    });

    return groupedData;
  }

  if (frequency === 'monthly') {
    // first, get label from each entry using the date
    const formattedData =
      data.values?.reduce((acc, row) => {
        const date = new Date(row?.date);
        if (date instanceof Date) {
          const monthIndex = date.getMonth();
          const monthLabel = months[monthIndex];
          if (monthLabel) {
            // init change only, will be updated later on
            acc.push({ x: monthLabel, value: row?.value || null, change: 0, name: data.year?.toString() || '' });
          }
        }

        return acc;
      }, [] as WeatherSeriesData[]) || [];

    // then, use a Map() to group values by label
    // and convert back to an array
    // const groupedData = Array.from(
    // 	formattedData.reduce((acc, row) => {
    // 		if (acc.has(row.label)) {
    // 			const currentValue = acc.get(row.label) || 0;
    // 			acc.set(row.label, currentValue + (row.value || 0));
    // 		} else {
    // 			acc.set(row.label, row.value);
    // 		}
    // 		return acc;
    // 	}, new Map<string, number | null>()),
    // ).map(([label, value]) => ({ label, value }));

    const groupedData = months.map((month) => {
      const monthValues = formattedData.reduce((acc, row) => {
        if (row.x === month && row.value) {
          acc += row.value;
        }
        return acc;
      }, 0);
      return { x: month, value: monthValues || null, change: 0, name: data.year?.toString() || '' };
    });

    return groupedData;
  }

  return [];
};

const getSeriesChange = (seriesData: WeatherSeriesData[][]): WeatherSeriesData[][] => {
  return seriesData.map((weatherSeries, i) => {
    return weatherSeries.map((row) => {
      if (seriesData[i - 1]) {
        const prevSeries = seriesData[i - 1];
        const currentValue = row.value;
        const prevValue = prevSeries.find((c) => c.x === row.x);

        if (prevValue?.value && currentValue) {
          // work out change against currentValue
          const diff = currentValue - prevValue.value;
          const change = (diff / prevValue.value) * 100;
          return { ...row, change };
        }
        return row;
      }
      return row;
    });
  });
};

export const getSeriesData = ({
  data,
  type = 'absolute',
  frequency = 'monthly',
}: {
  data?: StationWeather;
  type: WeatherChartType;
  frequency: WeatherChartFrequency;
}): WeatherSeriesData[][] => {
  const seriesData: WeatherSeriesData[][] = [];

  if (data && data.weatherData?.length) {
    const groupedSeries = data.weatherData.map((weatherEntry) => {
      return groupByFrequency({ data: weatherEntry, frequency });
    });

    const seriesWithChange = getSeriesChange(groupedSeries);

    seriesData.push(...seriesWithChange);
  }

  return seriesData;
};

export const getWeatherYearsOptions = (weatherYears?: number[]): SelectOption[] => {
  if (!weatherYears) {
    return [];
  }

  // get current year as a number
  const currentYear = new Date().getFullYear();

  return weatherYears
    .filter((year) => year !== currentYear)
    .map((year) => {
      return { label: `${year}`, value: year };
    });
};
