import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { ReportCardProps } from './ReportCard.types';

type ReportCardVariant = Pick<ReportCardProps, 'variant'>;
export const StyledReportCard = styled.div<ReportCardVariant>`
	${({ theme }) => css`
		background: ${theme.colors.black};
		border: 1px solid ${theme.colors.grey};
		cursor: pointer;
		outline: 1px solid transparent;
		position: relative;
		text-decoration: none;
		transition: all ${theme.transitions.default};
		width: 250px;
		&:hover,
		&:focus,
		&:active {
			border: 1px solid ${theme.colors.brandPrimary};
			/* outline: 1px solid ${theme.colors.brandPrimary}; */
			outline-offset: 0;
			z-index: 2;
			${ReportCardLink} {
				opacity: 1;
			}
		}
		&:not(:first-of-type) {
			margin-left: -1px;
		}
		${variant({
			variants: {
				highlighted: {
					'&::after': {
						// see https://spectrum.chat/styled-system/general/using-pseudo-elements-in-variant-object-styles~72dae117-344a-4b0a-b19a-b348e42ea241
						content: JSON.stringify(''),
						position: 'absolute',
						top: '-1px',
						right: '-1px',
						width: 0,
						height: 0,
						borderStyle: 'solid',
						borderWidth: '0 12px 12px 0',
						borderColor: `transparent ${theme.colors.brandPrimary} transparent transparent`,
					},
				},
			},
		})}
	`}
`;

export const ReportCardRow = styled.div`
	${({ theme }) => css`
		padding: ${theme.space.sm};
		position: relative;
		&:not(:last-child) {
			border-bottom: 1px solid ${theme.colors.grey};
		}
	`}
`;

export const ReportCardLabel = styled.span<{ variant?: 'dark' }>`
	${({ theme }) => css`
		color: white;
		display: block;
		font-size: 12px;
		line-height: 15px;
		${variant({
			variants: {
				dark: {
					color: theme.colors.greyLight,
				},
			},
		})}
	`}
`;

export const ReportCardValue = styled.span<{ variant?: 'large' }>`
	${({ theme }) => css`
		color: white;
		text-transform: capitalize;
		display: inline-block;
		font-size: 16px;
		line-height: 20px;
		margin-top: 4px;
		font-weight: 300;
		${variant({
			variants: {
				large: {
					fontSize: '40px',
					lineHeight: 1.1,
				},
			},
		})}
	`}
`;

export const ReportCardLink = styled.span`
	${({ theme }) => css`
		background: ${theme.colors.brandPrimary};
		color: white;
		display: flex;
		font-size: 12px;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		opacity: 0;
		right: 0;
		top: -1px;
		bottom: -1px;
		transition: opacity ${theme.transitions.default};
	`}
`;
