import React from 'react';
import { MetricData } from '../../../graphql/queries/report/report.types';
import { MetricCard } from './MetricCard/MetricCard';
import { Metrics } from './ReportMetrics.styles';

export const ReportMetrics: React.FC<{ metrics: MetricData[] }> = ({ metrics }) => {
	return (
		// //using a wrapper here to preve flex from altering the layout
		// <Wrapper>
		// 	<Wrapper mb="12px" display="flex" justifyContent="flex-start">
		// 		{report?.metrics
		// 			.filter((metric) => !metric.highlight)
		// 			.map((metric, i) => (
		// 				<MetricCard key={i} name={metric.name} value={metric.value} variant="dark" />
		// 			))}
		// 	</Wrapper>
		// 	<Wrapper display="flex" justifyContent="flex-start">
		// 		{report?.metrics
		// 			.filter((metric) => metric.highlight)
		// 			.map((metric, i) => (
		// 				<MetricCard key={i} name={metric.name} value={metric.value} variant="light" />
		// 			))}
		// 	</Wrapper>
		// </Wrapper>

		<Metrics>
			{metrics?.map((metric, i) => (
				<MetricCard
					key={i}
					name={metric.name}
					value={metric.value || 'TBC'}
					variant={metric.focus ? 'light' : 'dark'}
				/>
			))}
		</Metrics>
	);
};
