import React from 'react';
import { components } from 'react-select';
import { Select } from '../Select/Select';
import { Wrapper } from '../Wrapper/Wrapper';

//TODO: Move into separate components? Filters are currently a work-in-progress

const Option: React.FC<any> = (props) => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
    </components.Option>
  </div>
);

const MultiValueLabel: React.FC<any> = (props) => {
  const valuesLength = props.selectProps?.values?.length;
  return (
    <>
      <Wrapper display="flex" flexDirection="row">
        <span>{props.data.label},</span>
      </Wrapper>
    </>
  );
};

const MultiValueRemove: React.FC<any> = (props) => null;
const IndicatorsContainer: React.FC<any> = (props) => null;
const ValueContainer = (props: any) => (
  <components.ValueContainer {...props}>
    <Wrapper display="flex" flexDirection="column">
      <h4>{props?.selectProps?.placeholder}</h4>

      <Wrapper display="flex" width="130px" flexDirection="row" height={props.hasValue ? 'auto' : 0}>
        {props.children}
      </Wrapper>
    </Wrapper>
  </components.ValueContainer>
);

const Placeholder = () => null;

const SelectCheckboxes: React.FC<any> = ({ options, onChange, variant, portalSelectorId, fieldLabel, placeholder }) => {
  return (
    <Select
      portalSelectorId={portalSelectorId}
      defaultMenuIsOpen={true}
      closeMenuOnSelect={false}
      isMulti
      variant={variant}
      placeholder={placeholder}
      components={{ Option, MultiValueLabel, ValueContainer, MultiValueRemove, IndicatorsContainer, Placeholder }}
      options={options}
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      onChange={onChange}
    />
  );
};

export default SelectCheckboxes;
