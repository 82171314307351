import { useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GroundSurveyReport } from '../../../classes/GroundSurveyReport/GroundSurveyReport';
import { NotFound } from '../../../components/NotFound/NotFound';
import { ReportInfo } from '../../../components/Report/ReportInfo/ReportInfo';
import { ReportLegend } from '../../../components/Report/ReportLegend/ReportLegend';
import { ReportSidebar } from '../../../components/Report/ReportSidebar/ReportSidebar';
import { ReportSummary } from '../../../components/Report/ReportSummary/ReportSummary';
import { SEO } from '../../../components/SEO/SEO';
import { GET_REPORT_QUERY } from '../../../graphql/queries/report/report';
import { ReportData } from '../../../graphql/queries/report/report.types';
import { ME_QUERY } from '../../../graphql/queries/user/me';
import { UserData } from '../../../graphql/queries/user/user.types';
import { useCallbackRef } from '../../../hooks/useCallbackRef';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { toggleFeature, updateActiveProvince } from '../../../slices/igs/igs';
import { RootState } from '../../../slices/store';
import { MapContainer, StyledMap } from './ReportPage.styles';

export const ReportPage = () => {
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const history = useHistory<{ hideSummary: boolean }>();
	const { reportId } = useParams<{ reportId: string }>();
	const { map } = useSelector((state: RootState) => state.igs);

	const GSReport = useRef<GroundSurveyReport>();
	const [mapboxRef, setMapboxRef] = useCallbackRef();
	const [deckglRef, setDeckGlRef] = useCallbackRef();
	const [mapReady, setMapReady] = useState(false);
	const hideSummary = history.location.state?.hideSummary || false;

	const { data: userData, loading: userDataLoading } = useQuery<{ me: UserData }>(ME_QUERY);

	const {
		data: reportData,
		error,
		loading,
	} = useQuery<{ report: ReportData }, { id: string }>(GET_REPORT_QUERY, {
		skip: !isMounted,
		variables: { id: reportId },
	});

	const onSummaryEnd = () => {
		if (mapboxRef && deckglRef && reportData?.report) {
			GSReport.current?.createReport(reportData.report as any);
		}
	};

	useEffect(() => {
		// if refs are mounted, we can init the map
		if (mapboxRef.current && deckglRef.current && reportData?.report) {
			GSReport.current = new GroundSurveyReport({
				mapRef: mapboxRef.current,
				canvasRef: deckglRef.current,
				config: reportData.report.mapConfig,
				onHover: (name) => {
					dispatch(updateActiveProvince(name));
				},
				onReady: () => {
					setMapReady(true);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapboxRef, deckglRef, reportData]);

	useEffect(() => {
		if (map.activeFeature) {
			GSReport.current?.toggleFeature(map.activeFeature);
		}
	}, [map.activeFeature]);

	useEffect(() => {
		if (hideSummary && reportData?.report && GSReport.current) {
			GSReport.current?.createReport(reportData.report as any);
		}
	}, [hideSummary, reportData]);

	useEffect(() => {
		return () => {
			dispatch(toggleFeature(undefined));
		};
	}, []);

	if (!isMounted || loading || userDataLoading) {
		return null;
	}

	if (reportData?.report === null || error) {
		return <NotFound />;
	}

	if (!reportData?.report.active && userData?.me.role === 'USER') {
		return <NotFound />;
	}

	return (
		<StyledMap>
			<SEO title="Ground Survey - Report" />
			<MapContainer
				initial={{ opacity: 0, scale: 0.7 }}
				animate={{
					opacity: mapReady || hideSummary ? 1 : 0,
					scale: hideSummary ? 1 : mapReady && !hideSummary ? 0.8 : 0.7,
					transition: { delay: 0.3, duration: 0.4 },
				}}
			>
				<div ref={setMapboxRef}></div>
				<canvas ref={setDeckGlRef}></canvas>
			</MapContainer>
			{hideSummary && reportData && (
				<>
					<ReportSidebar reportData={reportData.report} />
					<ReportInfo />
					<ReportLegend reportData={reportData.report} />
				</>
			)}
			{!hideSummary && (
				<ReportSummary
					closeSummary={() => history.push(`/reports/${reportId}`, { hideSummary: true })}
					onAnimationComplete={onSummaryEnd}
					mapReady={mapReady}
					reportData={reportData?.report}
				/>
			)}
		</StyledMap>
	);
};
