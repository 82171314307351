import React from 'react';
import { LayoutProps, SpaceProps } from 'styled-system';
import { Wrapper } from '../../Wrapper/Wrapper';

const ContractsIcon: React.FC<SpaceProps & LayoutProps> = ({ children, ...style }) => (
  <Wrapper display="flex" {...style}>
    {children}
  </Wrapper>
);

export const EditFilterIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({
  color = '#fff',
  ...style
}) => {
  return (
    <ContractsIcon {...style}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
        <path
          fill={color}
          d="M5.05,8.5a2.5,2.5,0,0,0-2-2.45V0h-1V6.05a2.5,2.5,0,0,0,0,4.9V12h1V11A2.5,2.5,0,0,0,5.05,8.5Zm-2,1.41a1.59,1.59,0,0,1-.5.09,1.7,1.7,0,0,1-.5-.09,1.49,1.49,0,0,1,0-2.82A1.7,1.7,0,0,1,2.55,7a1.59,1.59,0,0,1,.5.09,1.49,1.49,0,0,1,0,2.82Z"
        />
        <path
          fill={color}
          d="M11.05,3.5a2.5,2.5,0,0,0-2-2.45V0h-1V1.05a2.5,2.5,0,0,0,0,4.9V12h1V6A2.5,2.5,0,0,0,11.05,3.5Zm-2,1.41a1.59,1.59,0,0,1-.5.09,1.7,1.7,0,0,1-.5-.09,1.49,1.49,0,0,1,0-2.82A1.7,1.7,0,0,1,8.55,2a1.59,1.59,0,0,1,.5.09,1.49,1.49,0,0,1,0,2.82Z"
        />
      </svg>
    </ContractsIcon>
  );
};

export const PurplePinIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({
  color = '#6e64e6',
  ...style
}) => {
  return (
    <ContractsIcon {...style}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 18">
        <g id="Group_172617" data-name="Group 172617">
          <g id="Group_172618" data-name="Group 172618">
            <path
              fill={color}
              id="Path_1645"
              data-name="Path 1645"
              d="M5.5,0A5.41,5.41,0,0,1,11,5.31C11,8.24,5.5,18,5.5,18S0,8.24,0,5.31A5.41,5.41,0,0,1,5.5,0Z"
            />
            <circle
              fill={'#222'}
              id="Ellipse_464"
              data-name="Ellipse 464"
              className="cls-2"
              cx="5.5"
              cy="5.5"
              r="3.5"
            />
          </g>
        </g>
      </svg>
    </ContractsIcon>
  );
};

export const UpDownArrowsIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({ color, ...style }) => (
  <ContractsIcon {...style}>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.859 94.858" xmlSpace="preserve">
      <g>
        <g fill="#fff">
          <path
            d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"
          />
          <path
            d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"
          />
        </g>
      </g>
    </svg>
  </ContractsIcon>
);
export const FilterIcon: React.FC<SpaceProps & LayoutProps> = (style) => {
  return (
    <ContractsIcon {...style}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 10">
        <g id="Group_172642">
          <rect fill={'#fff'} id="Rectangle_204873" data-name="Rectangle 204873" width="13" height="1" />
          <rect fill={'#fff'} id="Rectangle_204874" data-name="Rectangle 204874" x="2" y="3" width="9" height="1" />
          <rect fill={'#fff'} id="Rectangle_204875" data-name="Rectangle 204875" x="4" y="6" width="5" height="1" />
          <rect fill={'#fff'} id="Rectangle_204876" data-name="Rectangle 204876" x="6" y="9" width="1" height="1" />
        </g>
      </svg>
    </ContractsIcon>
  );
};

export const PlusIcon: React.FC<SpaceProps & LayoutProps> = (style) => {
  return (
    <ContractsIcon {...style}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 341.4 341.4"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <polygon
              fill="white"
              points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 
			341.4,149.4 		"
            />
          </g>
        </g>
      </svg>
    </ContractsIcon>
  );
};

export const CloseIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({ color = 'white', ...style }) => {
  return (
    <ContractsIcon {...style}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="10px"
        height="10px"
        viewBox="0 0 357 357"
        xmlSpace="preserve"
      >
        <g fill={color} id="close">
          <polygon
            points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 
			214.2,178.5 		"
          />
        </g>
      </svg>
    </ContractsIcon>
  );
};

export const LeftChevIcon: React.FC<SpaceProps & LayoutProps> = (style) => {
  return (
    <ContractsIcon {...style}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 407.436 407.436">
        <polygon
          fill="#fff"
          points="315.869,21.178 294.621,0 91.566,203.718 294.621,407.436 315.869,386.258 133.924,203.718 "
        />
      </svg>
    </ContractsIcon>
  );
};

export const RightChevIcon: React.FC<SpaceProps & LayoutProps> = (style) => {
  return (
    <ContractsIcon {...style}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 407.436 407.436">
        <polygon
          fill="#fff"
          points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "
        />
      </svg>
    </ContractsIcon>
  );
};

export const UpTriangleIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({
  color = '#838383',
  ...style
}) => (
  <ContractsIcon {...style}>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 123.959 123.959">
      <g>
        <path
          fill={color}
          d="M66.18,29.742c-2.301-2.3-6.101-2.3-8.401,0l-56,56c-3.8,3.801-1.1,10.2,4.2,10.2h112c5.3,0,8-6.399,4.2-10.2L66.18,29.742
		z"
        />
      </g>
    </svg>
  </ContractsIcon>
);

export const DownTriangleIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({
  color = '#838383',
  ...style
}) => (
  <ContractsIcon {...style}>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 123.959 123.958">
      <g>
        <path
          fill={color}
          d="M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56
		C125.979,34.417,123.279,28.017,117.979,28.017z"
        />
      </g>
    </svg>
  </ContractsIcon>
);

export const CircleIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({
  color = '#6e64e6',
  ...style
}) => (
  <ContractsIcon {...style}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <g>
          <path fill={color} d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z" />
        </g>
      </g>
    </svg>
  </ContractsIcon>
);

export const TableIcon: React.FC<SpaceProps & LayoutProps & { color?: string; bg?: string }> = ({
  color = '#fff',
  bg = 'grey',
  ...style
}) => (
  <ContractsIcon {...style}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 13 13">
      <g id="Rectangle_204917" data-name="Rectangle 204917">
        <rect fill={bg} width="13" height="13" />
        <path fill={color} d="M13,13H0V0H13ZM1,12H12V1H1Z" />
      </g>
      <path
        fill={color}
        d="M12,4V3H10V1H9V3H7V1H6V3H4V1H3V3H1V4H3V6H1V7H3V9H1v1H3v2H4V10H6v2H7V10H9v2h1V10h2V9H10V7h2V6H10V4ZM4,4H6V6H4ZM4,9V7H6V9ZM9,9H7V7H9ZM9,6H7V4H9Z"
      />
    </svg>
  </ContractsIcon>
);

export const MapIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({ color = '#fff', ...style }) => (
  <ContractsIcon {...style}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
      <defs>
        <clipPath id="a">
          <path d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
      <g data-name="ICON - Map" clipPath="url(#a)">
        <path fill="none" d="M0 0h12v12H0z" />
        <g data-name="Group 172017" fill={color}>
          <path
            data-name="Path 1582"
            d="M7.055.066a7.382 7.382 0 011.917 5.5c0 3.328-1.116 5.254-2.217 6.351A6 6 0 0012 5.967 6 6 0 007.055.066z"
          />
          <path
            data-name="Path 1583"
            d="M7.972 5.564c0-3.448-1.446-4.929-2.6-5.564A6 6 0 00-.004 5.967a6 6 0 005.182 5.938c1.07-.669 2.794-2.362 2.794-6.341z"
          />
        </g>
      </g>
    </svg>
  </ContractsIcon>
);

export const TickIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({ color = '#36b957', ...style }) => (
  <ContractsIcon {...style}>
    <svg id="bold" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill={color}
          d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"
        />
      </g>
    </svg>
  </ContractsIcon>
);
