import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ThemeContext } from 'styled-components';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import {
  ANALYSIS_NDVI_QUERY,
  ANALYSIS_NDVI_YEARS_QUERY,
} from '../../../graphql/queries/analysisNDVI/analysisNDVI.queries';
import { RootState } from '../../../slices/store';
import { Theme } from '../../../styles/theme';
import { Select } from '../../Select/Select';
import { SelectOption } from '../../Select/Select.types';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ChartContainer, ChartLegend, ChartLegendItem } from '../Chart.styles';
import { CurrentIcon } from '../ChartIcons/ChartIcons';
import { ChartLoader } from '../ChartLoader/ChartLoader';
import { ChartNoData } from '../ChartNoData/ChartNoData';
import { NDVIData } from './NDVIChart.types';
import { getNDVISeriesData, getNDVIYearsOptions, months } from './NDVIChart.utils';

export const NDVIChart: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  const { colors } = useContext<Theme>(ThemeContext);
  const [chartData, setChartData] = useState<NDVIData>();
  const { activeFilter } = useSelector((state: RootState) => state.iar);
  const [getNDVIData, { loading }] = useLazyQuery(ANALYSIS_NDVI_QUERY, {
    onCompleted: (d) => setChartData(d.analysisNDVI),
  });
  const [selectedYear, setSelectedYear] = useState<SelectOption>();
  const { data: yearsData, loading: yearsLoading } = useQuery(ANALYSIS_NDVI_YEARS_QUERY, {
    variables: { analysisId: analysisData.id },
  });
  const yearOptions = getNDVIYearsOptions(yearsData?.analysisNDVIYears);

  useEffect(() => {
    if (yearsData?.analysisNDVIYears.length) {
      setSelectedYear({
        label: yearsData?.analysisNDVIYears[0].year.toString(),
        value: yearsData?.analysisNDVIYears[0].year,
      });
    }
  }, [yearsData?.analysisNDVIYears]);

  useEffect(() => {
    if (selectedYear) {
      //TODO: improve level logic
      let subLevel: string | undefined = undefined;
      if (!activeFilter?.type) {
        subLevel = 'level2';
      }
      if (activeFilter?.type === 'level2') {
        subLevel = 'level3';
      }
      getNDVIData({
        variables: {
          analysisId: analysisData.id,
          area: activeFilter?.label || analysisData.coverage,
          level: activeFilter?.type || 'level1',
          year: selectedYear.value,
          subLevel,
        },
      });
    }
  }, [selectedYear, activeFilter]);

  const seriesData = getNDVISeriesData(chartData);

  return (
    <Wrapper display="flex" flexDirection="column" flex="1" overflow="hidden">
      <ChartLegend alignItems="center" justifyContent="space-between">
        <Wrapper display="flex" alignItems="center">
          <ChartLegendItem mr="md" py="sm">
            <CurrentIcon color={colors.charts.negative} width="14px" />
            Min
          </ChartLegendItem>
          <ChartLegendItem mr="md" py="sm">
            <CurrentIcon width="14px" />
            Average
          </ChartLegendItem>
          <ChartLegendItem mr="md" py="sm">
            <CurrentIcon color={colors.charts.positive} width="14px" />
            Max
          </ChartLegendItem>
        </Wrapper>
        <Wrapper display="flex" alignItems="center">
          <Wrapper as="span" mr="sm">
            Year
          </Wrapper>
          <Wrapper width="75px">
            <Select
              maxHeight={130}
              onChange={(option: SelectOption) => setSelectedYear(option)}
              options={yearOptions}
              value={selectedYear}
            />
          </Wrapper>
        </Wrapper>
      </ChartLegend>
      <ChartContainer>
        {chartData && !seriesData?.length && !loading && <ChartNoData />}
        {chartData && seriesData.length && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart margin={{ right: -40 }}>
              <CartesianGrid vertical={false} stroke={colors.charts.grid} />
              <XAxis
                allowDuplicatedCategory={false}
                tickFormatter={(tick) => months[tick]}
                dataKey="month"
                tick={{ fontSize: 12, fill: 'white' }}
                stroke={colors.charts.grid}
                tickLine={{ stroke: 'white' }}
              />
              <YAxis
                stroke={colors.charts.grid}
                yAxisId={0}
                dataKey="ndvi"
                tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
                tickLine={{ stroke: 'white' }}
                orientation="left"
                padding={{ top: 20, bottom: 0 }}
              />
              <YAxis stroke={colors.charts.grid} yAxisId={1} orientation="right" />
              {/* MAX */}
              <Line
                name="Max"
                animationDuration={600}
                data={seriesData[2]}
                type="monotone"
                dataKey="ndvi"
                fill={colors.black}
                stroke={colors.charts.positive}
                dot={{ r: 2 }}
                strokeWidth={1}
              />
              {/* AVG */}
              <Line
                name="Avg"
                animationDuration={600}
                data={seriesData[1]}
                type="monotone"
                dataKey="ndvi"
                fill={colors.black}
                stroke="#fff"
                dot={{ r: 3 }}
                strokeWidth={2}
              />

              {/* MIN */}
              <Line
                name="Min"
                animationDuration={600}
                data={seriesData[0]}
                type="monotone"
                dataKey="ndvi"
                fill={colors.black}
                stroke={colors.charts.negative}
                dot={{ r: 2 }}
                strokeWidth={1}
              />
              <Tooltip
                labelStyle={{ display: 'none' }}
                separator=": "
                filterNull={false}
                isAnimationActive={false}
                formatter={(value: any, name: any) => {
                  const val = value ? `${value.toFixed(2)}` : 'N/A';
                  return [val, name];
                }}
                contentStyle={{
                  background: colors.black,
                  border: `1px solid ${colors.grey}`,
                  strokeWidth: 1,
                  fontSize: 14,
                  marginBottom: 1,
                  marginTop: 1,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        <ChartLoader loading={loading || yearsLoading} />
      </ChartContainer>
    </Wrapper>
  );
};
