import { InitialViewStateProps } from '@deck.gl/core/lib/deck';

export const MAP_STYLE = process.env.REACT_APP_MAPBOX_STYLE;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export interface InitialViewState extends InitialViewStateProps {
  latitude: number;
  longitude: number;
  height: number;
  width: number;
}

export const INITIAL_VIEW_STATE: InitialViewState = {
  altitude: 1.5,
  bearing: 0,
  latitude: 13.186852902962059,
  longitude: 103.10093721606934,
  maxPitch: 60,
  maxZoom: 25,
  minPitch: 0,
  minZoom: 1,
  pitch: 0,
  zoom: 4.844471263262929,
  width: window.innerWidth,
  height: window.innerHeight,
};

export const VIEW_STATE_CONSTRAINTS = {
  longitude: [98, 105],
  latitude: [10, 20],
};
