import gql from 'graphql-tag';

export const GET_STATION_WEATHER_QUERY = gql`
	query stationWeather($name: String!, $years: [Int]) {
		stationWeather(name: $name, years: $years) {
			station {
				stationid
				name
			}
			weatherData {
				year
				values {
					date
					value
				}
			}
		}
	}
`;

export const GET_WEATHER_BY_STATION_LEVEL = gql`
	query weatherByStationLevel($name: String!, $yearStart: Int!, $yearEnd: Int!, $level: String!) {
		weatherByStationLevel(name: $name, yearStart: $yearStart, yearEnd: $yearEnd, level: $level) {
			weatherData {
				year
				values {
					date
					value
				}
			}
		}
	}
`;

export const GET_WEATHER_YEARS_QUERY = gql`
	query weatherYears {
		weatherYears
	}
`;
