import React from 'react';

export const AnalysisMapChangeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 80 12">
      <defs>
        <linearGradient id="linear-gradient" x1="1" x2="0" y1="0.5" y2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ff454c"></stop>
          <stop offset="0.527" stopColor="#434f5c"></stop>
          <stop offset="1" stopColor="#4eff8b"></stop>
        </linearGradient>
        <clipPath id="clip-Change">
          <path d="M0 0H80V12H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Change)">
        <path d="M0 0H80V12H0z"></path>
        <rect
          width="80"
          height="12"
          fill="url(#linear-gradient)"
          data-name="Rectangle 202184"
          rx="6"
          transform="rotate(180 40 6)"
        ></rect>
      </g>
    </svg>
  );
};
