import { motion } from 'framer-motion';
import styled from 'styled-components';
import { fontSize, space, SpaceProps } from 'styled-system';
import { InfoLabel } from '../../ReportInfo/ReportInfo.styles';
import { ReportTitle } from '../../ReportSummary/ReportSummary.styles';

export const StyledSidebarHeader = styled.header`
	color: white;
	pointer-events: all;
`;

export const ReportSidebarArrow = styled(motion.img)<SpaceProps>`
	cursor: pointer;
	height: 12px;
	${space}
`;

export const ReportSidebarHeaderTitle = styled(motion.custom(ReportTitle))`
	cursor: pointer;
	${fontSize}
`;

export const ReportSidebarHeaderLabel = styled(InfoLabel)``;
