import React, { useContext } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ThemeContext } from 'styled-components';
import { Theme } from '../../../../styles/theme';
import { WeatherChartFrequency, WeatherSeriesData } from '../WeatherChart.types';

export const WeatherChartChange: React.FC<{
  frequency: WeatherChartFrequency;
  seriesData: WeatherSeriesData[];
}> = ({ seriesData, frequency }) => {
  const { colors } = useContext<Theme>(ThemeContext);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={seriesData} margin={{ right: -40 }}>
        <XAxis
          dataKey="x"
          tick={{ fontSize: 12, fill: 'white' }}
          stroke={colors.charts.grid}
          tickLine={{ stroke: 'white' }}
          tickFormatter={(tick, index) => {
            if (frequency === 'weekly') {
              return (index + 1) % 4 === 0 || index === 0 ? tick : '';
            }

            return tick;
          }}
        />

        <CartesianGrid vertical={false} stroke={colors.charts.grid} />
        <YAxis
          stroke={colors.charts.grid}
          yAxisId={0}
          tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
          tickLine={{ stroke: 'white' }}
          tickFormatter={(tick) => `${tick}%`}
          orientation="left"
          padding={{ top: 10, bottom: 10 }}
        />
        <YAxis stroke={colors.charts.grid} yAxisId={1} orientation="right" />
        <Bar style={{ cursor: 'pointer' }} dataKey="change">
          {seriesData.map((series, i) => (
            <Cell key={i} fill={series.change > 0 ? colors.charts.positive : colors.charts.negative} />
          ))}
        </Bar>
        <Tooltip
          isAnimationActive={false}
          cursor={{ fill: 'rgba(255, 255, 255, .1)' }}
          labelStyle={{ display: 'none' }}
          formatter={(value: number) => [`${value.toFixed(2)}%`]}
          contentStyle={{
            background: colors.black,
            border: `1px solid ${colors.grey}`,
            strokeWidth: 1,
            fontSize: 14,
            marginBottom: 1,
            marginTop: 1,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        />

        <ReferenceLine y={0} stroke={colors.grey} strokeWidth={2} />
      </BarChart>
    </ResponsiveContainer>
  );
};
