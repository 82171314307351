import { gql } from '@apollo/client';

export interface UpdateCommentInput {
	projectId: string,
	contractUid: string,
	comment: string
}

export interface UpdateCommentResponse {
	updateComment: {
		author: string,
		contract: string,
		comment: string,
		createdAt: string,
		updatedAt: string,
	};
}

export const UPDATE_COMMENT_MUTATION = gql`
	mutation updateComment(
		$projectId: ID!, 
		$contractUid: ID!, 
		$comment: String!) 
		{
			updateComment(
				projectId:$projectId, 
				contractUid:$contractUid, 
				comment: $comment
			)
			{
				comment
			}
		}
`;
