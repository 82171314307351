import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

export const StyledReportLegend = styled.span`
	${({ theme }) => css`
		position: absolute;
		bottom: 0;
		font-size: 14px;
		pointer-events: none;
		line-height: 18px;
		right: 0;
		padding: ${theme.space.lg};
	`}
`;

export const CoverageLegend = styled.span`
	${({ theme }) => css`
		background: ${theme.colors.brandPrimary};
		display: inline-block;
		height: 10px;
		width: 10px;
	`}
`;

export const DotLegend = styled.span<{ variant?: 'white' | 'stroked' }>`
	${({ theme }) => css`
		display: inline-block;
		position: relative;
		width: 10px;
		height: 10px;
		&:after {
			box-sizing: border-box;
			content: '';
			background: ${theme.colors.brandPrimary};
			width: 6px;
			height: 6px;
			position: absolute;
			top: 2px;
			left: 2px;
			border-radius: 50%;
		}
		${variant({
			variants: {
				white: {
					'&:after': {
						background: 'white',
						height: '10px',
						width: '10px',
						top: 0,
						left: 0,
					},
				},
				stroked: {
					'&:after': {
						border: '1px solid white',
						background: 'transparent',
						height: '10px',
						width: '10px',
						top: 0,
						left: 0,
					},
				},
			},
		})}
	`}
`;

export const NoDataLegend = styled;

export const HealthLegend = styled.span`
	${({ theme }) => css`
		background: linear-gradient(180deg, rgba(0, 255, 0, 1) 0%, rgba(255, 0, 0, 1) 100%);
		border-radius: 10px;
		color: transparent;
		display: inline-block;
		height: 100%;
		width: 10px;
	`}
`;
