import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../../Checkbox/Checkbox';
import { Select } from '../../../Select/Select';
import { SelectOption } from '../../../Select/Select.types';
import { Wrapper } from '../../../Wrapper/Wrapper';
import {
  ANALYSIS_MAP_FREQUENCY_OPTIONS,
  ANALYSIS_MAP_VISUALISATION_OPTIONS,
  ARMapFrequency,
} from './AnalysisMapControls.constants';
import {
  AnalysisControlPanel,
  AnalysisControlWrapper,
  AnalysisLegendValues,
  StyledAnalysisMapControls,
} from './AnalysisMapControls.styles';
import { AnalysisMapControlsProps } from './AnalysisMapControls.types';
import { formatFrequencyOptions, getFilteredTiffs, getTiffTypes } from './AnalysisMapControls.utils';
import { AnalysisMapAbsoluteIcon } from './icons/AnalysisMapAbsoluteIcon';
import { AnalysisMapChangeIcon } from './icons/AnalysisMapChangeIcon';

const Switch: React.FC<{ onChange: (isToggled: boolean) => void; toggled: boolean }> = ({
  children,
  onChange,
  toggled,
}) => {
  return (
    <>
      <Wrapper as="span" style={{ fontSize: '14px', fontWeight: 'bold' }}>
        {children}
      </Wrapper>
      <Checkbox onChange={onChange} toggled={toggled} />
    </>
  );
};

export const AnalysisMapControls: React.FC<AnalysisMapControlsProps> = ({ controls, disabled, onChange }) => {
  const [selectedType, setSelectedType] = useState<SelectOption>();
  const [selectedTiff, setSelectedTiff] = useState<SelectOption | null>(null);
  const [types, setTypes] = useState<SelectOption[]>([]);
  // set default frequencies
  const [frequency, setFrequency] = useState<SelectOption<ARMapFrequency>>(ANALYSIS_MAP_FREQUENCY_OPTIONS[0]);

  useEffect(() => {
    if (controls.tiffs && controls.tiffs.length && !types.length) {
      const typesOptions = getTiffTypes(controls.tiffs);
      setTypes(typesOptions);

      // select first option by default
      if (typesOptions.length) {
        setSelectedType(typesOptions[0]);
        // once the default type has been selected, format frequencies to get count
        const freq = formatFrequencyOptions(controls.tiffs || [], typesOptions[0].value);
        setFrequency(freq[0]);
      }
    }
  }, [controls.tiffs, types]);

  useEffect(() => {
    setSelectedTiff(null);
  }, [frequency, selectedType]);

  const onTifSelected = (option: SelectOption) => {
    const tif = controls.tiffs?.find((t) => t.id === option?.value);
    setSelectedTiff(option);
    onChange({ ...controls, selectedTif: tif });
  };

  const filteredTiffs = getFilteredTiffs(controls.tiffs || [], frequency.value, selectedType?.value);
  // format frequencies on every render
  const frequencyOptions = formatFrequencyOptions(controls.tiffs || [], selectedType?.value);

  return (
    <StyledAnalysisMapControls>
      <Wrapper display="flex" alignItems="center" justifyContent="space-between" p="md">
        <AnalysisControlPanel>
          <AnalysisControlWrapper hideBorder>
            <Select
              disabled={controls.showMask || disabled}
              variant="transparent"
              width="100px"
              options={ANALYSIS_MAP_VISUALISATION_OPTIONS}
              onChange={(option) => onChange({ ...controls, selectedVis: option.value })}
              value={ANALYSIS_MAP_VISUALISATION_OPTIONS.find((v) => v.value === controls.selectedVis)}
            />
          </AnalysisControlWrapper>

          {!controls.showMask && (
            <>
              <AnalysisControlWrapper>
                {controls.selectedVis === 'absolute' && (
                  <Wrapper height="100%" flex="1" alignItems="center" display="flex" justifyContent="space-between">
                    <AnalysisLegendValues px="sm">Low</AnalysisLegendValues>
                    <AnalysisMapAbsoluteIcon />
                    <AnalysisLegendValues px="sm">High</AnalysisLegendValues>
                  </Wrapper>
                )}
                {controls.selectedVis === 'change' && (
                  <Wrapper height="100%" flex="1" alignItems="center" display="flex" justifyContent="space-between">
                    <AnalysisLegendValues px="sm">Less</AnalysisLegendValues>
                    <AnalysisMapChangeIcon />
                    <AnalysisLegendValues px="sm">More</AnalysisLegendValues>
                  </Wrapper>
                )}
              </AnalysisControlWrapper>
            </>
          )}
        </AnalysisControlPanel>
        {!!types.length && (
          <AnalysisControlPanel>
            <Wrapper as="span" display="flex" alignItems="center" px="md">
              <Switch
                onChange={() => onChange({ ...controls, showMask: !controls.showMask })}
                toggled={controls.showMask}
              >
                Show mask
              </Switch>
            </Wrapper>
            <AnalysisControlWrapper>
              <Select
                disabled={!controls.showMask || disabled}
                variant="transparent"
                width="100px"
                options={types}
                onChange={(option) => setSelectedType(option)}
                value={selectedType}
              />
            </AnalysisControlWrapper>
            <AnalysisControlWrapper>
              <Select
                disabled={!controls.showMask || disabled}
                variant="transparent"
                width="120px"
                options={frequencyOptions}
                onChange={setFrequency}
                value={frequency}
              />
            </AnalysisControlWrapper>
            <AnalysisControlWrapper>
              <Select
                disabled={!controls.showMask || disabled}
                placeholder="--"
                variant="transparent"
                width="110px"
                options={filteredTiffs}
                onChange={onTifSelected}
                value={selectedTiff}
              />
            </AnalysisControlWrapper>
          </AnalysisControlPanel>
        )}
      </Wrapper>
    </StyledAnalysisMapControls>
  );
};
