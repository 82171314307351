import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledReportSidebarMenu = styled(motion.menu)`
	${({ theme }) => css`
		margin-left: -${theme.space.md};
		overflow: scroll;
		padding: 20px ${theme.space.md};
		/* pointer-events: all; */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	`}
`;
