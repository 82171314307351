import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { Projects } from '../../components/Projects/Projects';
import { updateNavTitle } from '../../slices/nav/nav';
import { ReportPage } from './ReportPage/ReportPage';

export const IGS: React.FC = () => {
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const location = useLocation();

	useEffect(() => {
		dispatch(updateNavTitle('Ground Survey'));
	}, [dispatch]);

	return (
		<Layout>
			<AnimatePresence exitBeforeEnter>
				<Switch location={location} key={location.pathname}>
					<Route exact path={`${match.path}`}>
						<motion.div
							style={{ height: '100%' }}
							initial={{ opacity: 0, x: -10 }}
							animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
							exit={{ opacity: 0, x: -10 }}
							transition={{ duration: 0.3 }}
						>
							<Projects />
						</motion.div>
					</Route>
					<Route path={`${match.path}/:reportId`}>
						<motion.div
							style={{ height: '100%' }}
							initial={{ opacity: 1 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.3 }}
						>
							<ReportPage />
						</motion.div>
					</Route>
				</Switch>
			</AnimatePresence>
		</Layout>
	);
};
