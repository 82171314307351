import { getWeek } from 'date-fns';
import { SelectOption } from '../../Select/Select.types';
import { PriceChartData, PriceChartFrequency, PriceSeriesData } from './PriceChart.types';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const weeks = Array.from({ length: 52 }, (_, i) => i + 1);

const getSeriesChange = (seriesData: PriceSeriesData[]): PriceSeriesData[] => {
  return seriesData.map((series, i) => {
    if (!seriesData[i - 1]) {
      return series;
    }

    const currentValue = series.y;
    const prevValue = seriesData[i - 1].y;

    if (prevValue && currentValue) {
      // work out change against currentValue
      const diff = currentValue - prevValue;
      const change = (diff / prevValue) * 100;
      return { ...series, change };
    }

    return series;
  });
};

export const getPriceMonthlyData = (chartData: PriceChartData[]): PriceSeriesData[] => {
  const formattedData = chartData.reduce((acc, row) => {
    const date = new Date(row.date);
    if (date instanceof Date) {
      const monthIndex = date.getMonth();
      const monthLabel = months[monthIndex];
      if (monthLabel) {
        // init change only, will be updated later on
        acc.push({ x: monthLabel, y: row.price || 0, change: 0 });
      }
    }
    return acc;
  }, [] as PriceSeriesData[]);

  // returns data grouped by month:
  // {"Jan": {total: 5, count: 2}, "Feb": ...}
  const aggregatedData = formattedData.reduce((acc, row) => {
    if (acc[row.x]) {
      acc[row.x].total += row.y || 0;
      acc[row.x].count += 1;
    } else {
      acc[row.x] = {
        total: row.y || 0,
        count: 1,
      };
    }
    return acc;
  }, {} as { [key: string]: { total: number; count: number } });

  const averagedData: PriceSeriesData[] = months.map((month) => {
    const entry = aggregatedData[month];
    if (entry) {
      return { x: month, y: entry.total / entry.count, change: 0 };
    } else {
      return { x: month, y: null, change: 0 };
    }
  });

  return averagedData;
};

export const getPriceWeeklyData = (chartData: PriceChartData[]): PriceSeriesData[] => {
  const formattedData =
    chartData.reduce((acc, row) => {
      const date = new Date(row?.date);
      if (date instanceof Date) {
        const weekIndex = getWeek(date);
        if (weekIndex) {
          // init change only, will be updated later on
          acc.push({ x: weekIndex.toString(), y: row.price || 0, change: 0 });
        }
      }

      return acc;
    }, [] as PriceSeriesData[]) || [];

  const aggregatedData = formattedData.reduce((acc, row) => {
    if (acc[row.x]) {
      acc[row.x].total += row.y || 0;
      acc[row.x].count += 1;
    } else {
      acc[row.x] = {
        total: row.y || 0,
        count: 1,
      };
    }
    return acc;
  }, {} as { [key: string]: { total: number; count: number } });

  const averagedData = weeks.map((week) => {
    const entry = aggregatedData[week];
    const avg = entry ? entry.total / entry.count : null;
    return { x: week.toString(), y: avg, change: 0 };
  });

  return averagedData;
};

export const getPriceSeriesData = ({
  data,
  frequency,
}: {
  data?: { date: string; price: number }[];
  frequency: PriceChartFrequency;
}): PriceSeriesData[] => {
  if (!data || !data.length) {
    return [];
  }

  let seriesData: PriceSeriesData[] = [];

  if (frequency === 'monthly') {
    seriesData = getPriceMonthlyData(data);
  }

  if (frequency === 'weekly') {
    seriesData = getPriceWeeklyData(data);
  }

  const seriesWithChange = getSeriesChange(seriesData);
  return seriesWithChange;
};

export const getPriceYearsOptions = (yearsData?: { year: number }[]): SelectOption[] => {
  if (!yearsData) {
    return [];
  }

  return yearsData.map(({ year }) => {
    return {
      label: year.toString(),
      value: year,
    };
  });
};
