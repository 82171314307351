import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { Select } from '../../Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPage, updatePageLimit } from '../../../slices/icm/icm';
import { StyledContractsTableFooter, StyledContractsTableFooterInput, StyledContractsTableFooterItem } from './ContractsTableFooter.styles';
import { RootState } from '../../../slices/store';
import { LeftChevIcon, RightChevIcon } from '../ContractsIcons/ContractsIcons';
import { toast } from 'react-toastify';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

const ContractsTableFooter: React.FC<{ page?: number, maxPage?: number }> = ({ page = 1, maxPage = 1 }) => {
	const dispatch = useDispatch();
	const [isEditingPageNumber, setIsEditingPageNumber] = useState<boolean>(false);
	const [currentPageLocal, setCurrentPageLocal] = useState<number | string>(page || 1); // Used to display a value in the text input for page number
	const currentPage = useSelector((state: RootState) => state.icm.currentPage);

	const updatePageNumber = (newPage?: number) => {
		if (newPage && newPage <= maxPage) {
			return dispatch(updateCurrentPage(newPage));
		} else {
			return dispatch(updateCurrentPage(1)); // If user inputs an invalid page number, set page number to 1
		}
	}
	const pageNumberInputRef = useRef<HTMLInputElement>(null);

	useOnClickOutside(pageNumberInputRef, () => {
		if (isEditingPageNumber) {
			updateStoreFromLocalPageNumber();
		}
	});

	useEffect(() => {
		if (isEditingPageNumber) {
			setCurrentPageLocal(currentPage) // Always reset currentPageLocal to redux store value when beginning to edit
		}
	}, [isEditingPageNumber, currentPage])

	useEffect(() => { // Force currentPageLocal to match any changes from the page number value in the redux store
		if (currentPageLocal !== currentPage) {
			setCurrentPageLocal(currentPage)
		}
	}, [currentPage]) // Don't want to update on changes to currentlocalpage as it would not allow the local value to ever change

	const decrementPage = (): void => {
		if (page >= 2) {
			updatePageNumber(page - 1)
		}
	}

	const incrementPage = (): void => {
		if (page < maxPage) {
			updatePageNumber(page + 1)
		}
	}

	const updateStoreFromLocalPageNumber = () => {
		const newPageNumber = currentPageLocal;
		if (newPageNumber) {
			updatePageNumber(Number(currentPageLocal) || undefined);
			setIsEditingPageNumber(false);
		} else {
			if (currentPage > maxPage) {
				setCurrentPageLocal(1);
			} else {
				setCurrentPageLocal(currentPage);
			}
			toast.error(`Invalid page number`);
		}

	}

	const handleInputKeydown = (e: React.KeyboardEvent) => {
		// catch the code of the key pressed
		if (e.key === 'Enter') { // Return key pressed
			updateStoreFromLocalPageNumber();
		}

	}


	const pageLimit: number = useSelector((state: RootState) => state?.icm?.pageLimit)
	return (
		<StyledContractsTableFooter >
			<StyledContractsTableFooterItem>
				View:
				<Select
					variant="tableFooter"
					value={{ label: pageLimit.toString(), value: pageLimit }}
					options={[
						{ label: '25', value: 25 },
						{ label: '50', value: 50 },
						{ label: '75', value: 75 }]}
					onChange={(e) => {
						dispatch(updatePageLimit(e.value))
					}}></Select>
			</StyledContractsTableFooterItem>
			<StyledContractsTableFooterItem>
				{isEditingPageNumber ?
					<input
						min="1"
						ref={pageNumberInputRef}
						type="number"
						value={currentPageLocal}
						onKeyDown={handleInputKeydown} // User must press "return" key to submit the changed value
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const newPage = parseInt(e.target.value);
							setCurrentPageLocal(newPage || ""); // Updates local state to user can see input changing as they type
						}}
					/>
					:
					<StyledContractsTableFooterInput
						onClick={() => {
							setIsEditingPageNumber(true);
						}}
					>
						<p>{currentPage}</p>
					</StyledContractsTableFooterInput>}
				{` of ${maxPage}`}
			</StyledContractsTableFooterItem>
			<StyledContractsTableFooterItem onClick={decrementPage}>
				<LeftChevIcon width="12px" />
			</StyledContractsTableFooterItem>
			<StyledContractsTableFooterItem onClick={incrementPage}>
				<RightChevIcon width="12px" />
			</StyledContractsTableFooterItem>
		</StyledContractsTableFooter >
	);
};

export default ContractsTableFooter;