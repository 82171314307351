import { SelectOption } from '../../Select/Select.types';
import { WeatherChartFrequency, WeatherChartType } from './WeatherChart.types';

export const WEATHER_CHART_OPTIONS: SelectOption<WeatherChartType>[] = [
  { label: 'Absolute (mm)', value: 'absolute' },
  { label: '% Change (YoY)', value: 'change' },
];

export const WEATHER_CHART_FREQUENCIES: SelectOption<WeatherChartFrequency>[] = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
];
