import React from 'react';
import { useSelector } from 'react-redux';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { RootState } from '../../../slices/store';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { StyledDashboardMetadata } from './DashboardMetadata.styles';
import { getMetadata } from './DashboardMetadata.utils';

export const DashboardMetadata: React.FC<{
  analysisData: AnalysisData;
  selectedYear?: number;
  showAllMetrics?: boolean;
  useAsFilter?: boolean;
}> = ({ analysisData, selectedYear, showAllMetrics = false, useAsFilter }) => {
  // const currentYear =
  //   selectedYear ||
  //   Math.max(...analysisData.level3.map((l) => new Date(l.date).getFullYear())) ||
  //   new Date().getFullYear();
  const { activeFilter, currentYear: year } = useSelector((state: RootState) => state.iar);
  const currentYear = year || new Date().getFullYear();
  const cropType = analysisData.cropType || '-';
  const metadata = getMetadata({
    level3Data: analysisData?.level3 || [],
    filter: activeFilter?.label,
    selectedYear: currentYear,
  });

  return (
    <StyledDashboardMetadata>
      <DashboardCard
        selectedYear={currentYear}
        title={`Total ${cropType.toLowerCase()} area`}
        metadata={metadata.specificCrop}
        type="Specific Crop Area"
        unit="(ha)"
        useAsFilter={useAsFilter}
      />
      <DashboardCard
        selectedYear={currentYear}
        title="Total crop area"
        metadata={metadata.totalCropArea}
        unit="(ha)"
        type="Total Crop Area"
        useAsFilter={useAsFilter}
      />
      <DashboardCard
        factor={100}
        hideChange
        metadata={metadata.yieldIndex}
        selectedYear={currentYear}
        title={`${cropType} Crop Yield Index`}
        type="Yield Index"
        unit="(0-100)"
        useAsFilter={useAsFilter}
      />
      <DashboardCard
        hideChange
        selectedYear={currentYear}
        title={`Age of ${cropType.toLowerCase()} crop in place`}
        metadata={metadata.cropAge}
        type="Years Crop in Place"
        unit="(years)"
        useAsFilter={useAsFilter}
      />
    </StyledDashboardMetadata>
  );
};
