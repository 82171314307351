import React, { useState } from 'react';
import { AnalysisData } from '../../graphql/queries/analysis/analysis.types';
import { CHART_TYPES } from './Chart.constants';
import { ChartContainer, ChartHeader, ChartWrapper, StyledChart } from './Chart.styles';
import { ChartTypes } from './Chart.types';
import { ChartNoData } from './ChartNoData/ChartNoData';
import { ChartSelect } from './ChartSelect/ChartSelect';
import { CoverageChart } from './CoverageChart/CoverageChart';
import { HealthChart } from './HealthChart/HealthChart';
import { NDVIChart } from './NDVIChart/NDVIChart';
import { PriceChart } from './PriceChart/PriceChart';
import { WeatherChart } from './WeatherChart/WeatherChart';
import { YieldChart } from './YieldChart/YieldChart';

const ChartComponents: Partial<ChartTypes> = {
  [CHART_TYPES.Area]: CoverageChart,
  [CHART_TYPES.Precipitation]: WeatherChart,
  [CHART_TYPES.Yield]: YieldChart,
  [CHART_TYPES.Price]: PriceChart,
  [CHART_TYPES.Health]: HealthChart,
  [CHART_TYPES.NDVI]: NDVIChart,
};

export const Chart: React.FC<{ analysisData: AnalysisData; defaultChart: CHART_TYPES }> = ({
  analysisData,
  defaultChart,
}) => {
  const [chartType, setChartType] = useState<CHART_TYPES>(defaultChart);
  const ChartComponent = ChartComponents[chartType];

  if (!ChartComponent || !analysisData) {
    return (
      <ChartWrapper>
        <StyledChart>
          <ChartHeader>
            <ChartSelect
              chartType={chartType}
              analysisData={analysisData}
              onChange={(chartType) => setChartType(chartType)}
            />
          </ChartHeader>
          <ChartContainer>
            <ChartNoData showTicks={false} />
          </ChartContainer>
        </StyledChart>
      </ChartWrapper>
    );
  }

  return (
    <ChartWrapper>
      <StyledChart>
        <ChartHeader>
          <ChartSelect
            chartType={chartType}
            analysisData={analysisData}
            onChange={(chartType) => setChartType(chartType)}
          />
        </ChartHeader>
        <ChartComponent analysisData={analysisData} />
      </StyledChart>
    </ChartWrapper>
  );
};
