import { useEffect, useState } from 'react';

export const useIsMounted = (): boolean => {
	const [isMounted, setIsMouted] = useState(false);
	useEffect(() => {
		setIsMouted(true);
		return () => setIsMouted(false);
	}, []);
	return isMounted;
};
