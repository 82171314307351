import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { Wrapper } from '../Wrapper/Wrapper';

export const NotFoundWrapper = styled(Wrapper)`
	color: white;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: 0.54px;
`;

export const NotFoundIcon = styled.img<SpaceProps>`
	height: 10px;
	${space}
`;

export const NotFoundLink = styled.a`
	${({ theme }) => css`
		color: ${theme.colors.greyLight};
		font-size: 18px;
		line-height: 23px;
		letter-spacing: 0.27px;
		text-decoration: underline;
		transition: color ${theme.transitions.default};
		&:hover {
			color: white;
		}
	`}
`;
