import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { SelectOption } from '../../Select/Select.types';
import { StyledChartSelect } from './ChartSelect.styles';
import { ChartSelectProps } from './ChartSelect.types';
import { getChartOptions, getChartTypeLabel } from './ChartSelect.utils';

export const ChartSelect: React.FC<ChartSelectProps> = ({ chartType, analysisData, onChange }) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const cropType = analysisData?.cropType || 'N/A';

  useEffect(() => {
    const options = getChartOptions(analysisData);
    setOptions(options);
  }, [cropType, analysisData]);

  const value = { label: getChartTypeLabel(chartType, cropType), value: chartType };

  return (
    <StyledChartSelect>
      <ReactSelect
        components={{ IndicatorSeparator: null }}
        classNamePrefix="select"
        options={options}
        isSearchable={false}
        maxMenuHeight={210}
        value={value}
        onChange={(option) => {
          if (option) {
            onChange(option?.value);
          }
        }}
      />
    </StyledChartSelect>
  );
};
