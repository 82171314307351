import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { LOGIN_ANIMATION_VARIANTS } from '../Login/Login.constants';
import { FieldMessage } from '../Login/Login.styles';
import { P } from '../Typography/Typography';
import { NewPassword } from './NewPassword';

export const ResetPassword = () => {
	const [codeSent, setCodeSent] = useState(false);
	const { values, errors, touched, handleChange, handleSubmit, setFieldError } = useFormik({
		initialValues: {
			email: '',
			apiError: '',
		},
		onSubmit: (data: any) => {
			Auth.forgotPassword(data.email)
				.then((data) => {
					setCodeSent(true);
				})
				.catch((err) => {
					console.log(err);
					setFieldError('apiError', err.message);
				});
		},
	});
	return (
		<>
			<AnimatePresence exitBeforeEnter>
				{!codeSent ? (
					<motion.div
						key="signIn"
						variants={LOGIN_ANIMATION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={0.5}
					>
						<P mb="md">Please enter your email address</P>
						<P fontSize="14px" mb="lg">
							We'll send you a verification code so you can reset your password.
						</P>
						<form onSubmit={handleSubmit}>
							<Input
								autocomplete="on"
								label="Email"
								name="email"
								status={touched.email && errors.email ? 'error' : undefined}
								onChange={handleChange}
								value={values.email}
								mb="md"
							/>
							{Object.values(errors).length ? <FieldMessage my="md">{Object.values(errors)[0]}</FieldMessage> : null}
							<Button>Submit</Button>
						</form>
					</motion.div>
				) : (
					<motion.div
						key="signIn"
						variants={LOGIN_ANIMATION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={0.5}
					>
						<NewPassword username={values.email} />
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};
