import { RGBAColor } from '@deck.gl/core';
import { TransitionTiming } from '@deck.gl/core/lib/layer';
import { scaleLinear } from 'd3-scale';

export const HEALTH_COLOR_SCALE = scaleLinear<string, string, string>().domain([0, 100]).range(['#F00', '#0F0']);
// default color to render if health = null
export const HEALTH_DEFAULT_COLOR = 'rgb(255, 255, 255)';
export const NON_CROP_TYPE_COLOR: RGBAColor = [84, 73, 176];

interface GSConfig {
	menuSize: number;
	coverageColor: RGBAColor;
	coverageStroke: RGBAColor;
	cropRadius: number;
	nonCropRadius: number;
	noCropHealthAlpha: number;
	transitions: TransitionTiming;
}

export const GS: GSConfig = {
	menuSize: 450,
	coverageColor: [81, 77, 170],
	coverageStroke: [36, 34, 53],
	cropRadius: 6,
	nonCropRadius: 3,
	noCropHealthAlpha: 100,
	transitions: {
		duration: 300,
		type: 'interpolation',
		// 		stiffness: 0.1,
		// 		damping: 0.5,
		// 		type: 'spring',
	},
};
