import styled, { css } from 'styled-components';

export const StyledProjects = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.black};
		height: 100%;
	`}
`;

export const ProjectHeader = styled.div`
	${({ theme }) => css`
		border-bottom: 1px solid ${theme.colors.grey};
		font-size: 14px;

		padding: ${theme.space.md} ${theme.space.md};
		&:first-of-type {
			border-top: 1px solid ${theme.colors.grey};
		}
	`}
`;

export const ProjectReports = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.blackLight};
		border-bottom: 1px solid ${theme.colors.grey};
		display: flex;
		flex-wrap: wrap;
		padding: ${theme.space.md};
	`}
`;
