import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ReportData } from '../../../../graphql/queries/report/report.types';
import { Wrapper } from '../../../Wrapper/Wrapper';
import ArrowIcon from './arrow.svg';
import { ReportSidebarArrow, ReportSidebarHeaderTitle, StyledSidebarHeader } from './ReportSidebarHeader.styles';

export const ReportSidebarHeader: React.FC<{ reportData: ReportData }> = ({ reportData }) => {
	const history = useHistory();
	// const deliveryDate = report ? report.deliveryDate.split(' ') : '';

	return useMemo(
		() => (
			<StyledSidebarHeader>
				<Wrapper display="flex" alignItems="center" mb="xs">
					<ReportSidebarArrow
						onClick={() => history.goBack()}
						layout
						src={ArrowIcon}
						mr="md"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					/>

					<ReportSidebarHeaderTitle
						fontSize={{ default: '24px', md: '32px', xl: '48px' }}
						layout
						onClick={() => history.goBack()}
					>
						{reportData.name} {!reportData.active && `(Draft)`}
					</ReportSidebarHeaderTitle>
				</Wrapper>
				{/* <Wrapper as="span" mr="sm" style={{ fontSize: '15px' }}>
					<SidebarHeaderLabel>Report</SidebarHeaderLabel>
					<Mono as="span" ml="2px" fontWeight="600" fontSize="12px">
						{report?.surveyId}
					</Mono>
				</Wrapper> */}
				{/* <Wrapper as="span" mr="md" style={{ fontSize: '15px' }}>
					<SidebarHeaderLabel>{deliveryDate[0]}</SidebarHeaderLabel>
					<Mono as="span" ml="2px" fontWeight="600" fontSize="12px">
						{deliveryDate[1]}
					</Mono>
				</Wrapper> */}
			</StyledSidebarHeader>
		),
		[],
	);
};
