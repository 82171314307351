import { Layer, RGBAColor } from '@deck.gl/core';
import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers';
import { PickInfo, Position } from 'deck.gl';
import debounce from 'lodash/debounce';
import { parseToRgb } from 'polished';
import { noop } from '../../App.utils';
import { MapConfig } from '../../graphql/queries/mapConfig/mapConfig.types';
import { FeatureData, ReportData } from '../../graphql/queries/report/report.types';
import { InteractiveMap } from '../InteractiveMap/InteractiveMap';
import { GS, HEALTH_COLOR_SCALE, NON_CROP_TYPE_COLOR } from './GroundSurveyReport.constants';

export interface GeoJsonData {
  features: GeoJsonFeature[];
}

export interface GeoJsonFeature {
  type: 'Feature' | string;
  properties: {
    Name: string;
    level2: string;
    NAME_1: string;
    Idx: number;
    //TODO: process data beforehand to remove whitespaces
    'Crop Health': number;
  };
  geometry: {
    type: 'Point' | 'Polygon';
    coordinates: Position;
  };
}

export interface GroundSurveyReportProps {
  mapRef: HTMLDivElement;
  canvasRef: HTMLCanvasElement;
  config?: MapConfig;
  onHover: (name: string | undefined) => void;
  onReady: () => void;
}

export interface GSFeature extends Omit<FeatureData, 'featureData'> {
  featureData: GeoJsonData | undefined;
}

export class GroundSurveyReport {
  private geoJsonData: GeoJsonData;
  private layers: Layer<GeoJsonData, any>[] = [];
  private map: InteractiveMap;
  private reportData: ReportData;
  private provincesData: GeoJsonData;
  private features: GSFeature[] = [];
  private activeFeatureId: string | null = null;
  private onReady: () => void = noop;
  private config?: MapConfig;
  private onHover: (name: string | undefined) => void = noop;
  private shapeFile: any;

  constructor({ canvasRef, mapRef, onHover, onReady, config }: GroundSurveyReportProps) {
    this.onReady = onReady;
    this.onHover = onHover;
    this.config = config;
    this.onProvinceHover = debounce(this.onProvinceHover, 10);
    this.map = new InteractiveMap({ mapRef, canvasRef, config });
  }

  private getHealthColor = (cropHealth: number, featureId: string): { fill: RGBAColor; stroke: RGBAColor } => {
    const isActive = featureId === this.activeFeatureId;

    // only display stroke if !crop health
    if (!cropHealth) {
      const alpha = isActive ? GS.noCropHealthAlpha : 0;
      return { fill: [0, 0, 0, 0], stroke: [255, 255, 255, alpha] };
    }

    const alpha = isActive ? 255 : 0;
    const rgbColor = HEALTH_COLOR_SCALE(cropHealth);
    const { red, green, blue } = parseToRgb(rgbColor);
    return { fill: [red, green, blue, alpha], stroke: [0, 0, 0, 0] };
  };

  private getLocationColor = (cropName: string, featureId: string): RGBAColor => {
    const cropColor = cropName === this.reportData.cropType ? [255, 255, 255] : NON_CROP_TYPE_COLOR;
    const alpha = featureId === this.activeFeatureId ? 255 : 0;
    return [...cropColor, alpha] as RGBAColor;
  };

  private onProvinceHover(d: PickInfo<GeoJsonFeature>) {
    this.onHover(d?.object?.properties?.NAME_1 || '');
  }

  //TODO: create Custom layer to handle data
  private generateLayers() {
    const layers: any[] = this.features.flatMap((feature, i) => {
      switch (feature.type) {
        case 'coverage':
          return new GeoJsonLayer<GeoJsonFeature>({
            id: `deckgl-feature-${feature.id}`,
            data: this.provincesData.features,
            //@ts-ignore
            onHover: (d) => this.onProvinceHover(d),
            //@ts-ignore
            getFillColor: this.activeFeatureId === feature.id ? [...GS.coverageColor, 255] : [...GS.coverageColor, 0],
            pickable: true,
            //@ts-ignore
            getLineColor: this.activeFeatureId === feature.id ? [...GS.coverageStroke, 255] : [...GS.coverageStroke, 0],
            getLineWidth: 1,
            lineWidthUnits: 'pixels',
            updateTriggers: {
              getFillColor: this.activeFeatureId,
              getLineColor: this.activeFeatureId,
            },
            stroked: true,
            filled: true,
            transitions: {
              getFillColor: GS.transitions,
              getLineColor: GS.transitions,
            },
          });
        case 'curated:':
          return new ScatterplotLayer<GeoJsonFeature>({
            id: `deckgl-feature-${feature.id}`,
            //@ts-ignore
            data: this.features[i].featureData?.features,
            getFillColor: (d) => this.getHealthColor(d.properties['Crop Health'], feature.id).fill,
            getPolygonOffset: (v) => [0, v + 10],
            getPosition: (d) => d.geometry.coordinates,
            getRadius: 6,
            radiusScale: 10,
            radiusUnits: 'meters',
            radiusMinPixels: 6,
            transitions: {
              getFillColor: GS.transitions,
            },
            updateTriggers: {
              getFillColor: this.activeFeatureId,
            },
            //@ts-ignore
            parameters: {
              //@ts-ignore
              depthTest: false,
            },
          });
        case 'location':
          return new ScatterplotLayer<GeoJsonFeature>({
            id: `deckgl-feature-${feature.id}`,
            //@ts-ignore
            data: this.features[i].featureData?.features,
            getFillColor: (d) => this.getLocationColor(d.properties.Name, feature.id),
            getPosition: (d) =>
              d.properties.Name === this.reportData.cropType
                ? [d.geometry.coordinates[0], d.geometry.coordinates[1], 10]
                : d.geometry.coordinates,
            getRadius: (d) => (d.properties.Name === this.reportData.cropType ? GS.cropRadius : GS.nonCropRadius),
            radiusUnits: 'pixels',
            radiusMinPixels: 1,
            transitions: {
              getFillColor: GS.transitions,
            },
            updateTriggers: {
              getFillColor: this.activeFeatureId,
            },
          });
        case 'healthIndex':
          return new ScatterplotLayer<GeoJsonFeature>({
            id: `deckgl-feature-${feature.id}`,
            data: this.features[i].featureData?.features,
            stroked: true,
            lineWidthMinPixels: 1,
            getLineWidth: 2,
            getLineColor: (d) => this.getHealthColor(d.properties['Crop Health'], feature.id).stroke,
            getFillColor: (d) => this.getHealthColor(d.properties['Crop Health'], feature.id).fill,
            getPosition: (d) =>
              d.properties['Crop Health']
                ? [d.geometry.coordinates[0], d.geometry.coordinates[1], 15]
                : [d.geometry.coordinates[0], d.geometry.coordinates[1], 10],
            getRadius: GS.cropRadius,
            radiusUnits: 'pixels',
            radiusMinPixels: 1,
            transitions: {
              getFillColor: GS.transitions,
              getLineColor: GS.transitions,
            },
            updateTriggers: {
              getFillColor: this.activeFeatureId,
              getLineColor: this.activeFeatureId,
            },
          });
        // case 'curated:':
        // 	return new ScatterplotLayer<GeoJsonFeature>({
        // 		id: `deckgl-feature-${feature.id}`,
        // 		data: this.features[i].featureData?.features,
        // 		stroked: true,
        // 		lineWidthMinPixels: 1,
        // 		getLineWidth: 2,
        // 		getLineColor: (d) => this.getHealthColor(d.properties['Crop Health'], feature.id).stroke,
        // 		getFillColor: (d) => this.getHealthColor(d.properties['Crop Health'], feature.id).fill,
        // 		getPosition: (d) =>
        // 			d.properties['Crop Health']
        // 				? [d.geometry.coordinates[0], d.geometry.coordinates[1], 15]
        // 				: [d.geometry.coordinates[0], d.geometry.coordinates[1], 10],
        // 		getRadius: GS.cropRadius,
        // 		radiusUnits: 'pixels',
        // 		radiusMinPixels: 1,
        // 		transitions: {
        // 			getFillColor: GS.transitions,
        // 			getLineColor: GS.transitions,
        // 		},
        // 		updateTriggers: {
        // 			getFillColor: this.activeFeatureId,
        // 			getLineColor: this.activeFeatureId,
        // 		},
        // 	});
        default:
          return [];
      }
    });
    this.layers = layers;
  }

  public toggleFeature(featureId: string) {
    this.activeFeatureId = featureId;
    //TODO: generate fadeOut before flyTo?
    this.generateLayers();

    this.map.setLayers(this.layers);

    const feature = this.features.find((f) => f.id === featureId);

    if (feature?.bbox && feature.bbox.length) {
      this.map.flyTo(feature.bbox);
    } else {
      this.map.flyToStart();
    }
  }

  public async createReport(reportData: ReportData) {
    // make sure report can't be created more than once
    // and prevent report from loading on mobile
    if (this.reportData) {
      return;
    }
    //TODO: implement error messages - snack notifications?
    if (!reportData) {
      throw new Error('Missing report data.');
    }

    this.reportData = reportData;

    if (!reportData.geoJsonReportUrl) {
      throw new Error('The GeoJSON file cannot be found.');
    }

    // download geojson report first
    try {
      const geoJsonSource = await fetch(reportData.geoJsonReportUrl);
      const geoJsonData = await geoJsonSource.json();

      this.geoJsonData = geoJsonData;
    } catch (e) {
      throw new Error(`Could not fetch GeoJSON file: ${e}`);
    }

    // then download provinces data
    try {
      if (this.config?.shapeFileUrl) {
        const shapeData = await fetch(this.config.shapeFileUrl);
        this.shapeFile = await shapeData.json();
      } else {
        const shapeData = await fetch('/data/thailand.json');
        this.shapeFile = await shapeData.json();
      }

      // TODO: make code more bulletproof
      const coveredProvinces = Array.from(
        this.geoJsonData.features.reduce((prev, crop) => {
          prev.add(crop.properties.level2);
          return prev;
        }, new Set()),
      );

      this.provincesData = {
        ...this.shapeFile,
        //@ts-ignore
        features: this.shapeFile.features.filter((feature) => {
          return coveredProvinces.includes(feature.properties.NAME_1);
        }),
      };
    } catch (e) {
      throw new Error(`Could not fetch provinces data: ${e}`);
    }

    //then parse features and download required files
    try {
      const features = await Promise.all(
        this.reportData.features.map(async (f) => {
          // copy data and assign main geojson file as featureData
          const feature: GSFeature = Object.assign({}, f, { featureData: this.geoJsonData });
          // if different featureData is specified, load the file and overwrite props
          if (f.featureDataUrl) {
            const featureSource = await fetch(f.featureDataUrl);
            const featureData = await featureSource.json();
            feature.featureData = featureData;
          }
          if (f.type === 'healthIndex') {
            feature.featureData = {
              features: this.geoJsonData.features.filter((ft) => ft.properties.Name === this.reportData.cropType),
            };
          }

          return feature;
        }),
      );
      //TODO: order features by ID to follow CMS order
      this.features = features;
    } catch (e) {
      throw new Error('There was an error while parsing features.');
    }

    this.generateLayers();
    this.map.setLayers(this.layers);

    this.onReady();
  }

  get isMobile() {
    //TODO: test and come up with more checks
    const { innerHeight, innerWidth } = window;
    // excude iPads from list (iPad min res = 768x1024)
    return (innerHeight < 768 && innerWidth < 1024) || (innerHeight < 1024 && innerWidth < 768);
  }
}
