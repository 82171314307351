import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { SelectOption } from '../../Select/Select.types';
import { CHART_TYPES } from '../Chart.constants';

export const getChartTypeLabel = (chartType: CHART_TYPES, cropType = 'N/A') => {
  const ChartTypesLabels = {
    [CHART_TYPES.Area]: `Area (${cropType})`,
    [CHART_TYPES.Precipitation]: `Precipitation`,
    [CHART_TYPES.Yield]: `Yield (${cropType})`,
    [CHART_TYPES.Price]: `Price (${cropType})`,
    [CHART_TYPES.Health]: `Health (${cropType})`,
    [CHART_TYPES.NDVI]: `NDVI`,
  };

  return ChartTypesLabels[chartType] || chartType;
};

export const getChartOptions = (analysisData?: AnalysisData): SelectOption[] => {
  const cropType = analysisData?.cropType || 'N/A';

  // these 2 options should always be available by default
  const defaultOptions = [
    { label: getChartTypeLabel(CHART_TYPES.Area, cropType), value: CHART_TYPES.Area },
    { label: getChartTypeLabel(CHART_TYPES.Precipitation, cropType), value: CHART_TYPES.Precipitation },
  ];

  const availableCharts = Object.values(CHART_TYPES)
    // filter out non available types
    .filter((v) => analysisData?.charts[v])
    .map((v) => {
      return { label: getChartTypeLabel(v, cropType), value: v };
    });

  return [...defaultOptions, ...availableCharts];
};
