import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import {
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  fontSize,
  FontSizeProps,
  layout,
  LayoutProps,
  lineHeight,
  LineHeightProps,
  space,
  SpaceProps,
} from 'styled-system';
import { H1, Legend } from '../../Typography/Typography';
export const StyledSummary = styled.div<SpaceProps>`
  ${({ theme }) => css`
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    left: 0;
    overflow: scroll;
    position: absolute;
    top: 0;
    width: 100%;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
  ${space}
`;

export const SummaryCTA = styled(Legend)`
  background: none;
  border: 0;
  transition: opacity 0.2s ease-out;
  :not(:disabled) {
    cursor: pointer;
  }
  &:hover:not(:disabled) {
    opacity: 1;
  }
`;

export const ReportTitle = styled(H1)`
  ${({ theme }) => css`
    font-size: 24px;
    ${theme.mediaQueries.md} {
      font-size: 32px;
    }
    ${theme.mediaQueries.xl} {
      font-size: 48px;
    }
  `}
`;

export const SummaryIntro = styled.h1<SpaceProps & LineHeightProps>`
  ${({ theme }) => css`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    ${theme.mediaQueries.sm} {
      font-size: 26px;
      line-height: 34px;
    }
    ${theme.mediaQueries.lg} {
      font-size: 36px;
      line-height: 42px;
    }

    ${compose(lineHeight, space)}
  `}
`;

export const SummaryWrapper = styled(motion.div)<DisplayProps & LayoutProps & FlexboxProps & SpaceProps>`
  ${compose(display, flexbox, space, layout)}
`;

export const MetadataTitle = styled.strong<FontSizeProps & SpaceProps>`
  ${({ theme }) => css`
    color: ${theme.colors.greyLight};
    ${compose(fontSize, space)}
  `}
`;

export const MetadataValue = styled.p<FontSizeProps & SpaceProps>`
  font-weight: 300;
  text-transform: capitalize;
  ${compose(fontSize, space)}
`;
