import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { StyledSidebarItem } from './SidebarItem.styles';

export const SidebarItem: React.FC<{ name: string; link: string }> = ({ name, link }) => {
	const location = useLocation();
	const active = location.pathname.includes(link);
	return (
		<StyledSidebarItem variant={active ? 'active' : undefined}>
			<RouterLink to={link}>{name}</RouterLink>
		</StyledSidebarItem>
	);
};
