import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { ARMap } from '../../../classes/ARMap/ARMap';
import { AnalysisData, Level3Data } from '../../../graphql/queries/analysis/analysis.types';
import { useCallbackRef } from '../../../hooks/useCallbackRef';
import { AnalysisFilter, updateActiveFilter, updateMetadataFilter } from '../../../slices/iar/iar.slice';
import { RootState } from '../../../slices/store';
import { Loader } from '../../Loader/Loader';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ANALYSIS_MAP_CONTROLS } from './AnalysisMap.constants';
import { AnalysisMapContainer, AnalysisMapLoader } from './AnalysisMap.styles';
import { ARMapMetadata, TerracottaDataset } from './AnalysisMap.types';
import { getMapMetadata, getTiffCollection } from './AnalysisMap.utils';
import { AnalysisMapControls } from './AnalysisMapControls/AnalysisMapControls';

export type ARMapVisualisation = 'absolute' | 'change';
export interface ARMapControls {
  // returns level & area
  activeFilter: AnalysisFilter | undefined;
  // stores metric used as filter
  metadataFilter: keyof Level3Data | undefined;
  showMask: boolean;
  selectedVis: ARMapVisualisation;
  selectedYear: number | undefined;
  selectedTif?: TerracottaDataset;
  tiffs: TerracottaDataset[] | undefined;
}

export const AnalysisMap: React.FC<{ analysisData: AnalysisData; onYearUpdate: (year?: number) => void }> = ({
  analysisData,
  onYearUpdate,
}) => {
  // Map refs
  const Map = useRef<ARMap>();
  const [mapboxRef, setMapboxRef] = useCallbackRef();
  const [deckglRef, setDeckGlRef] = useCallbackRef();

  const dispatch = useDispatch();
  const metadata = useRef<ARMapMetadata[]>([]);
  const { metadataFilter, activeFilter, currentYear } = useSelector((state: RootState) => state.iar);
  const [mapReady, setMapReady] = useState(false);
  const [mapControls, setControls] = useState<ARMapControls>({ ...ANALYSIS_MAP_CONTROLS, activeFilter });

  // get tiff collection on mount
  useEffect(() => {
    const getTiffs = async () => {
      const allTiffs = await getTiffCollection(analysisData?.project.id);
      setControls({ ...mapControls, tiffs: allTiffs });
    };

    // generate metadata before fetching to reduce pressure on thread
    metadata.current = getMapMetadata(analysisData);
    getTiffs();

    // reset on unmount
    return () => {
      dispatch(updateMetadataFilter(undefined));
    };
  }, []);

  // once everything is mounted and tiffs list has been downloaded, create map and assign metadata
  useEffect(() => {
    if (mapboxRef.current && deckglRef.current && mapControls.tiffs && !Map.current) {
      Map.current = new ARMap({
        canvasRef: deckglRef.current,
        mapRef: mapboxRef.current,
        metadata: metadata.current,
        controls: mapControls,
        onMapClick: (d) => {
          // simulate blur event
          if (!d.object) {
            dispatch(updateActiveFilter(undefined));
          }
        },
        onClick: (d) => {
          if (d) {
            dispatch(updateActiveFilter({ label: d, type: 'level3' }));
          }
        },
        onReady: () => {
          dispatch(updateMetadataFilter('Specific Crop Area'));
          setMapReady(true);
        },
        projectId: analysisData.project.id,
        config: analysisData.mapConfig,
      });
    }
  }, [mapboxRef, deckglRef, analysisData, dispatch, mapControls, Map]);

  // update local controls on AnalysisControls update
  const onControlsUpdate = (controls: ARMapControls) => {
    setControls({
      ...mapControls,
      ...controls,
    });
  };

  // update map on controls update
  useEffect(() => {
    if (Map.current) {
      ReactGA.event({ category: 'IAR Map', action: 'Toggled controls' });
      Map.current.updateControls({ ...mapControls, metadataFilter, activeFilter, selectedYear: currentYear });
    }
    // onYearUpdate(mapControls.selectedYear);
  }, [mapControls, metadataFilter, activeFilter, currentYear]);

  //if Escape pressed, reset level3 filter
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      dispatch(updateActiveFilter(undefined));
    }
  };

  return (
    <Wrapper display="flex" height="100%" onKeyDown={onKeyDown}>
      <Wrapper flex="1 1 75%" height="100%" position="relative">
        {/* Show loader while loads */}
        <AnimatePresence>
          {!mapReady && (
            <AnalysisMapLoader exit={{ opacity: 0 }} initial={{ opacity: 1 }}>
              <Loader />
            </AnalysisMapLoader>
          )}
        </AnimatePresence>

        {/* {mapReady && ( */}
        <>
          <AnalysisMapControls disabled={!mapReady} controls={mapControls} onChange={onControlsUpdate} />
          {/* <AnalysisMapLegend
            selectedYear={mapControls.selectedYear}
            showLegend={!mapControls.showMask}
            type={mapControls.selectedVis}
          /> */}
        </>
        {/* )} */}

        <AnalysisMapContainer>
          <div style={{ opacity: mapReady ? 1 : 0 }} ref={setMapboxRef}></div>
          <canvas style={{ opacity: mapReady ? 1 : 0 }} ref={setDeckGlRef}></canvas>
        </AnalysisMapContainer>
      </Wrapper>
    </Wrapper>
  );
};
