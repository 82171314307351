import { format } from 'date-fns';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ReportCardLabel, ReportCardLink, ReportCardRow, ReportCardValue, StyledReportCard } from './ReportCard.styles';
import { ReportCardProps } from './ReportCard.types';

export const ReportCard: React.FC<ReportCardProps> = ({ rId, report, variant }) => {
	return (
		<StyledReportCard as={RouterLink} to={`/reports/${report.id}`} variant={variant}>
			<ReportCardRow>
				{report?.name}
				<ReportCardLink>Open survey</ReportCardLink>
			</ReportCardRow>
			<ReportCardRow>
				<Wrapper display="flex">
					<Wrapper flex="1">
						<ReportCardLabel variant="dark">Report no</ReportCardLabel>
						<ReportCardValue>{rId.toString().padStart(3, '0')}</ReportCardValue>
					</Wrapper>
					<Wrapper flex="1">
						<ReportCardLabel variant="dark">Date delivered</ReportCardLabel>
						<ReportCardValue>
							{report?.deliveryDate ? format(new Date(report?.deliveryDate), 'MM/yyyy') : '-'}
						</ReportCardValue>
					</Wrapper>
				</Wrapper>
				<Wrapper display="flex" mt="md">
					<Wrapper flex="1">
						<ReportCardLabel variant="dark">Coverage</ReportCardLabel>
						<ReportCardValue>{report?.coverage || '-'}</ReportCardValue>
					</Wrapper>
					<Wrapper flex="1">
						<ReportCardLabel variant="dark">Crop</ReportCardLabel>
						<ReportCardValue>{report?.cropType || '-'}</ReportCardValue>
					</Wrapper>
				</Wrapper>
			</ReportCardRow>
			{/* <ReportCardRow>
				<ReportCardLabel>Insights</ReportCardLabel>
				<ReportCardValue variant="large">
					{report?.features?.filter((f) => f.type?.startsWith('curated:')).length || 0}
				</ReportCardValue>
			</ReportCardRow> */}
		</StyledReportCard>
	);
};
