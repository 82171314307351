import { AnalysisLevel3, Level3Data } from '../../../graphql/queries/analysis/analysis.types';
import { AnalysisCardData, AnalysisMetadata } from './DashboardMetadata.types';

/**
 *  Compute change based on actual & previous values and return full metadata
 * @param {AnalysisMetadata} baseMetadata
 * @param {number} previousValue
 * @return {*}  {AnalysisMetadata}
 */
const getMetadataChange = (baseMetadata: AnalysisMetadata, previousValue: number): AnalysisMetadata => {
	const change = baseMetadata.currentValue - previousValue;
	const percentage = (change / previousValue) * 100 || 0;
	return { ...baseMetadata, previousValue, change: percentage || 0 };
};

const aggregateData = (data: Level3Data[]): Level3Data => {
	return data.reduce((acc, row) => {
		Object.keys(row).forEach((key) => {
			const currentKey = key as keyof Level3Data;
			// no need to aggregate names
			if (currentKey !== 'Province Name') {
				// create key if not done before
				if (!acc[currentKey]) {
					acc[currentKey] = 0;
				}

				// add value to accumulator and prevent null values
				acc[currentKey] += row[currentKey] || 0;
			}
		});
		return acc;
	}, {} as Level3Data);
};

/**
 * Get level3 data based on date.
 * Requires date to be a DateTime string.
 * @param {AnalysisLevel3[]} data
 * @param {number} year
 * @return {*}  {(AnalysisLevel3 | undefined)}
 */
const getDataByYear = (data: AnalysisLevel3[], year: number): AnalysisLevel3 | undefined => {
	return data.find((row) => {
		const date = new Date(row.date);
		return date instanceof Date ? date.getFullYear() === year : false;
	});
};

const getMetadataValue = (value: number | null, dataLength: number): number => {
	return value ? value / dataLength : 0;
};

/**
 * Aggregates data based on `year` parameter.
 * Returns default data if data isn't found.
 * @param {AnalysisLevel3[]} level3Data
 * @param {number} selectedYear
 * @return {*}  {AnalysisCardsMetadata}
 */
const getAggregatedMetadata = (level3Data: AnalysisLevel3[], selectedYear: number): AnalysisCardData => {
	const DEFAULT_METADATA: AnalysisMetadata = { currentValue: 0, change: 0, previousValue: 0 };
	const DEFAULT_CARD_DATA: AnalysisCardData = {
		specificCrop: { ...DEFAULT_METADATA },
		cropAge: { ...DEFAULT_METADATA },
		totalCropArea: { ...DEFAULT_METADATA },
		yieldIndex: { ...DEFAULT_METADATA },
	};

	const currentYearData = getDataByYear(level3Data, selectedYear);
	const previousYearData = getDataByYear(level3Data, selectedYear - 1);

	// return early if no data
	if (!currentYearData) {
		return DEFAULT_CARD_DATA;
	}

	// aggregate all values for selected year
	const currentAggregatedData = aggregateData(currentYearData.data);

	// init metadata
	const currentMetadata = { ...DEFAULT_CARD_DATA };

	currentMetadata.cropAge.currentValue = getMetadataValue(
		currentAggregatedData['Years Crop in Place'],
		currentYearData.data.length,
	);
	currentMetadata.yieldIndex.currentValue = getMetadataValue(
		currentAggregatedData['Yield Index'],
		currentYearData.data.length,
	);
	// crop area is a sum, no need to AVG value
	currentMetadata.totalCropArea.currentValue = currentAggregatedData['Total Crop Area'];
	currentMetadata.specificCrop.currentValue = currentAggregatedData['Specific Crop Area'];

	if (previousYearData) {
		const previousAggregatedData = aggregateData(previousYearData.data);

		currentMetadata.cropAge = getMetadataChange(
			currentMetadata.cropAge,
			getMetadataValue(previousAggregatedData['Years Crop in Place'], previousYearData.data.length),
		);

		currentMetadata.yieldIndex = getMetadataChange(
			currentMetadata.yieldIndex,
			getMetadataValue(previousAggregatedData['Yield Index'], previousYearData.data.length),
		);

		currentMetadata.totalCropArea = getMetadataChange(
			currentMetadata.totalCropArea,
			previousAggregatedData['Total Crop Area'],
		);

		currentMetadata.specificCrop = getMetadataChange(
			currentMetadata.specificCrop,
			previousAggregatedData['Specific Crop Area'],
		);
	}

	return currentMetadata;
};

export const getMetadata = ({
	level3Data,
	filter,
	selectedYear,
}: {
	level3Data: AnalysisLevel3[];
	filter?: string;
	selectedYear: number;
}): AnalysisCardData => {
	const DEFAULT_METADATA: AnalysisMetadata = { currentValue: 0, change: 0, previousValue: 0 };
	const DEFAULT_CARD_DATA: AnalysisCardData = {
		specificCrop: { ...DEFAULT_METADATA },
		cropAge: { ...DEFAULT_METADATA },
		totalCropArea: { ...DEFAULT_METADATA },
		yieldIndex: { ...DEFAULT_METADATA },
	};

	// return default metadata if no data
	if (!level3Data.length) {
		return DEFAULT_CARD_DATA;
	}

	// if no filter, aggregate data based on the selected year
	if (!filter) {
		return getAggregatedMetadata(level3Data, selectedYear);
	}

	const currentYear = getDataByYear(level3Data, selectedYear);

	// find data by filter
	const currentYearData = currentYear?.data.find((row) => row['Province Name'] === filter);

	// if we can't find the data, return early
	if (!currentYearData) {
		return DEFAULT_CARD_DATA;
	}

	// set first values before looking for older values
	const baseMetadata: AnalysisCardData = {
		specificCrop: { ...DEFAULT_METADATA, currentValue: currentYearData['Specific Crop Area'] },
		cropAge: { ...DEFAULT_METADATA, currentValue: currentYearData['Years Crop in Place'] },
		yieldIndex: { ...DEFAULT_METADATA, currentValue: currentYearData['Yield Index'] },
		totalCropArea: { ...DEFAULT_METADATA, currentValue: currentYearData['Total Crop Area'] },
	};

	const previousYear = getDataByYear(level3Data, selectedYear - 1);

	// if we have more than one year available, return previous year
	if (previousYear) {
		const previousYearData = previousYear.data.find((row) => row['Province Name'] === filter);
		// if we can't find the data, return base metadata
		if (!previousYearData) {
			return baseMetadata;
		}

		baseMetadata.specificCrop = getMetadataChange(baseMetadata.specificCrop, previousYearData['Specific Crop Area']);
		baseMetadata.cropAge = getMetadataChange(baseMetadata.cropAge, previousYearData['Years Crop in Place']);
		baseMetadata.totalCropArea = getMetadataChange(baseMetadata.totalCropArea, previousYearData['Total Crop Area']);
		baseMetadata.yieldIndex = getMetadataChange(baseMetadata.yieldIndex, previousYearData['Yield Index']);
	}

	return baseMetadata;
};
