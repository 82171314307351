import styled, { css } from 'styled-components';

export const Metrics = styled.div`
	${({ theme }) => css`
		display: grid;
		column-gap: 12px;
		row-gap: 12px;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 90px;
		max-width: 840px;
		width: 100%;
		${theme.mediaQueries.sm} {
			grid-auto-rows: 110px;
			grid-template-columns: repeat(3, 1fr);
		}
		${theme.mediaQueries.md} {
			grid-template-columns: repeat(4, 1fr);
		}
	`}
`;
