import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Level3Data } from '../../graphql/queries/analysis/analysis.types';

export type AnalysisFilter = { type: 'level2' | 'level3'; label: string };
export interface AnalysisState {
  activeFilter: AnalysisFilter | undefined;
  historicalData: AnalysisHistoricalData | undefined;
  metadataFilter: keyof Level3Data | undefined;
  currentYear: number | undefined;
}

export interface HistoricalData {
  type: 'level2' | 'level3';
  name: string;
  value: number;
}
export interface AnalysisHistoricalData {
  [key: number]: HistoricalData[];
}

const initialState: AnalysisState = {
  activeFilter: undefined,
  historicalData: undefined,
  metadataFilter: undefined,
  currentYear: undefined,
};
export const iarSlice = createSlice({
  name: 'iar',
  initialState,
  reducers: {
    updateActiveFilter(state, action: PayloadAction<AnalysisFilter | undefined>) {
      state.activeFilter = action.payload;
    },
    updateHistoricalData(state, action: PayloadAction<AnalysisHistoricalData | undefined>) {
      state.historicalData = action.payload;
    },
    updateMetadataFilter(state, action: PayloadAction<keyof Level3Data | undefined>) {
      state.metadataFilter = action.payload;
    },
    updateCurrentYear(state, action: PayloadAction<number>) {
      state.currentYear = action.payload;
    },
  },
});
export const { updateActiveFilter, updateHistoricalData, updateMetadataFilter, updateCurrentYear } = iarSlice.actions;
export default iarSlice.reducer;
