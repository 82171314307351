import { format } from "date-fns"
import { BSContractsData, TransformedBSContractData } from "../graphql/queries/contracts/contracts.types"

export const transformContractData = (contractData?: BSContractsData): TransformedBSContractData | undefined => {
	if (!contractData){
		return undefined;
	}
	return {
		'Contract': {
			'Contract Year': contractData?.contractYear,
			'Contract Number': contractData?.contractNumber,
			'Contract Manager 1': contractData?.contractManager1,
			'Contract Manager 2': contractData?.contractManager2
		},
		'Field': {
			'Field ID': contractData?.fieldId,
			'Lat / Long': `${contractData?.latitude}/${contractData?.longitude}`,
			'Field Name': contractData?.fieldName,
			'Field Area': contractData?.fieldArea,
			'Soil Type': contractData?.soilType,
			'Factory': contractData?.factory,
		},
		'Crop': {
			'Crop Grown': contractData?.cropGrown,
			'Drilled Area (ha)': contractData?.drilledArea,
			'Drilled Date': contractData?.drilledDate,
			'Area Grown': contractData?.areaGrown,
			'Crop Health': contractData?.hsat_cropHealthLevel,
			'Harvest Date': contractData?.harvested ? format(new Date(contractData?.harvested), 'dd/MM/yyyy') : ''
		}
	}
}