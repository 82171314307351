import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { StyledInputContainer } from '../../GenericFilter/GenericFilter.styles';

const ContractNumberFilterInput = forwardRef<any, { fieldLabel: string, initialValue?: string, setValue: (newValue: string) => void }>(({ fieldLabel, initialValue = "", setValue }, ref) => {
	useImperativeHandle(
		ref,
		() => ({
			updateInputValue() {
				if (inputValue) {
					setValue(inputValue)
				}
			}
		}),
	)
	const [inputValue, setInputValue] = useState<string>(initialValue);
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e?.target?.value);
	};

	return (
		<StyledInputContainer key={`contract-number-filter-${fieldLabel}`}>
			<label htmlFor="contract-number">Search</label>
			<input
				key={`contract-number-input-${fieldLabel}`}
				id={`contract-number-${fieldLabel}`}
				value={inputValue}
				onChange={onChange}
				onClick={(e: React.MouseEvent<HTMLInputElement>) => {
					const target = e.target as HTMLElement;
					target?.focus();
				}}
			/>
		</StyledInputContainer>
	)
})

export default ContractNumberFilterInput