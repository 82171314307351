import { useQuery } from '@apollo/client';
import React from 'react';
import { USER_PROJECTS_QUERY } from '../../graphql/queries/projects/projects';
import { ProjectData } from '../../graphql/queries/projects/projects.types';
import { useIsMounted } from '../../hooks/useIsMounted';
import { NotFound } from '../NotFound/NotFound';
import { SEO } from '../SEO/SEO';
import { ProjectHeader, ProjectReports, StyledProjects } from './Projects.styles';
import { ReportCard } from './ReportCard/ReportCard';

export const Projects = () => {
	const isMounted = useIsMounted();
	const {
		data: projectsData,
		loading,
		error,
	} = useQuery<{ projects: ProjectData[] }>(USER_PROJECTS_QUERY, {
		skip: !isMounted,
		fetchPolicy: 'cache-and-network',
	});

	const filteredProjects = projectsData?.projects.filter((project) => project.reports?.length) || [];
	const orderedProjects = filteredProjects
		? filteredProjects.slice().map((project) => {
				return {
					...project,
					reports: project.reports?.slice().sort((a, b) => {
						return new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime();
					}),
				};
		  })
		: [];

	if (loading || !isMounted) {
		return null;
	}

	if (error || !filteredProjects.length) {
		return <NotFound />;
	}

	return (
		<StyledProjects>
			<SEO title="Ground Survey - Projects" />
			{orderedProjects.map((project) => (
				<React.Fragment key={project.id}>
					<ProjectHeader>{project.name}</ProjectHeader>
					<ProjectReports>
						{project.reports?.map((report, i) => (
							<ReportCard
								key={report.id}
								rId={project.reports!.length - i}
								report={report}
								variant={i === 0 ? 'highlighted' : undefined}
							/>
						))}
					</ProjectReports>
				</React.Fragment>
			))}
		</StyledProjects>
	);
};
