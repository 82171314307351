//@ts-ignore
import { format } from 'friendly-numbers';
import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ThemeContext } from 'styled-components';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { RootState } from '../../../slices/store';
import { Theme } from '../../../styles/theme';
import { Select } from '../../Select/Select';
import { SelectOption } from '../../Select/Select.types';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ChartContainer, ChartLegend, ChartLegendIcon, ChartLegendItem } from '../Chart.styles';
import { ChartNoData } from '../ChartNoData/ChartNoData';
import { getBarColor, getSeriesData } from './CoverageChart.utils';
import HSATAbsIcon from './icons/hsat-abs.svg';
import HSATChangeIcon from './icons/hsat-change.svg';
import OCSBAbsIcon from './icons/ocsb-abs.svg';
import OCSBChangeIcon from './icons/ocsb-change.svg';

//TODO: replace svgs by components
export const CoverageChart: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  const { activeFilter: filter, historicalData: data } = useSelector((state: RootState) => state.iar);
  const { colors } = useContext<Theme>(ThemeContext);
  const options = [
    { label: 'Absolute (ha)', value: 'absolute' },
    { label: '% Change (YoY)', value: 'yoy' },
  ];
  const [chartType, setChartType] = useState<SelectOption>(options[0]);

  const updateChartType = (chartType: any) => {
    setChartType(chartType);
    ReactGA.event({ category: 'IAR Dashboard', action: 'Updated Area chart type', label: chartType.value });
  };

  const seriesData = getSeriesData({ data, filter });
  // if maxValue = 0, we don't render the chart
  const maxValue = Math.max(...seriesData.map((s) => s.value), 0);

  const level2 = seriesData.filter((series) => series.type === 'level2');
  const level3 = seriesData.filter((series) => series.type === 'level3');

  return (
    <Wrapper data-testid="coverageChart" display="flex" flexDirection="column" flex="1" overflow="hidden">
      <ChartLegend alignItems="center" justifyContent="space-between">
        <Wrapper py="sm" display="flex">
          <ChartLegendItem mr="md" alignItems="baseline">
            {chartType.value === 'absolute' ? (
              <ChartLegendIcon height="14px" src={OCSBAbsIcon} />
            ) : (
              <ChartLegendIcon height="14px" src={OCSBChangeIcon} />
            )}
            OCSB
          </ChartLegendItem>
          <ChartLegendItem alignItems="baseline">
            {chartType.value === 'absolute' ? (
              <ChartLegendIcon height="14px" src={HSATAbsIcon} />
            ) : (
              <ChartLegendIcon height="14px" src={HSATChangeIcon} />
            )}
            HSAT
          </ChartLegendItem>
        </Wrapper>
        <Wrapper display="flex" alignItems="center">
          <Wrapper as="span" mr="sm">
            Show:
          </Wrapper>
          <Wrapper width="130px">
            <Select onChange={(value) => updateChartType(value)} options={options} value={chartType} />
          </Wrapper>
        </Wrapper>
      </ChartLegend>
      <ChartContainer>
        <>
          {!maxValue ? (
            <ChartNoData showTicks={false} />
          ) : (
            <>
              <ResponsiveContainer>
                <BarChart barGap={0} data={seriesData} margin={{ right: -40 }}>
                  <YAxis
                    padding={{ top: 20 }}
                    tick={{ fontSize: 12, fill: 'white' }}
                    stroke={colors.charts.grid}
                    tickLine={{ stroke: 'white' }}
                    dataKey={chartType.value === 'absolute' ? 'value' : 'change'}
                    orientation="left"
                    tickFormatter={(tick) =>
                      chartType.value === 'absolute' ? format(tick, { decimals: 2, formattedDecimals: 2 }) : `${tick}%`
                    }
                    yAxisId="0"
                  />
                  <YAxis orientation="right" yAxisId="1" stroke={colors.charts.grid} />
                  <XAxis
                    padding={{ left: 10, right: 10 }}
                    allowDuplicatedCategory={false}
                    tick={{ fontSize: 12, fill: 'white' }}
                    stroke={colors.charts.grid}
                    tickLine={{ stroke: 'white' }}
                    dataKey="year"
                  />
                  <CartesianGrid vertical={false} stroke={colors.charts.grid} />

                  {level2.length && (
                    <Bar
                      data={level2}
                      dataKey={chartType.value === 'absolute' ? 'value' : 'change'}
                      name="OCSB"
                      fill={colors.greyLight}
                    />
                  )}
                  {level3.length && (
                    <Bar
                      data={level3}
                      dataKey={chartType.value === 'absolute' ? 'value' : 'change'}
                      name="HSAT"
                      fill={colors.brandPrimary}
                    >
                      {level3.map((series, i) => (
                        <Cell key={i} fill={getBarColor(chartType.value, series.change)} />
                      ))}
                    </Bar>
                  )}
                  <Tooltip
                    isAnimationActive={false}
                    separator=": "
                    cursor={{ fill: 'rgba(255, 255, 255, .1)' }}
                    labelStyle={{ display: 'none' }}
                    formatter={(value: number, name: string) => {
                      if (!value) {
                        return ['N/A', name];
                      }
                      const val =
                        chartType.value === 'absolute' ? `${value.toLocaleString('en-US')} ha` : `${value.toFixed(2)}%`;
                      return [val, name];
                    }}
                    filterNull
                    contentStyle={{
                      background: colors.black,
                      border: `1px solid ${colors.grey}`,
                      strokeWidth: 1,
                      fontSize: 14,
                      marginBottom: 1,
                      marginTop: 1,
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  />
                  {chartType.value === 'yoy' && <ReferenceLine y="0" stroke={colors.grey} strokeWidth={2} />}
                </BarChart>
              </ResponsiveContainer>
            </>
          )}
        </>
      </ChartContainer>
    </Wrapper>
  );
};
