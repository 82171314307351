import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../slices/icm/icm';
import { FILTERS_LABELS } from '../Filters.constants';
import GenericFilter from '../GenericFilter/GenericFilter';
import ContractNumberFilterInput from './ContractNumberFilterInput/ContractNumberFilterInput';

const ContractNumberFilter: React.FC<{ fieldLabel: string }> = ({ fieldLabel }) => {
  const [value, setValue] = useState<string>("");
  const dispatch = useDispatch();
  const childRef = useRef<any>();

  // const currentInputValue = useSelector((state: RootState) => state.icm.currentInputValue);
  const onClose = () => {
    if (childRef?.current?.updateInputValue) {
      childRef?.current?.updateInputValue();
    }
  };

  useEffect(() => {
    // Only update the filters once the dropdown has been closed
    if (value) {
      dispatch(updateFilters({ [fieldLabel]: parseInt(value) }));
    }
  }, [dispatch, fieldLabel, value])

  // GenericFilter handles the filter-level dropdowns, so we pass our specific implementation as a child to that
  return (
    <>
      {/* @ts-ignore */}
      <GenericFilter values={value} fieldLabel={FILTERS_LABELS[fieldLabel]} onClose={onClose} >
        <ContractNumberFilterInput fieldLabel={fieldLabel} initialValue={value} setValue={setValue} ref={childRef} />
      </GenericFilter >
    </>
  );
};

export default React.memo(ContractNumberFilter);
