import gql from 'graphql-tag';

export const ANALYSIS_NDVI_QUERY = gql`
  query analysisNDVI($analysisId: ID!, $area: String!, $level: String!, $year: Int!, $subLevel: String) {
    analysisNDVI(analysisId: $analysisId, area: $area, level: $level, year: $year, subLevel: $subLevel) {
      min {
        area
        ndvi
        month
      }
      max {
        area
        ndvi
        month
      }
      avg {
        area
        ndvi
        month
      }
    }
  }
`;

export const ANALYSIS_NDVI_YEARS_QUERY = gql`
  query analysisNDVIYears($analysisId: ID!) {
    analysisNDVIYears(analysisId: $analysisId) {
      year
    }
  }
`;
