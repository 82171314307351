export const APP_ROUTES = {
	AUTH: {
		path: '/auth',
	},
	LOGIN: {
		path: '/login',
	},
	HOME: {
		path: '/reports',
	},
};
