import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import {
  GET_WEATHER_BY_STATION_LEVEL,
  GET_WEATHER_YEARS_QUERY,
} from '../../../graphql/queries/weather/weather.queries';
import { StationWeather } from '../../../graphql/queries/weather/weather.types';
import { RootState } from '../../../slices/store';
import { Select } from '../../Select/Select';
import { SelectOption } from '../../Select/Select.types';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ChartContainer, ChartLegend, ChartLegendItem } from '../Chart.styles';
import { CurrentIcon, ReferenceIcon } from '../ChartIcons/ChartIcons';
import { ChartLoader } from '../ChartLoader/ChartLoader';
import { ChartNoData } from '../ChartNoData/ChartNoData';
import { WEATHER_CHART_FREQUENCIES, WEATHER_CHART_OPTIONS } from './WeatherChart.constants';
import { WeatherChartFrequency, WeatherChartType } from './WeatherChart.types';
import { getSeriesData, getWeatherYearsOptions } from './WeatherChart.utils';
import { WeatherChartAbsolute } from './WeatherChartAbsolute/WeatherChartAbsolute';
import { WeatherChartChange } from './WeatherChartChange/WeatherChartChange';

export const WeatherChart: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  const { coverage } = analysisData;

  const { activeFilter: filter } = useSelector((state: RootState) => state.iar);
  // use current year as default year
  const [currentYear] = useState(new Date().getFullYear());
  // set previous year as default reference year
  const [referenceYear, setReferenceYear] = useState({ label: `${currentYear - 1}`, value: currentYear - 1 });
  // grab list of available years from db
  const { data: weatherYearsData } = useQuery<{ weatherYears: number[] }>(GET_WEATHER_YEARS_QUERY);
  // store data locally so we can keep data on screen in between calls
  const [localWeatherData, setWeatherData] = useState<StationWeather>();

  const [chartType, setChartType] = useState<SelectOption<WeatherChartType>>(WEATHER_CHART_OPTIONS[0]);
  const [chartFrequency, setChartFrequency] = useState<SelectOption<WeatherChartFrequency>>(
    WEATHER_CHART_FREQUENCIES[0],
  );

  const updateChartType = (option: SelectOption<WeatherChartType>) => {
    setChartType(option);
    ReactGA.event({ category: 'IAR Dashboard', action: 'Updated Weather chart type', label: option.value });
  };

  const updateChartFrequency = (option: SelectOption<WeatherChartFrequency>) => {
    setChartFrequency(option);
    ReactGA.event({
      category: 'IAR Dashboard',
      action: 'Updated Weather chart frequency',
      label: option.value,
    });
  };

  const updateReferenceYear = (option: any) => {
    setReferenceYear(option);
    ReactGA.event({ category: 'IAR Dashboard', action: 'Updated Weather chart year', label: option.value });
  };

  const [getWeatherByStationLevel, { loading }] = useLazyQuery<
    { weatherByStationLevel: StationWeather },
    { name?: string; yearStart: number; yearEnd: number; level: string }
  >(GET_WEATHER_BY_STATION_LEVEL, {
    onCompleted({ weatherByStationLevel }) {
      setWeatherData(weatherByStationLevel);
    },
  });

  // fetch data on filters update
  useEffect(() => {
    //if filter is not defined, use level1 + coverage to get national data
    getWeatherByStationLevel({
      variables: {
        level: filter?.type || 'level1',
        name: filter?.label || coverage,
        yearEnd: currentYear,
        yearStart: referenceYear.value,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.label, referenceYear]);

  const seriesData = getSeriesData({ data: localWeatherData, type: chartType.value, frequency: chartFrequency.value });
  const yearOptions = getWeatherYearsOptions(weatherYearsData?.weatherYears);

  return (
    <Wrapper display="flex" flexDirection="column" flex="1" overflow="hidden">
      <ChartLegend alignItems="center" justifyContent="space-between">
        <Wrapper py="sm" display="flex">
          <ChartLegendItem mr="md">
            <CurrentIcon width="14px" />
            {currentYear}
          </ChartLegendItem>
          <ChartLegendItem>
            <ReferenceIcon width="14px" />
            {referenceYear.value}
          </ChartLegendItem>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper display="flex" alignItems="center" mr="md">
            <Wrapper as="span" mr="sm">
              Show:
            </Wrapper>
            <Wrapper width="120px">
              <Select onChange={updateChartType} options={WEATHER_CHART_OPTIONS} value={chartType} />
            </Wrapper>
          </Wrapper>
          <Wrapper display="flex" alignItems="center" mr="md">
            <Wrapper as="span" mr="sm">
              Period:
            </Wrapper>
            <Wrapper width="90px">
              <Select onChange={updateChartFrequency} options={WEATHER_CHART_FREQUENCIES} value={chartFrequency} />
            </Wrapper>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Wrapper as="span" mr="sm">
              Compared to:
            </Wrapper>
            <Wrapper width="75px">
              <Select maxHeight={130} onChange={updateReferenceYear} options={yearOptions} value={referenceYear} />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </ChartLegend>
      <ChartContainer>
        {localWeatherData && chartType.value === 'absolute' && (
          <WeatherChartAbsolute frequency={chartFrequency.value} seriesData={seriesData} />
        )}

        {localWeatherData && chartType.value === 'change' && (
          <WeatherChartChange frequency={chartFrequency.value} seriesData={seriesData[1]} />
        )}

        {localWeatherData !== undefined && !localWeatherData && <ChartNoData />}

        <ChartLoader loading={loading} />
      </ChartContainer>
    </Wrapper>
  );
};
