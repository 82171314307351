import styled, { css } from 'styled-components';
import { space, SpaceProps, variant } from 'styled-system';

export const StyledDashboardCard = styled.div<{ active?: boolean; variant?: 'filter' }>`
  ${({ active, theme }) => css`
    background: ${active ? theme.colors.brandSecondary : theme.colors.black};
    border: 1px solid ${theme.colors.grey};
    border-right: 0;
    border-top: 0;
    /* min-height: 25%; */
    margin-left: -1px;
    position: relative;
    transition: background ${theme.transitions.default};
    &:after {
      background: ${active ? theme.colors.brandPrimary : 'transparent'};
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      transition: background ${theme.transitions.default};
      width: 2px;
    }
    ${variant({
      variants: {
        filter: {
          cursor: 'pointer',
          ':hover:after': {
            background: theme.colors.brandPrimary,
          },
        },
      },
    })}
  `}
`;

export const DashboardCardHeader = styled.div<{ active?: boolean }>`
  ${({ active, theme }) => css`
    background: ${active ? theme.colors.brandSecondary : theme.colors.blackLight};
    border-bottom: 1px solid ${theme.colors.grey};
    padding: ${theme.space.md};
    font-size: 14px;
    transition: background ${theme.transitions.default};
  `}
`;

export const DashboardCardValue = styled.p`
  ${({ theme }) => css`
    font-size: 32px;
    letter-spacing: 2.5px;
    line-height: 40px;
    ${theme.mediaQueries.xl} {
      font-size: 46px;
      letter-spacing: 3.45px;
      line-height: 57px;
    }
  `}
`;

export const DashboardCardMenu = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.grey};
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding: ${theme.space.sm} ${theme.space.md};
    span {
      color: ${theme.colors.greyLight};
    }
  `}
`;

export const DashboardCardDiff = styled.span<{ as?: 'strong'; variant?: 'red' } & SpaceProps>`
  ${({ theme, as }) => css`
    color: ${theme.colors.success};
    font-size: 20px;
    line-height: 25px;
    font-weight: ${as === 'strong' ? 600 : 300};
    letter-spacing: 1.8px;
    ${theme.mediaQueries.xl} {
      font-size: 24px;
      line-height: 30px;
    }
    ${variant({
      variants: {
        red: {
          color: theme.colors.danger,
        },
      },
    })}
    ${space}
  `}
`;
