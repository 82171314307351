import React, { useContext } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ThemeContext } from 'styled-components';
import { Theme } from '../../../../styles/theme';
import { WeatherChartFrequency, WeatherSeriesData } from '../WeatherChart.types';

export const WeatherChartAbsolute: React.FC<{
  frequency: WeatherChartFrequency;
  seriesData: WeatherSeriesData[][];
}> = ({ seriesData, frequency }) => {
  const { colors } = useContext<Theme>(ThemeContext);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart margin={{ right: -40 }}>
        <CartesianGrid vertical={false} stroke={colors.charts.grid} />

        <XAxis
          dataKey="x"
          tick={{ fontSize: 12, fill: 'white' }}
          stroke={colors.charts.grid}
          tickLine={{ stroke: 'white' }}
          tickFormatter={(tick, index) => {
            if (frequency === 'weekly') {
              return (index + 1) % 4 === 0 || index === 0 ? tick : '';
            }

            return tick;
          }}
          allowDuplicatedCategory={false}
        />
        <YAxis
          stroke={colors.charts.grid}
          tickFormatter={(tick) => (tick === 0 ? '' : tick)}
          yAxisId={0}
          tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
          tickLine={{ stroke: 'white' }}
          orientation="left"
          padding={{ top: 20, bottom: 0 }}
        />
        <YAxis stroke={colors.charts.grid} yAxisId={1} orientation="right" />

        <Line
          animationDuration={600}
          fill={colors.black}
          data={seriesData[0]}
          name={seriesData[0][0].name}
          key={0}
          type="monotone"
          dataKey="value"
          dot={{ r: 2 }}
          stroke={colors.greyLight}
        />
        <Line
          animationDuration={600}
          data={seriesData[1]}
          name={seriesData[1][0].name}
          key={1}
          type="monotone"
          dataKey="value"
          fill={colors.black}
          stroke="#fff"
          dot={{ r: 3 }}
          strokeWidth={2}
        />
        <Tooltip
          filterNull={false}
          labelStyle={{ display: 'none' }}
          formatter={(value: number, name: string) => {
            if (!value) {
              return ['N/A', name];
            }
            return [`${value.toFixed(2)} mm`, name];
          }}
          separator=": "
          isAnimationActive={false}
          contentStyle={{
            background: colors.black,
            border: `1px solid ${colors.grey}`,
            strokeWidth: 1,
            fontSize: 14,
            marginBottom: 1,
            marginTop: 1,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
