import { motion } from 'framer-motion';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { GS } from '../../../classes/GroundSurveyReport/GroundSurveyReport.constants';

export const StyledReportSidebar = styled(motion.nav)<SpaceProps>`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: ${GS.menuSize}px;
	${space}
`;
