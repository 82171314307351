import styled, { css } from 'styled-components';
import { space, SpaceProps, variant } from 'styled-system';
import { Wrapper } from '../../Wrapper/Wrapper';

export const StyledAnalysisNav = styled.nav`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    width: 100%;
  `}
`;

export const AnalysisTabs = styled(Wrapper)`
  ${({ theme }) => css`
    border-right: 1px solid ${theme.colors.grey};
  `}
`;

export const AnalysisNavTab = styled.button<{ variant?: 'active' | 'inactive' | 'primary' | 'secondary' } & SpaceProps>`
  ${({ theme }) => css`
    background: transparent;
    color: ${theme.colors.greyLight};
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    border-radius: 0;
    border: 0;
    letter-spacing: 0.2px;
    line-height: 17px;
    padding: ${theme.space.sm};
    text-decoration: none;
    transition: background 0.6s ease-out, color 0.3s ease-out;
    &:active,
    &:focus {
      outline: 0;
    }
    &:hover {
      color: white;
    }
    ${variant({
      variants: {
        active: {
          background: 'white',
          color: 'black',
          '&:hover': {
            color: 'black',
          },
        },
        inactive: {
          background: 'rgba(255, 255, 255, .4)',
          color: theme.colors.grey,
          '&:hover': {
            color: 'black',
          },
        },
        primary: {
          background: theme.colors.brandPrimary,
          color: 'white',
        },
      },
    })}
  `}
  ${space}
`;

export const AnalysisNavTabIcon = styled.img`
  ${({ theme }) => css`
    margin-right: ${theme.space.sm};
  `}
`;
