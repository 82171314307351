import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { updateActiveFilter, updateCurrentYear } from '../../../slices/iar/iar.slice';
import { RootState } from '../../../slices/store';
import { Select } from '../../Select/Select';
import { SelectOption } from '../../Select/Select.types';
import { AnalysisNavTab, AnalysisNavTabIcon, AnalysisTabs, StyledAnalysisNav } from './AnalysisNav.styles';
import DashboardIcon from './icons/dashboard-icon.svg';
import MapIcon from './icons/map-icon.svg';

export const AnalysisNav: React.FC<{ coverage?: string; years?: number[] }> = ({ coverage, years }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch<{ analysisId: string }>();
  const { activeFilter, currentYear } = useSelector((state: RootState) => state.iar);

  const onYearUpdate = (option: SelectOption) => {
    dispatch(updateCurrentYear(option.value));
  };

  const yearsOptions =
    years?.map((y) => ({
      label: y.toString(),
      value: y,
    })) || [];
  const currentValue = yearsOptions.find((y) => y.value === currentYear) || null;

  return (
    <StyledAnalysisNav>
      <AnalysisTabs display="flex" flex="1 0 calc(80% + 1px)">
        <Wrapper style={{ fontSize: '12px' }} ml="md" display="flex" alignItems="center" flex="1 1 100%">
          Data shows:
          <Wrapper ml="sm" display="flex">
            {coverage && (
              <AnalysisNavTab
                onClick={() => dispatch(updateActiveFilter(undefined))}
                variant={activeFilter ? 'inactive' : 'active'}
              >
                {coverage}
              </AnalysisNavTab>
            )}
            {activeFilter && (
              <>
                <AnalysisNavTab variant="active">{activeFilter.label}</AnalysisNavTab>
                <AnalysisNavTab ml="1px" variant="active" onClick={() => dispatch(updateActiveFilter(undefined))}>
                  x
                </AnalysisNavTab>
              </>
            )}
          </Wrapper>
        </Wrapper>
        <Wrapper flex="1 0 auto" display="flex">
          <AnalysisNavTab
            onClick={() => dispatch(updateActiveFilter(undefined))}
            as={Link}
            to={`/analysis/${match.params.analysisId}/dashboard`}
            variant={location.pathname.endsWith('dashboard') ? 'primary' : undefined}
          >
            <AnalysisNavTabIcon src={DashboardIcon} alt="View dashboard" />
            Dashboard
          </AnalysisNavTab>
          <AnalysisNavTab
            onClick={() => dispatch(updateActiveFilter(undefined))}
            as={Link}
            to={`/analysis/${match.params.analysisId}/map`}
            variant={location.pathname.endsWith('map') ? 'primary' : undefined}
          >
            <AnalysisNavTabIcon src={MapIcon} alt="View map" />
            Map
          </AnalysisNavTab>
        </Wrapper>
      </AnalysisTabs>
      <Wrapper
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ fontSize: '12px' }}
        ml="md"
        flex="1 1 20%"
      >
        {location.pathname.endsWith('map') && (
          <>
            Show data for:
            <Wrapper ml="md" flex="1 1 100px" maxWidth="100px">
              <Select options={yearsOptions} onChange={onYearUpdate} value={currentValue} />
            </Wrapper>
          </>
        )}
      </Wrapper>
    </StyledAnalysisNav>
  );
};
