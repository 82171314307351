import { useQuery } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ContractsDetails from '../../../components/Contracts/ContractsDetails/ContractsDetails';
import ContractsMap from '../../../components/Contracts/ContractsMap/ContractsMap';
import ContractsNav from '../../../components/Contracts/ContractsNav/ContractsNav';
import ContractsTable from '../../../components/Contracts/ContractsTable/ContractsTable';
import { Loader } from '../../../components/Loader/Loader';
import { SEO } from '../../../components/SEO/SEO';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { BS_CONTRACTS_QUERY } from '../../../graphql/queries/contracts/contracts.queries';
import { BSContractFilters, BSContractsData } from '../../../graphql/queries/contracts/contracts.types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { RootState } from '../../../slices/store';
import { StyledContractsDetailsWrapper, StyledContractsPage } from './ContractsPage.styles';

export const ContractsPage: React.FC = () => {
  const { params } = useRouteMatch<{ projectId: string }>();
  const { state } = useLocation<{ projectId: string }>();
  const projectId = params.projectId || state?.projectId;

  const pageLimit = useSelector((state: RootState) => state.icm.pageLimit);
  const currentPage = useSelector((state: RootState) => state.icm.currentPage);
  const sortBy = useSelector((state: RootState) => state.icm.sortedBy);
  const filters = useSelector((state: RootState) => state.icm.filters);

  const isMounted = useIsMounted();

  // Contract data is fetched here as it needs to be shared across ContractsTable, ContractsMap and ContractsDetails
  const {
    data: contractsData,
    loading: loadingContractsData,
    error,
  } = useQuery<{
    contractManagement: {
      contracts: BSContractsData[];
      pagination: {
        count: number;
        page: number;
        maxPage: number;
      };
      filters?: BSContractFilters;
    };
  }>(BS_CONTRACTS_QUERY, {
    variables: {
      projectId,
      limit: pageLimit,
      page: currentPage,
      sortBy,
      filters,
    },
    skip: !isMounted,
  });

  return (
    <>
      <SEO title="Analysis & Review" />
      <StyledContractsPage id="contracts-page-wrapper" data-testid="contracts-page">
        <Wrapper width="70%" flexDirection="column" maxHeight="100%" overflowY="scroll">
          <ContractsNav coverage={'Thailand'} resultsCount={contractsData?.contractManagement?.pagination?.count} />
          <Switch>
            <Route path={`/contracts/${projectId}/table`}>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  style={{ height: '100%', position: 'relative' }}
                  key="analysis"
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                  exit={{ opacity: 0, x: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  <ContractsTable
                    contractsData={contractsData?.contractManagement}
                    loading={loadingContractsData}
                    sortBy={sortBy}
                  />

                  <AnimatePresence>
                    {loadingContractsData && (
                      <motion.div
                        animate={{ opacity: 1, transition: { duration: 0.2 } }}
                        initial={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{
                          zIndex: 5,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <Loader />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              </AnimatePresence>
            </Route>
            <Route path={`/contracts/${projectId}/map`}>
              <ContractsMap filters={filters} />
            </Route>
            <Route exact path={`/contracts/:projectId`}>
              <Redirect to={`/contracts/${projectId}/table`} />
            </Route>
          </Switch>
        </Wrapper>
        <StyledContractsDetailsWrapper>
          <ContractsDetails />
        </StyledContractsDetailsWrapper>
      </StyledContractsPage>
    </>
  );
};
