import styled, { css } from 'styled-components';

export const StyledChartSelect = styled.div`
  ${({ theme }) => css`
    width: 200px;
    .select__control {
      background: ${theme.colors.blackLighter};
      border: 0;
      border-right: 1px solid ${theme.colors.grey};
      border-radius: 0;
      font-size: 14px;
      cursor: pointer;
      min-height: 35px;
      padding: ${theme.space.xs} ${theme.space.sm};
      &:focus,
      &:hover,
      &:active {
        outline: 0;
        border-right: 1px solid ${theme.colors.grey};
        box-shadow: none;
      }
      ${theme.mediaQueries.xl} {
        padding: ${theme.space.sm} ${theme.space.md};
      }
    }
    .select__control--is-focused {
      outline: 0;

      box-shadow: none;
    }
    .select__single-value {
      color: white;
      margin: 0;
      font-weight: 600;
      padding: 0 ${theme.space.sm};

      /* overflow: visible; */
    }
    .select__menu {
      background: ${theme.colors.blackLighter};
      border: 1px solid ${theme.colors.grey};
      border-radius: 0;
      box-shadow: none;
      font-size: 13px;
      margin: 0;
      margin-left: -1px;
      width: calc(100% + 1px);
    }
    .select__menu-list {
      padding: 0;
    }
    .select__option {
      color: white;
      padding: ${theme.space.md};
      &:hover {
        background: ${theme.colors.inputPrimary};
        cursor: pointer;
      }
    }
    .select__option--is-focused {
      background: ${theme.colors.blackLighter};
    }
    .select__option--is-selected {
      background: ${theme.colors.inputPrimary};
    }
  `}
`;
