import React from 'react';
import { Wrapper } from '../../../Wrapper/Wrapper';
import {
  ContractsLegend,
  ContractsLegendItem,
  ContractsLegendTitle,
  StyledContractsMapLegend,
} from './ContractsMapLegend.styles';

export const ContractsMapLegend = () => {
  return (
    <StyledContractsMapLegend>
      <ContractsLegend>
        <ContractsLegendTitle>Yellow virus risk</ContractsLegendTitle>
        <Wrapper display="flex" alignItems="center">
          <Wrapper display="flex" alignItems="center" p="sm">
            <ContractsLegendItem variant="low" /> Low
          </Wrapper>
          <Wrapper display="flex" alignItems="center" p="sm">
            <ContractsLegendItem variant="medium" /> Medium
          </Wrapper>
          <Wrapper display="flex" alignItems="center" p="sm">
            <ContractsLegendItem variant="high" /> High
          </Wrapper>
          <Wrapper display="flex" alignItems="center" p="sm">
            <ContractsLegendItem /> Unknown
          </Wrapper>
        </Wrapper>
      </ContractsLegend>
    </StyledContractsMapLegend>
  );
};
