import { range } from '../../../App.utils';
import { AnalysisData, AnalysisLevel3, Level3Data } from '../../../graphql/queries/analysis/analysis.types';
import { ARMapMetadata, Level3KeyDomain, TerracottaDataset } from './AnalysisMap.types';

export const getTiffCollection = async (projectId: string): Promise<TerracottaDataset[]> => {
  try {
    const url = `${process.env.REACT_APP_TILLING_SERVER_ENDPOINT}/datasets?project=${projectId}`;
    const data = await fetch(url);
    const tiffData: { datasets: TerracottaDataset[] } = await data.json();
    return tiffData.datasets.map((d, i) => ({
      ...d,
      id: i,
    }));
  } catch (e) {
    console.error(`There was an error while fetching tiffs: `, e);
    return [];
  }
};

// export const getAvailableTiffs = async (url: string, projectId: string): Promise<TIFFCollection> => {
//   if (!url || !projectId) {
//     console.error('Missing parameters for tilling server.');
//     return {};
//   }
//   try {
//     const datasets = await fetch(url);
//     const datasetsList: { datasets: TerracottaDataset[] } = await datasets.json();
//     console.log(datasetsList);
//     const relevantTiffs = datasetsList.datasets.filter((tiff) => tiff.project === projectId);

//     const collection = relevantTiffs.reduce((acc, tiff) => {
//       const date = tiff.date;
//       if (!acc[date]) {
//         acc[date] = [];
//       }
//       acc[date].push(tiff.label);
//       return acc;
//     }, {} as TIFFCollection);

//     return collection;
//   } catch (e) {
//     console.error(e);
//     return {};
//   }
// };

/**
 * Returns a list of keys from level3Data
 * @param {AnalysisLevel3} level3Data
 * @return {*}  {Array<keyof Level3Data>}
 * Ex: ["Foo", "Bar"]
 */
const getLevel3Keys = (level3Data: AnalysisLevel3): Array<keyof Level3Data> => {
  return level3Data.data.reduce((acc, d) => {
    Object.keys(d).map((key) => {
      // cast type for TS
      const dataKey = key as keyof Level3Data;
      if (!acc.includes(dataKey)) {
        acc.push(dataKey);
      }
    });
    return acc;
  }, [] as Array<keyof Level3Data>);
};

//TODO: TEST TEST TEST
export const getMapMetadata = (analysisData: AnalysisData): ARMapMetadata[] => {
  // first, build an array of available years so the map can query data by selected year
  const baseMetadata: ARMapMetadata[] = analysisData.level3.map((data) => {
    const date = new Date(data.date);
    return { year: date.getFullYear(), data: [] };
  });

  if (!baseMetadata) {
    return [];
  }
  // then assign data from available years
  const updatedMetadata = baseMetadata.map((metadata) => {
    // get data for given year
    const currentYearData = analysisData.level3.find((data) => {
      const date = new Date(data.date);
      if (date instanceof Date) {
        return date.getFullYear() === metadata?.year;
      }

      return false;
    });
    // compute data frmo previous year in order too calculate change
    const previousYearData = analysisData.level3.find((data) => {
      const date = new Date(data.date);
      if (date instanceof Date) {
        return date.getFullYear() === metadata?.year - 1;
      }

      return false;
    });

    //get relevant keys
    if (currentYearData) {
      // then get all relevant keys
      const keys = getLevel3Keys(currentYearData);
      //  work out their min/max values so we can interpolate numbers later on
      // => {Foo: [0, 1], Bar: [2, 3]}
      const keysDomain: Level3KeyDomain = keys.reduce((acc, key) => {
        const min = Math.min(...currentYearData.data.map((d) => d[key]));
        const max = Math.max(...currentYearData.data.map((d) => d[key]));
        acc[key] = [min, max];

        return acc;
      }, {} as Level3KeyDomain);

      // once we have min/max values for each key, we can interpolate numbers on a [0, 1] scale
      const interpolatedData = currentYearData.data.map((row) => {
        // copy row so we can replace values with inteprolated values

        //@ts-ignore - inital object should be empty but still typed
        const rowCopy: Level3Metadata = {};
        const previousYearRow = previousYearData?.data.find((d) => d['Province Name'] === row['Province Name']);

        // loop through keys
        Object.keys(row).forEach((key) => {
          // cast for TS
          const dataKey = key as keyof Level3Data;
          if (dataKey === 'Province Name') {
            rowCopy[dataKey] = {
              current: 0,
              change: 0,
              name: row[dataKey],
            };
          } else {
            // get current value for given key
            const value = row[dataKey] || null;
            // get previous value
            const previousValue = previousYearRow ? previousYearRow[dataKey] : null;
            let change = 0;

            // if previous value is availabel, calculate change
            if (previousValue) {
              const diff = value - previousValue;
              change = (diff / previousValue) * 100 || 0;
            }
            // get min/max value for given key
            const keyDomain = keysDomain[dataKey];
            // if we find the key, assign interpolated value to the copy
            if (keyDomain) {
              const interpolatedValue = range(value, keyDomain[0], keyDomain[1], 0, 1);
              rowCopy[dataKey] = {
                current: interpolatedValue,
                change,
              };
            }
          }
        });
        // return new row
        return rowCopy;
      });

      return { ...metadata, data: interpolatedData };
    }
    return metadata;
  });

  return updatedMetadata;
};
