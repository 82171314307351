import gql from 'graphql-tag';

export const ANALYSIS_YIELD_QUERY = gql`
  query analysisYield($analysisId: ID!, $area: String!) {
    analysisYield(analysisId: $analysisId, area: $area) {
      yield
      yieldIndex
      yieldSource
      area
      year
    }
  }
`;
