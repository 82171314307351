import { gql } from '@apollo/client';

export const ME_QUERY = gql`
	query me {
		me {
			id
			createdAt
			updatedAt
			phone
			firstName
			email
			name
			role
			projects {
				name
			}
		}
	}
`;
