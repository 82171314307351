import styled, { css } from 'styled-components';
import { compose, layout, LayoutProps, space, SpaceProps, variant } from 'styled-system';

export const StyledButton = styled.button<{ variant: 'light' | 'dark' } & LayoutProps & SpaceProps>`
  ${({ theme }) => css`
    align-items: center;
    background: transparent;
    border: 1px solid;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 12px;
    pointer-events: all;
    text-transform: uppercase;
    text-decoration: none;
    transition: background 0.2s ease-out;

    ${variant({
      variants: {
        light: {
          backgroundColor: theme.colors.buttonLight,
          borderColor: theme.colors.buttonLight,
          [theme.mediaQueries.md]: {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: theme.colors.buttonLight,
          },
        },
        dark: {
          backgroundColor: theme.colors.buttonDark,
          borderColor: theme.colors.buttonDark,
          [theme.mediaQueries.md]: {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: theme.colors.buttonDark,
          },
        },
      },
    })}
    ${compose(layout, space)}
  `}
`;

export const ButtonImage = styled.img`
  display: inline-block;
  height: 20px;
  margin-right: 18px;
`;

export const ButtonType = styled.span`
  ${({ theme }) => css`
    color: white;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.28px;
    margin-left: 12px;
    text-transform: none;
  `}
`;
