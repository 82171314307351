import { useQuery } from '@apollo/client';
import format from 'date-fns/format';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { USER_PROJECTS_QUERY } from '../../../graphql/queries/projects/projects';
import { ProjectData } from '../../../graphql/queries/projects/projects.types';
import { ME_QUERY } from '../../../graphql/queries/user/me';
import { UserData } from '../../../graphql/queries/user/user.types';
import { AnalysisProjects } from '../../Analysis/AnalysisList/AnalysisList.styles';
import { NotFound } from '../../NotFound/NotFound';
import { ProjectHeader } from '../../Projects/Projects.styles';
import {
  ReportCardLabel,
  ReportCardLink,
  ReportCardRow,
  ReportCardValue,
  StyledReportCard,
} from '../../Projects/ReportCard/ReportCard.styles';
import { Wrapper } from '../../Wrapper/Wrapper';

export const ContractsList: React.FC = () => {
  const { data: projectsData, loading: projectsLoading } = useQuery<{ projects: ProjectData[] }>(USER_PROJECTS_QUERY);
  const { data: userData, loading } = useQuery<{ me: UserData }>(ME_QUERY);

  // don't show anything until user data has loaded
  if (loading || projectsLoading) {
    return null;
  }

  const activeCntracts = projectsData?.projects?.filter((c) => {
    return userData?.me.role === 'ADMIN' ? c.contract : c?.contract?.active;
  });

  // if no data, show NotFound
  if (!activeCntracts || !activeCntracts.length) {
    return <NotFound />;
  }
  return (
    <>
      {activeCntracts?.map((project) => (
        <React.Fragment key={project.id}>
          <ProjectHeader>{project.name}</ProjectHeader>

          <AnalysisProjects>
            <StyledReportCard as={RouterLink} to={`/contracts/${project.id}/`} key={project.id}>
              <ReportCardRow>
                Contract 001
                <ReportCardLink>Open dashboard</ReportCardLink>
              </ReportCardRow>
              <ReportCardRow>
                <Wrapper display="flex">
                  <Wrapper flex="1">
                    <ReportCardLabel as="div" variant="dark">
                      Created
                    </ReportCardLabel>
                    <ReportCardValue as="div">
                      {project.contract?.createdAt ? format(new Date(project.contract?.createdAt), 'dd/MM/yyyy') : '-'}
                    </ReportCardValue>
                  </Wrapper>
                  <Wrapper flex="1">
                    <ReportCardLabel as="div" variant="dark">
                      Last updated
                    </ReportCardLabel>
                    <ReportCardValue as="div">
                      {project.contract?.updatedAt ? format(new Date(project.contract?.updatedAt), 'dd/MM/yyyy') : '-'}
                    </ReportCardValue>
                  </Wrapper>
                </Wrapper>
              </ReportCardRow>
            </StyledReportCard>
          </AnalysisProjects>
        </React.Fragment>
      ))}
    </>
  );
};
