import { ARMapControls } from './AnalysisMap';

export const ANALYSIS_MAP_CONTROLS: ARMapControls = {
  activeFilter: undefined,
  metadataFilter: undefined,
  showMask: false,
  selectedVis: 'absolute',
  selectedYear: 2019,
  tiffs: undefined,
};
