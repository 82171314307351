import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

export const StyledDashboardFilters = styled.div<{ variant?: 'open' }>`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grey};
    border-bottom: 0;
    border-left: 0;
    flex: 1 1 20%;
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
  `}
`;

export const DashboardFiltersHeader = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.grey};
    color: ${theme.colors.greyLight};
    flex: 0 0 auto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.18px;
    line-height: 15px;
  `}
`;

export const DashboardFiltersBody = styled.div`
  ${({ theme }) => css`
    /* background: ${theme.colors.blackLight}; */
    flex: 1 1 100%;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const DashboardFiltersList = styled.div``;

export const DashboardFilter = styled.div<{ variant?: 'active' }>`
  ${({ theme }) => css`
    background: ${theme.colors.black};
    border-bottom: 1px solid ${theme.colors.grey};
    color: white;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.21px;
    padding: ${theme.space.sm} ${theme.space.md};
    &:hover {
      background: ${theme.colors.brandSecondary};
    }
    ${variant({
      variants: {
        active: {
          background: theme.colors.brandPrimary,
          '&:hover': {
            background: theme.colors.brandPrimary,
          },
        },
      },
    })}
    ${theme.mediaQueries.xl} {
      padding: ${theme.space.md};
    }
  `}
`;

export const DashboardFiltersTab = styled.button<{ variant?: 'active' }>`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.greyLight};
    background: transparent;
    text-decoration: none;
    flex: 1 1 auto;
    outline: 0;
    font-size: 12px;
    letter-spacing: 0.18px;
    border: 0;
    padding: ${theme.space.sm} ${theme.space.md};
    transition: all ${theme.transitions.default};
    &:hover {
      color: white;
    }
    ${variant({
      variants: {
        active: {
          background: theme.colors.brandSecondary,
          color: 'white',
          fontWeight: 'bold',
        },
      },
    })}
    ${theme.mediaQueries.xl} {
      padding: ${theme.space.md};
    }
  `}
`;
