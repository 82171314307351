import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ContractsList } from '../../components/Contracts/ContractsList/ContractsList';
import { Layout } from '../../components/Layout/Layout';
import { SEO } from '../../components/SEO/SEO';
import { updateSelectedContractUid } from '../../slices/icm/icm';
import { updateNavTitle } from '../../slices/nav/nav';
import { ContractsPage } from './ContractsPage/ContractsPage';

export const ICM: React.FC = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateNavTitle('Contract Management'));
    dispatch(updateSelectedContractUid()); // Remove persisted selected contract UID when navigating back to CM module
  }, [dispatch]);

  return (
    <Layout>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key="cmPage">
          <Route exact path={`${match.path}/`}>
            <motion.div
              style={{ height: '100%' }}
              key="analysis"
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
              exit={{ opacity: 0, x: -10 }}
              transition={{ duration: 0.3 }}
            >
              <SEO title="Contract Management" />
              <ContractsList />
            </motion.div>
          </Route>
          <Route path={`${match.path}/:projectId/`}>
            <motion.div
              style={{ height: '100%' }}
              key="analysis"
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
              exit={{ opacity: 0, x: -10 }}
              transition={{ duration: 0.3 }}
            >
              <SEO title="Contract Management - Projects" />
              <ContractsPage />
            </motion.div>
          </Route>
        </Switch>
      </AnimatePresence>
    </Layout>
  );
};
