import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ThemeContext } from 'styled-components';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { ANALYSIS_HEALTH_QUERY } from '../../../graphql/queries/analysisHealth/analysisHealth.queries';
import { AnalysisHealthData, AnalysisHealthInput } from '../../../graphql/queries/analysisHealth/analysisHealth.types';
import { RootState } from '../../../slices/store';
import { Theme } from '../../../styles/theme';
import { SelectOption } from '../../Select/Select.types';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ChartContainer, ChartLegend, ChartLegendItem } from '../Chart.styles';
import { CurrentIcon } from '../ChartIcons/ChartIcons';
import { ChartLoader } from '../ChartLoader/ChartLoader';
import { ChartNoData } from '../ChartNoData/ChartNoData';
import { HEALTH_CHART_TYPES } from './HealthChart.constants';
import { getHealthSeriesData } from './HealthChart.utils';

const currentYear = new Date();

export const HealthChart: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  // store remote data in state
  const [chartData, setChartData] = useState<AnalysisHealthData[]>([]);
  const [chartType, setChartType] = useState<SelectOption>(HEALTH_CHART_TYPES[0]);
  // get active filter from redux
  const activeFilter = useSelector((state: RootState) => state.iar.activeFilter);
  const { colors } = useContext<Theme>(ThemeContext);

  const [getHealthData, { loading }] = useLazyQuery<{ analysisHealth: AnalysisHealthData[] }, AnalysisHealthInput>(
    ANALYSIS_HEALTH_QUERY,
    {
      onCompleted: (d) => setChartData(d.analysisHealth),
    },
  );

  useEffect(() => {
    getHealthData({
      variables: {
        analysisId: analysisData.id,
        area: activeFilter?.label || analysisData.coverage,
        year: currentYear.getFullYear(),
      },
    });
  }, [activeFilter?.label]);

  const seriesData = getHealthSeriesData(chartData);

  return (
    <Wrapper display="flex" flexDirection="column" flex="1" overflow="hidden">
      <ChartLegend alignItems="center" justifyContent="space-between">
        <Wrapper display="flex" alignItems="center">
          <ChartLegendItem mr="md" py="sm">
            <CurrentIcon width="14px" />
            {currentYear.getFullYear()}
          </ChartLegendItem>
        </Wrapper>
      </ChartLegend>
      <ChartContainer>
        {!chartData.length && !loading && <ChartNoData />}
        {!!chartData.length && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart margin={{ right: -40 }} data={seriesData}>
              <CartesianGrid vertical={false} stroke={colors.charts.grid} />
              <XAxis
                dataKey="x"
                tick={{ fontSize: 12, fill: 'white' }}
                stroke={colors.charts.grid}
                tickLine={{ stroke: 'white' }}
              />
              <YAxis
                stroke={colors.charts.grid}
                tickFormatter={(tick) => (tick === 0 ? '' : tick)}
                yAxisId={0}
                dataKey="y"
                tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
                tickLine={{ stroke: 'white' }}
                orientation="left"
                padding={{ top: 20, bottom: 0 }}
              />
              <YAxis stroke={colors.charts.grid} yAxisId={1} orientation="right" />

              <Line
                animationDuration={600}
                type="monotone"
                dataKey="y"
                fill={colors.black}
                stroke="#fff"
                dot={{ r: 3 }}
                strokeWidth={2}
              />
              <Tooltip
                labelStyle={{ display: 'none' }}
                separator=": "
                filterNull={false}
                formatter={(v: number, r: string, p: any) => {
                  const value = v ? `${v}/100` : 'N/A';
                  return [value, p.payload.x];
                }}
                isAnimationActive={false}
                contentStyle={{
                  background: colors.black,
                  border: `1px solid ${colors.grey}`,
                  strokeWidth: 1,
                  fontSize: 14,
                  marginBottom: 1,
                  marginTop: 1,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        <ChartLoader loading={loading} />
      </ChartContainer>
    </Wrapper>
  );
};
