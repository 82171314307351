import { AnalysisFilter, AnalysisHistoricalData } from '../../../slices/iar/iar.slice';
import { colors } from '../../../styles/colors';

export interface SeriesData {
  year: number;
  value: number;
  change?: number;
  type?: 'level2' | 'level3';
}

const getSeriesChangeByFilter = (series: SeriesData[]): SeriesData[] => {
  const seriesData = series.map((serie) => {
    const prevSerie = series.find((s) => s.year === serie.year - 1 && s.type === serie.type);

    let change = 0;
    // prevent Infinity if prev value === 0
    if (prevSerie && prevSerie.value !== 0 && serie.value !== 0) {
      const diff = serie.value - prevSerie.value;
      change = (diff / prevSerie.value) * 100 || 0;
    }

    return { ...serie, change };
  });

  return seriesData;
};

const getAggregatedSeriesData = (data: AnalysisHistoricalData): SeriesData[] => {
  // break down series by year
  return Object.keys(data).reduce((acc, year: any) => {
    // aggregate data per year
    const serie1 = data[year]
      ? data[year]
          .filter((d) => d.type === 'level2')
          .reduce((values, val) => {
            values += val.value;
            return values;
          }, 0)
      : 0;
    const serie2 = data[year]
      ? data[year]
          .filter((d) => d.type === 'level3')
          .reduce((values, val) => {
            values += val.value;
            return values;
          }, 0)
      : 0;

    // return both series
    acc.push(
      { year: parseInt(year), value: serie1, type: 'level2' },
      { year: parseInt(year), value: serie2, type: 'level3' },
    );
    return acc;
  }, [] as SeriesData[]);
};

//TODO: rename to avoid confusion in other charts
export const getSeriesData = ({
  data,
  filter,
}: {
  data?: AnalysisHistoricalData;
  filter?: AnalysisFilter;
}): SeriesData[] => {
  let series: SeriesData[] = [];

  if (data) {
    if (filter) {
      series = Object.keys(data).reduce((acc, year: any) => {
        const entry = data[year].find((d) => d.name === filter.label && d.type === filter.type);
        if (entry) {
          acc.push({ year: parseInt(year), value: entry?.value || 0, type: filter.type });
        }
        return acc;
      }, [] as SeriesData[]);

      series.forEach((serie, i) => {
        let change = 0;
        if (series[i - 1]) {
          const prevSerie = series[i - 1];
          const diff = serie.value - prevSerie.value;
          change = (diff / prevSerie.value) * 100;
        }
        serie.change = change || 0;
      });
    } else {
      series = getAggregatedSeriesData(data);
      series = getSeriesChangeByFilter(series);
    }
  }

  return series;
};

export const getBarColor = (chartType: string, value?: number) => {
  if (chartType === 'absolute') {
    return colors.brandPrimary;
  }

  if (!value) {
    return colors.greyLight;
  }
  return value > 0 ? colors.charts.positive : colors.charts.negative;
};
