import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export const StyledInfo = styled.span<SpaceProps>`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
	${space}
`;

export const InfoCursor = styled.img`
	height: 12px;
	margin-right: 6px;
`;

export const InfoLabel = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.greyLight};
		font-size: 12px;
		font-weight: bold;
		text-transform: capitalize;
	`}
`;

export const InfoValue = styled(motion.span)`
	font-size: 20px;
`;
