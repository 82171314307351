import gql from 'graphql-tag';

export const ANALYSIS_PRICE_QUERY = gql`
  query analysisPrice($analysisId: ID!, $year: Int!) {
    analysisPrice(analysisId: $analysisId, year: $year) {
      date
      price
    }
  }
`;

export const ANALYSIS_PRICE_YEARS_QUERY = gql`
  query analysisPriceYears($analysisId: ID!) {
    analysisPriceYears(analysisId: $analysisId) {
      year
    }
  }
`;
