import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';
import { Wrapper } from '../../../Wrapper/Wrapper';
import {
  StyledRangeDropdown,
  StyledRangeInputContainer,
  StyledRangeInputSeparator,
} from '../ContractsRangeFilter.styles';

const ContractsRangeFilterInput = forwardRef<
  any,
  {
    fieldLabel: string;
    initialValues?: { from: number; to: number };
    setValues: (newValue: { from: number; to: number }) => void;
  }
>(({ fieldLabel, initialValues = { from: 0, to: 0 }, setValues }, ref) => {
  useImperativeHandle(ref, () => ({
    updateInputValue() {
      // console.log({ inputValues });
      if (inputValues.from > inputValues.to) {
        toast.error(`Invalid filter values`);
      } else if (inputValues.to > 0) {
        setValues(inputValues);
      }
    },
  }));
  const [inputValues, setInputValues] = useState<{ from: number; to: number }>(initialValues);

  return (
    <Wrapper width="160px" display="flex">
      <StyledRangeDropdown>
        <StyledRangeInputContainer>
          <label htmlFor="field-area-from">From</label>
          <input
            // TODO: Typing is broken as form is re-rendered every loop, so fields lose focus
            key="contract-range-from-input"
            id="field-area-from"
            value={inputValues?.from || ''}
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValues({ ...inputValues, from: parseInt(e.target?.value) });
            }}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
              const target = e.target as HTMLElement;
              target?.focus();
            }}
          />
        </StyledRangeInputContainer>
        <StyledRangeInputSeparator>-</StyledRangeInputSeparator>
        <StyledRangeInputContainer>
          <label htmlFor="field-area-to">To</label>
          <input
            key="contract-range-to-input"
            id="field-area-to"
            value={inputValues?.to || ''}
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValues({ ...inputValues, to: parseInt(e.target?.value) });
            }}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
              const target = e.target as HTMLElement;
              target?.focus();
            }}
          />
        </StyledRangeInputContainer>
      </StyledRangeDropdown>
    </Wrapper>
  );
});

export default ContractsRangeFilterInput;
