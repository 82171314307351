import { SelectOption } from '../../Select/Select.types';
import { NDVIData, NDVISeriesData } from './NDVIChart.types';

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getNDVIYearsOptions = (yearsData?: { year: number }[]): SelectOption[] => {
  if (!yearsData) {
    return [];
  }

  return yearsData.map(({ year }) => {
    return {
      label: year.toString(),
      value: year,
    };
  });
};

export const getNDVISeriesData = (ndviData?: NDVIData): NDVISeriesData[][] => {
  if (!ndviData || !ndviData.avg.length) {
    return [];
  }

  const formatSeries = (data?: NDVISeriesData[]) => {
    if (!data) {
      return [];
    }

    return months.map((d, i) => {
      const match = data.find((n) => n.month - 1 === i);
      return {
        month: i,
        area: '',
        ndvi: match?.ndvi || null,
      };
    });
  };

  const avg = formatSeries(ndviData.avg);
  const min = formatSeries(ndviData.min);
  const max = formatSeries(ndviData.max);

  return [min, avg, max];
};

// export const getNDVISublevel = (level?: string): string => {
//   const levels = {level2: 'level3', level3: null}
//   if(!level) {
//     return 'level3'
//   } else {
//     return level[level]
//   }

// }
