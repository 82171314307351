import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../slices/icm/icm';
import GenericFilter from '../GenericFilter/GenericFilter';
import ContractsRangeFilterInput from './ContractsRangeFilterInput/ContractsRangeFilterInput';

const ContractsRangeFilter: React.FC<{
  fieldLabel: string;
  values: any;
  fieldSuffix?: string;
  children: any;
  filtersData?: any;
}> = ({ fieldLabel, values, fieldSuffix, children, filtersData }) => {
  // console.log({ filtersData })
  const [fieldValues, setFieldValues] = useState<{ from: number; to: number }>({ from: 0, to: 0 });
  const dispatch = useDispatch();
  const childRef = useRef<any>();

  const onClose = () => {
    // Currently updating the filters on the closing of the GenericFilter dropdown
    if (childRef?.current?.updateInputValue) {
      childRef?.current?.updateInputValue();
    }
  };
  useEffect(() => {
    // Only update the filters once the dropdown has been closed
    if (fieldValues.from > 0 && fieldValues.to > 0) {
      dispatch(updateFilters({ [fieldLabel]: fieldValues }));
    }
  }, [dispatch, fieldLabel, fieldValues]);

  const getFieldLabel = (fieldLabel: string) => {
    switch (fieldLabel) {
      case 'fieldArea':
        return 'Field Area';
      case 'cropHealth':
        return 'Crop Health';
      default:
        return '';
    }
  };
  const getFieldSuffix = (fieldLabel: string) => {
    switch (fieldLabel) {
      case 'fieldArea':
        return 'ha';
      case 'cropHealth':
        return '%';
      default:
        return undefined;
    }
  };

  // GenericFilter handles the filter-level dropdowns, so we pass our specific implementation as a child to that
  return (
    <GenericFilter
      variant="range"
      values={fieldValues}
      fieldLabel={getFieldLabel(fieldLabel)}
      fieldSuffix={getFieldSuffix(fieldLabel)}
      onClose={onClose}
    >
      <ContractsRangeFilterInput
        fieldLabel={fieldLabel}
        initialValues={values}
        setValues={setFieldValues}
        ref={childRef}
      />
    </GenericFilter>
  );
};

export default ContractsRangeFilter;
