import { UserData } from '../../graphql/queries/user/user.types';

export const getInitials = (user?: UserData) => {
	if (!user) {
		return '';
	}
	if (user.firstName && user.name) {
		return `${user.firstName?.charAt(0)}${user.name?.charAt(0)}`;
	}
	if (user.firstName || user.name) {
		return user.firstName ? `${user.firstName?.charAt(0)}` : `${user.name?.charAt(0)}`;
	}
};
