import { format } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Row, useTable } from 'react-table';
import { ThemeContext } from 'styled-components';
import { BSContractsData, BSSortBy } from '../../../graphql/queries/contracts/contracts.types';
import { updateSelectedContractUid, updateSortedBy } from '../../../slices/icm/icm';
import { RootState } from '../../../slices/store';
import { Theme } from '../../../styles/theme';
import { Wrapper } from '../../Wrapper/Wrapper';
import { CONTRACTS_FILTER_TYPES } from '../Contracts.constants';
import { CircleIcon, CloseIcon, DownTriangleIcon, UpTriangleIcon } from '../ContractsIcons/ContractsIcons';
import ContractsTableFooter from '../ContractsTableFooter/ContractsTableFooter';
import {
  StyledContractsTable,
  StyledContractsTableHeader,
  StyledContractsTableHeaderContent,
  StyledRowClose,
} from './ContractsTable.styles';

const ContractsTable: React.FC<{
  loading?: boolean;
  contractsData?: {
    contracts: BSContractsData[];
    pagination: {
      count: number;
      page: number;
      maxPage: number;
    };
  };
  sortBy: BSSortBy | undefined;
}> = ({ loading, contractsData, sortBy }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state: RootState) => state.icm.currentPage);
  const { colors } = useContext<Theme>(ThemeContext);

  useEffect(() => {
    dispatch(updateSelectedContractUid());
  }, [dispatch]); // Reset sidebar details when table is mounted

  const columns = React.useMemo(
    () => [
      {
        Header: 'Contract Year',
        canSort: true,
        accessor: 'contractYear' as const, // accessor is the "key" in the data
      },
      {
        Header: 'Contract Number',
        canSort: true,
        accessor: 'contractNumber' as const,
      },
      {
        Header: 'Field ID',
        canSort: true,
        accessor: 'fieldId' as const,
      },
      {
        Header: 'Field Area',
        canSort: true,
        accessor: 'fieldArea' as const,
      },
      {
        Header: 'Factory',
        canSort: true,
        accessor: 'factory' as const,
      },
      {
        Header: 'Crop Health',
        canSort: true,
        accessor: 'hsat_cropHealthScore' as const,
      },
      {
        Header: 'YV Risk',
        accessor: 'hsat_yvRiskLevel' as const,
        canSort: false,
      },
      {
        Header: 'Harvested',
        id: 'harvested',
        canSort: true,
        accessor: (d: BSContractsData) => {
          return format(new Date(d.harvested), 'dd/MM/yyyy');
        },
      },
      {
        Header: 'Flag',
        id: 'flagged',
        canSort: false,
        accessor: (d: BSContractsData) => {
          return d?.flag?.flagged ? <CircleIcon width="10px" /> : null;
        },
      },
    ],
    [],
  );

  const handleClick = (sortByFilter: CONTRACTS_FILTER_TYPES) => {
    dispatch(updateSortedBy(sortByFilter));
  };

  const tableInstance = useTable({ columns, data: contractsData?.contracts || [] });
  const selectedFieldId: string | undefined = useSelector((state: RootState) => state?.icm?.selectedContractUid);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) {
    return (
      // TODO: improve loading
      <Wrapper p="xxl" height="100%" display="flex" justifyContent="center" alignItems="center" flex="1">
        <p></p>
      </Wrapper>
    );
  }
  return (
    <Wrapper height="800px" mt="10px">
      {/* this height prop is in order to allow the sticky header, it is still visible if taller */}
      {!loading && !contractsData?.contracts?.length ? (
        <Wrapper p="xxl" height="100%" display="flex" justifyContent="center" alignItems="center" flex="1">
          <p>No results</p>
        </Wrapper>
      ) : (
        <StyledContractsTable {...getTableProps()} data-testid="contracts-table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i}>
                {
                  // Only appears to be one headergroup and has no discernible props with which to set key
                  headerGroup.headers.map((column) => (
                    <StyledContractsTableHeader
                      variant={column.id === sortBy?.filter ? 'active' : undefined}
                      {...column.getHeaderProps()}
                      key={column.id}
                      onClick={() => {
                        if (column.canSort) {
                          handleClick(column.id as CONTRACTS_FILTER_TYPES);
                        }
                      }}
                    >
                      <StyledContractsTableHeaderContent>
                        {column.render('Header')}
                        <Wrapper display="flex" flexDirection="column" pl="xs">
                          {column.canSort && (
                            <>
                              <UpTriangleIcon
                                width="7px"
                                color={
                                  column.id === sortBy?.filter && sortBy?.order === 'asc'
                                    ? 'white'
                                    : colors.tables.detailTitle
                                }
                              />
                              <DownTriangleIcon
                                width="7px"
                                color={
                                  column.id === sortBy?.filter && sortBy?.order === 'desc'
                                    ? 'white'
                                    : colors.tables.detailTitle
                                }
                              />
                            </>
                          )}
                        </Wrapper>
                      </StyledContractsTableHeaderContent>
                    </StyledContractsTableHeader>
                  ))
                }
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row<BSContractsData>) => {
              prepareRow(row);
              const isSelectedField = row.original.inference_cm_id === selectedFieldId;
              return (
                <tr
                  style={isSelectedField ? { background: colors.tables.rowHighlight } : {}}
                  {...row.getRowProps()}
                  onClick={(): void => {
                    dispatch(updateSelectedContractUid(row?.original?.inference_cm_id));
                  }}
                >
                  {row.cells.map((cell: Cell<BSContractsData>) => {
                    let cellBackground = null;
                    if (cell.column.id === sortBy?.filter) {
                      cellBackground = colors.tables.selecetedCell;
                      if (isSelectedField) {
                        cellBackground = '#3B3856';
                      }
                    }
                    if (cell.column?.id === 'hsat_yvRiskLevel') {
                      switch (cell.value) {
                        case 'Low':
                          cellBackground = colors.tables.riskLow;
                          break;
                        case 'Medium':
                          cellBackground = colors.tables.riskMed;
                          break;
                        case 'High':
                          cellBackground = colors.tables.riskHigh;
                          break;
                        default:
                          cellBackground = colors.tables.riskUnknown;
                          break;
                      }
                    }
                    return (
                      <td style={cellBackground ? { background: cellBackground } : {}} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                        {cell.column.id === 'flagged' && isSelectedField ? (
                          <StyledRowClose
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(updateSelectedContractUid());
                            }}
                          >
                            <CloseIcon width="8px" />
                          </StyledRowClose>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledContractsTable>
      )}
      <ContractsTableFooter
        maxPage={contractsData?.pagination?.maxPage || 0}
        page={contractsData?.pagination?.page || currentPage}
      />
    </Wrapper>
  );
};

export default ContractsTable;
