import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { updateActiveFilter } from '../../../slices/iar/iar.slice';
import { RootState } from '../../../slices/store';
import { Wrapper } from '../../Wrapper/Wrapper';
import {
  DashboardFilter,
  DashboardFiltersBody,
  DashboardFiltersHeader,
  DashboardFiltersTab,
  StyledDashboardFilters,
} from './DashboardFilters.styles';
import { formatLevel2Filters, formatLevel3Filters } from './DashboardFilters.utils';

export const DashboardFilters: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { activeFilter } = useSelector((state: RootState) => state.iar);
  const level2Filters = formatLevel2Filters(analysisData.level2 || []);
  const level3Filters = formatLevel3Filters(analysisData.level3 || []);
  const dispatch = useDispatch();

  const onFilterClick = (label: string, type: 'level2' | 'level3') => {
    ReactGA.event({ category: 'IAR Dashboard', action: `Filtered by ${type}`, label });
    dispatch(updateActiveFilter({ type, label }));
  };

  const onTabClick = (tabIndex: number) => {
    ReactGA.event({
      category: 'IAR Dashboard',
      action: `Triggered filter tab`,
      label: tabIndex === 0 ? 'level2' : 'level3',
    });
    setActiveTab(tabIndex);
  };

  return (
    <StyledDashboardFilters>
      <DashboardFiltersHeader>
        <Wrapper px="md" py="sm">
          Filter by:
        </Wrapper>
        <Wrapper display="flex" flexWrap="wrap">
          <DashboardFiltersTab onClick={() => onTabClick(0)} variant={activeTab === 0 ? 'active' : undefined}>
            Regions
          </DashboardFiltersTab>
          <DashboardFiltersTab onClick={() => onTabClick(1)} variant={activeTab === 1 ? 'active' : undefined}>
            Provinces
          </DashboardFiltersTab>
        </Wrapper>
      </DashboardFiltersHeader>
      <DashboardFiltersBody>
        {activeTab === 0 &&
          level2Filters.map((filter, i) => (
            <DashboardFilter
              variant={activeFilter?.label === filter ? 'active' : undefined}
              onClick={() => onFilterClick(filter, 'level2')}
              key={i}
            >
              {filter}
            </DashboardFilter>
          ))}
        {activeTab === 1 &&
          level3Filters.map((filter, i) => (
            <DashboardFilter
              variant={activeFilter?.label === filter ? 'active' : undefined}
              onClick={() => onFilterClick(filter, 'level3')}
              key={i}
            >
              {filter}
            </DashboardFilter>
          ))}
      </DashboardFiltersBody>
    </StyledDashboardFilters>
  );
};
