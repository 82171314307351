import styled, { css } from 'styled-components';

export const StyledSidebar = styled.aside`
	${({ theme }) => css`
		background: ${theme.colors.black};
		border-right: 1px solid ${theme.colors.grey};
		flex: 0 0 ${theme.navbar.size};
		width: ${theme.navbar.size};
	`}
`;

export const SidebarLogo = styled.div`
	${({ theme }) => css`
		border-bottom: 1px solid ${theme.colors.grey};
		display: flex;
		align-items: center;
		justify-content: center;
		height: ${theme.navbar.size};
		width: 100%;
	`}
`;

export const SidebarItems = styled.ul``;
