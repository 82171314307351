import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { AnalysisList } from '../../components/Analysis/AnalysisList/AnalysisList';
import { Layout } from '../../components/Layout/Layout';
import { SEO } from '../../components/SEO/SEO';
import { updateNavTitle } from '../../slices/nav/nav';
import { AnalysisPage } from './AnalysisPage/AnalysisPage';

export const IAR = () => {
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const location = useLocation();

	useEffect(() => {
		dispatch(updateNavTitle('Analysis & Review'));
	}, [dispatch]);

	// TODO: figure out why parent re-renders on child route
	return (
		<Layout>
			<AnimatePresence exitBeforeEnter>
				<Switch location={location} key="arProjectList">
					<Route exact path={`${match.path}`}>
						<motion.div
							style={{ height: '100%' }}
							key="analysis"
							initial={{ opacity: 0, x: -10 }}
							animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
							exit={{ opacity: 0, x: -10 }}
							transition={{ duration: 0.3 }}
						>
							<SEO title="Analysis & Review - Projects" />
							<AnalysisList />
						</motion.div>
					</Route>

					<Route path={`${match.path}/:analysisId/`} render={() => <AnalysisPage />} />
				</Switch>
			</AnimatePresence>
		</Layout>
	);
};
