import { SelectOption } from '../../Select/Select.types';
import { PriceChartFrequency, PriceChartType } from './PriceChart.types';

export const PRICE_CHART_FREQUENCIES: SelectOption<PriceChartFrequency>[] = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
];

export const PRICE_CHART_TYPES: SelectOption<PriceChartType>[] = [
  { label: 'Absolute ($)', value: 'absolute' },
  { label: '% Change', value: 'change' },
];
