import styled, { css } from 'styled-components';

export const StyledDashboardCharts = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.grey};
    display: flex;
    flex-direction: column;
    flex: 1 1 80%;
    width: 80%;
  `}
`;
