import gql from 'graphql-tag';

export const USER_PROJECTS_QUERY = gql`
  query projects($searchFilter: String, $searchFilterType: String) {
    projects(searchFilter: $searchFilter, searchFilterType: $searchFilterType) {
      id
      name
      createdAt
      reports {
        id
        active
        createdAt
        deliveryDate
        name
        cropType
        coverage
        features {
          name
          type
          id
        }
        metrics {
          id
        }
      }
      analysis {
        id
        active
        coverage
        cropType
        createdAt
        updatedAt
      }
      contract {
        active
        createdAt
        updatedAt
      }
    }
  }
`;
