import { gql } from '@apollo/client';
import { UserData } from '../../queries/user/user.types';

export interface UpdateUserMutationInput {
	setName?: string;
	setFirstName?: string;
	setPhone?: string;
	setPassword?: string;
	addProject?: string;
	user?: string;
}

export interface UpdateUserMutationResponse {
	updateUser: UserData;
}

export const UPDATE_USER_MUTATION = gql`
	mutation updateUser(
		$setName: String
		$setFirstName: String
		$setPhone: String
		$setPassword: String
		$user: String
	) {
		updateUser(
			setName: $setName
			setFirstName: $setFirstName
			setPhone: $setPhone
			setPassword: $setPassword
			user: $user
		) {
			id
			createdAt
			updatedAt
			email
			name
			imageUrl
		}
	}
`;
