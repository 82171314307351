import styled, { css } from 'styled-components';

export const StyledSidebarItem = styled.li<{ variant?: 'active' }>`
  ${({ theme, variant }) => css`
    background: ${variant === 'active' ? theme.colors.brandSecondary : 'transparent'};
    font-size: 14px;
    line-height: 18px;
    padding: ${theme.space.md} 0;
    text-align: center;
    transition: background ${theme.transitions.default};
    a {
      text-decoration: none;
    }
  `}
`;
