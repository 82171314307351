import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { LayoutProps } from 'styled-system';
import { StyledDatePicker } from './StyledDatePicker.styles';


const DatePicker: React.FC<ReactDatePickerProps & LayoutProps> = ({ width, height, ...props }) => {
	return (
		<StyledDatePicker>
			<ReactDatePicker
				{...props}
			/>
		</StyledDatePicker>
	);
};

export default DatePicker;