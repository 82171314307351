import React from 'react';
import { CONTRACTS_FILTER_TYPES } from '../../Contracts/Contracts.constants';
import ContractNumberFilter from '../ContractNumberFilter/ContractNumberFilter';
import ContractYearFilter from '../ContractYearFilter/ContractYearFilter';
import FactoryFilter from '../FactoryFilter/FactoryFilter';
import ContractsRangeFilter from '../ContractsRangeFilter/ContractsRangeFilter';
import GenericFilter from '../GenericFilter/GenericFilter';
import ContractsDateRangeFilter from '../ContractsDateRangeFilter/ContractsDateRangeFilter';
import YvRiskFilter from '../yvRiskFilter/yvRiskFilter';
import FlagFilter from '../flagFilter/flagFilter';

const FILTER_TYPES: { [key in CONTRACTS_FILTER_TYPES]: React.FC<any> } = {
	[CONTRACTS_FILTER_TYPES.ContractYears]: ContractYearFilter,
	[CONTRACTS_FILTER_TYPES.ContractNumber]: ContractNumberFilter,
	[CONTRACTS_FILTER_TYPES.FieldId]: ContractNumberFilter,
	[CONTRACTS_FILTER_TYPES.Factory]: FactoryFilter,
	[CONTRACTS_FILTER_TYPES.FieldArea]: ContractsRangeFilter,
	[CONTRACTS_FILTER_TYPES.CropHealth]: ContractsRangeFilter,
	[CONTRACTS_FILTER_TYPES.YvRisk]: YvRiskFilter,
	[CONTRACTS_FILTER_TYPES.Harvested]: ContractsDateRangeFilter,
	[CONTRACTS_FILTER_TYPES.Flag]: FlagFilter,
}

const SelectedFilter: React.FC<{ selectedFilterType: CONTRACTS_FILTER_TYPES | null, filtersData: any }> = ({ selectedFilterType, filtersData }) => {
	let FilterComponent = null;
	if (selectedFilterType) {
		FilterComponent = FILTER_TYPES[selectedFilterType] || null;
	}
	if (!FilterComponent) {
		return null
	}
	return (
		<div>
			{/* TODO: Pass values through */}
			<FilterComponent filtersData={filtersData} fieldLabel={selectedFilterType} values={{ }} />
		</div>
	);
};

export default SelectedFilter;