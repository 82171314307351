import React from 'react';
import { LayoutProps, SpaceProps } from 'styled-system';
import { Wrapper } from '../../Wrapper/Wrapper';

const ChartIcon: React.FC<SpaceProps & LayoutProps> = ({ children, ...style }) => (
  <Wrapper mr="sm" display="flex" {...style}>
    {children}
  </Wrapper>
);

export const CurrentIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({ color = '#fff', ...style }) => {
  return (
    <ChartIcon {...style}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.99 8">
        <g>
          <path fill={color} d="M9 8a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2z"></path>
          <path
            fill={color}
            d="M5 4a4.09 4.09 0 01.14-1H0v2h5.13A4.09 4.09 0 015 4zM12.85 3A4.09 4.09 0 0113 4a4.09 4.09 0 01-.14 1H18V3z"
          ></path>
        </g>
      </svg>
    </ChartIcon>
  );
};

export const ReferenceIcon: React.FC<SpaceProps & LayoutProps> = (style) => {
  return (
    <ChartIcon {...style}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 5">
        <g fill="#a6a6a6">
          <path d="M0 2H4.5V3H0z"></path>
          <path d="M8.45 2H12.95V3H8.45z"></path>
          <path d="M6.5 5A2.5 2.5 0 119 2.5 2.5 2.5 0 016.5 5zm0-4A1.5 1.5 0 108 2.5 1.5 1.5 0 006.5 1z"></path>
        </g>
      </svg>
    </ChartIcon>
  );
};

export const BarIcon: React.FC<SpaceProps & LayoutProps & { color?: string }> = ({ color, ...style }) => (
  <ChartIcon {...style}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8">
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h7v10H0z" />
        </clipPath>
      </defs>
      <g data-name="Heat - Abs">
        <g data-name="Group 171994" fill={color} clipPath="url(#prefix__a)">
          <path data-name="Rectangle 202117" d="M0 0h3v10H0z" />
          <path data-name="Rectangle 202140" d="M4 5h3v5H4z" />
        </g>
      </g>
    </svg>
  </ChartIcon>
);
