import { useQuery } from '@apollo/client';
import format from 'date-fns/format';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { USER_PROJECTS_QUERY } from '../../../graphql/queries/projects/projects';
import { ProjectData } from '../../../graphql/queries/projects/projects.types';
import { ME_QUERY } from '../../../graphql/queries/user/me';
import { UserData } from '../../../graphql/queries/user/user.types';
import { NotFound } from '../../NotFound/NotFound';
import { ProjectHeader } from '../../Projects/Projects.styles';
import {
	ReportCardLabel,
	ReportCardLink,
	ReportCardRow,
	ReportCardValue,
	StyledReportCard,
} from '../../Projects/ReportCard/ReportCard.styles';
import { Wrapper } from '../../Wrapper/Wrapper';
import { AnalysisProjects } from './AnalysisList.styles';

export const AnalysisList: React.FC = () => {
	const { data: projectsData, loading: projectsLoading } = useQuery<{ projects: ProjectData[] }>(USER_PROJECTS_QUERY);
	const { data: userData, loading } = useQuery<{ me: UserData }>(ME_QUERY);

	// don't show anything until user data has loaded
	if (loading || projectsLoading) {
		return null;
	}

	// if user != ADMIN, only return projects with active analysis
	// otherwise, return all projects with analysis
	const activeProjects = projectsData?.projects.filter((project) => {
		return userData?.me.role === 'ADMIN' ? project.analysis : project?.analysis?.active;
	});

	// if no data, show NotFound
	if (!activeProjects || !activeProjects.length) {
		return <NotFound />;
	}
	return (
		<>
			{activeProjects?.map((project) => (
				<React.Fragment key={project.id}>
					<ProjectHeader>{project.name}</ProjectHeader>

					<AnalysisProjects>
						<StyledReportCard as={RouterLink} to={`/analysis/${project.analysis?.id}`} key={project.id}>
							<ReportCardRow>
								Analysis 001
								<ReportCardLink>Open dashboard</ReportCardLink>
							</ReportCardRow>
							<ReportCardRow>
								<Wrapper display="flex">
									<Wrapper flex="1">
										<ReportCardLabel as="div" variant="dark">
											Created
										</ReportCardLabel>
										<ReportCardValue as="div">
											{project.analysis?.createdAt ? format(new Date(project.analysis?.createdAt), 'dd/MM/yyyy') : '-'}
										</ReportCardValue>
									</Wrapper>
									<Wrapper flex="1">
										<ReportCardLabel as="div" variant="dark">
											Last updated
										</ReportCardLabel>
										<ReportCardValue as="div">
											{project.analysis?.updatedAt ? format(new Date(project.analysis?.updatedAt), 'dd/MM/yyyy') : '-'}
										</ReportCardValue>
									</Wrapper>
								</Wrapper>

								<Wrapper display="flex" mt="md">
									<Wrapper flex="1">
										<ReportCardLabel as="div" variant="dark">
											Coverage
										</ReportCardLabel>
										<ReportCardValue as="div">{project.analysis?.coverage || '-'}</ReportCardValue>
									</Wrapper>
									<Wrapper flex="1">
										<ReportCardLabel as="div" variant="dark">
											Crop
										</ReportCardLabel>
										<ReportCardValue as="div">
											{/* @ts-ignore */}
											{project.analysis?.cropType || '-'}
										</ReportCardValue>
									</Wrapper>
								</Wrapper>
							</ReportCardRow>
						</StyledReportCard>
					</AnalysisProjects>
				</React.Fragment>
			))}
		</>
	);
};
