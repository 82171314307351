import styled, { css } from 'styled-components';
import { space, SpaceProps, variant } from 'styled-system';
import { MetricVariant } from './MetricCard';

export const StyledMetric = styled.span<{ variant: MetricVariant } & SpaceProps>`
	${({ theme }) => css`
		border-radius: 8px;
		box-shadow: 5px 5px 15px #0000003b;
		color: white;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		${variant({
			variants: {
				light: {
					gridRow: 2,
					background: theme.colors.metricLight,
				},
				dark: {
					background: theme.colors.metricDark,
				},
			},
		})}

		${space}
	`}
`;
