import { motion, Variants } from 'framer-motion';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ReportSidebarItemContent, ReportSidebarItemTitle, StyledReportSidebarItem } from './ReportSidebarItem.styles';

const itemVariants: Variants = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

export const ReportSidebarItem: React.FC<{ active: boolean; name: string; onClick: () => void; value: string }> = ({
  active,
  name,
  onClick,
  value,
}) => {
  const { colors } = useContext(ThemeContext);

  return (
    <StyledReportSidebarItem layout variants={itemVariants}>
      <ReportSidebarItemTitle onClick={onClick} layout>
        {/* init size: 18px - target size: 14px | scale factor ~.77 */}
        <motion.div
          whileHover={{ color: 'white' }}
          animate={{ scale: active ? 1 : 0.77, color: active ? 'white' : colors.greyLight }}
          style={{ color: 'inherit', transformOrigin: 'left' }}
        >
          {name}
        </motion.div>
      </ReportSidebarItemTitle>
      {active && (
        <ReportSidebarItemContent
          mt="xs"
          mb="md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.3 } }}
          layout
          dangerouslySetInnerHTML={{ __html: value }}
        ></ReportSidebarItemContent>
      )}
    </StyledReportSidebarItem>
  );
};
