import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { mediaQueries } from './mediaQueries';
import { navbar } from './navbar';
import { space } from './space';
import { transitions } from './transitions';

export const theme = {
  breakpoints,
  colors,
  mediaQueries,
  navbar,
  space,
  transitions,
};

export type Theme = typeof theme;
