import styled, { css } from 'styled-components';

export const AnalysisProjects = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.blackLight};
		border-bottom: 1px solid ${theme.colors.grey};
		display: flex;
		flex-wrap: wrap;
		padding: ${theme.space.md};
	`}
`;

export const AnalysisProjectCard = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.black};
		border: 1px solid ${theme.colors.grey};
		cursor: pointer;
		outline: 1px solid transparent;
		position: relative;
		text-decoration: none;
		width: 250px;
	`}
`;

export const AnalysisCardTitle = styled.h1`
	font-size: 30px;
	font-weight: 300;
	line-height: 36px;
	width: 80%;
`;
