import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { USER_PROJECTS_QUERY } from '../../graphql/queries/projects/projects';
import { ProjectData } from '../../graphql/queries/projects/projects.types';
import { ME_QUERY } from '../../graphql/queries/user/me';
import { UserData } from '../../graphql/queries/user/user.types';
import { Logo } from '../Logo/Logo';
import { SidebarItems, SidebarLogo, StyledSidebar } from './Sidebar.styles';
import { SidebarItem } from './SidebarItem/SidebarItem';

export const Sidebar = () => {
  const { data: projectData } = useQuery<{ projects: ProjectData[] }>(USER_PROJECTS_QUERY);
  const { data: userData } = useQuery<{ me: UserData }>(ME_QUERY);
  //TODO: improve logic
  const components = projectData?.projects.reduce(
    (acc, project) => {
      if (project?.reports?.length) {
        acc.reports = true;
      }
      if (project.analysis) {
        acc.analysis = true;
      }
      // TODO: Show this only if CM is enabled for user
      if (project.contract) {
        acc.contracts = true;
      }
      return acc;
    },
    { reports: false, analysis: false, contracts: false },
  ) || { reports: false, analysis: false, contracts: false };

  const activeContracts =
    projectData?.projects?.filter((c) => {
      return userData?.me.role === 'ADMIN' ? c.contract : c?.contract?.active;
    }) || [];

  return (
    <StyledSidebar>
      <SidebarLogo as={Link} to="/">
        <Logo />
      </SidebarLogo>
      <SidebarItems>
        {components.reports && <SidebarItem name="GS" link="/reports" />}
        {components.analysis && <SidebarItem name="AR" link="/analysis" />}
        {components.contracts && activeContracts?.length > 0 && <SidebarItem name="CM" link="/contracts" />}
      </SidebarItems>
    </StyledSidebar>
  );
};
