import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { CONTRACT_FILTERS_QUERY } from '../../../graphql/queries/contracts/contracts.queries';
import { BSContractFilters } from '../../../graphql/queries/contracts/contracts.types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { updateFilters } from '../../../slices/icm/icm';
import SelectedFilter from '../../Filters/SelectedFilter/SelectedFilter';
import SelectFilter from '../../Filters/SelectFilter/SelectFilter';
import { Wrapper } from '../../Wrapper/Wrapper';
import { CONTRACTS_FILTER_TYPES } from '../Contracts.constants';
import { CloseIcon, PlusIcon } from '../ContractsIcons/ContractsIcons';
import { ContractsNavTab } from '../ContractsNav/ContractsNav.styles';

const ContractsFilters: React.FC<{ filters?: BSContractFilters }> = ({ filters }) => {
  const { params } = useRouteMatch<{ projectId: string }>();
  const { state } = useLocation<{ projectId: string }>();
  const projectId = params.projectId || state?.projectId;
  const [addingNewFilter, setAddingNewFilter] = useState<boolean>(false);
  const [existingFilters, setExistingFilters] = useState<CONTRACTS_FILTER_TYPES[]>([]);
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const addFilter = (filterType: CONTRACTS_FILTER_TYPES): void => {
    setExistingFilters([...existingFilters, filterType]);
    setAddingNewFilter(false);
  };

  const removeFilter = (filterType: CONTRACTS_FILTER_TYPES): void => {
    dispatch(updateFilters({ [filterType]: undefined }));
    const remainingFilters = existingFilters.filter((type) => type !== filterType);
    setExistingFilters(remainingFilters);
  };

  const { data: filtersData, loading: loadingFilters } = useQuery<{
    contractManagementFilters: { contractYears: number[]; factories: string[] };
  }>(CONTRACT_FILTERS_QUERY, {
    variables: { projectId },
    skip: !isMounted,
  });

  if (loadingFilters) {
    return <p data-testid="loading-filters">Loading filters...</p>; //TODO: Style loading state correctly
  }
  return (
    <Wrapper display="flex" data-testid="filters-wrapper">
      {existingFilters.map((filterType: CONTRACTS_FILTER_TYPES) => (
        <React.Fragment key={filterType}>
          <SelectedFilter
            data-testid={`contracts-filter-${filterType}`}
            filtersData={filtersData?.contractManagementFilters}
            selectedFilterType={filterType}
            key={filterType}
          />
          <ContractsNavTab
            variant="filters"
            onClick={() => {
              removeFilter(filterType);
            }}
          >
            <CloseIcon width="10px" />
          </ContractsNavTab>
        </React.Fragment>
      ))}
      {addingNewFilter && (
        <>
          <SelectFilter
            portalSelectorId="contracts-page-wrapper"
            selectFilterType={addFilter}
            existingFilters={existingFilters}
          />
          <ContractsNavTab
            variant="filters"
            onClick={(): void => {
              setAddingNewFilter(false);
            }}
          >
            <CloseIcon width="10px" />
          </ContractsNavTab>
        </>
      )}

      <ContractsNavTab
        data-testid="add-new-filter"
        variant="add"
        onClick={(): void => {
          setAddingNewFilter(true);
        }}
      >
        <PlusIcon width="10px" />
      </ContractsNavTab>
    </Wrapper>
  );
};

export default ContractsFilters;
