import styled, { css } from 'styled-components';
import { space, SpaceProps, variant } from 'styled-system';

export const StyledContractsNav = styled.nav`
  ${({ theme }) => css`
    position: sticky;
    z-index: 1;
    top: 0;
    background: ${theme.colors.black};
    align-items: stretch;
    justify-content: space-between;
    display: flex;
    width: 100%;
    border: 1px solid ${theme.colors.tables.borders};
    border-left: 0;
    border-bottom: 0;
  `}
`;

export const StyledContractsNavFilters = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    display: flex;
    align-items: center;
    overflow: scroll;
  `}
`;

export const ContractsNavTab = styled.button<{ variant?: 'filters' | 'add' | 'active' | 'inactive' } & SpaceProps>`
  ${({ theme }) => css`
    background: ${theme.colors.brandPrimary};
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 0;
    border: 0;

    letter-spacing: 0.2px;
    line-height: 17px;
    padding: ${theme.space.sm};
    text-decoration: none;
    transition: background 0.6s ease-out, color 0.3s ease-out;
    &:active,
    &:focus {
      outline: 0;
    }
    &:hover {
      color: white;
    }
    ${variant({
      variants: {
        add: {
          width: '39px',
          height: '39px',
          background: theme.colors.tables.filterAddBg,
          color: 'white',
          justifyContent: 'center',
        },
        filters: {
          width: '30px',
          background: `${theme.colors.brandPrimary}`,
          color: 'white',
          '&:hover': {
            background: theme.colors.filterOptionHover,
          },
          borderRight: `1px solid ${theme.colors.black}`,
          borderLeft: 'none',
          marginLeft: 0,
          justifyContent: 'center',
        },
        active: {
          background: 'white',
          color: `${theme.colors.grey}`,
          padding: `${theme.space.sm} ${theme.space.md}`,
          svg: {
            marginRight: `${theme.space.sm}`,
          },
          '&:hover': {
            color: `${theme.colors.grey}`,
          },
        },
        inactive: {
          background: `${theme.colors.grey}`,
          color: 'white',
          padding: `${theme.space.sm} ${theme.space.md}`,
          svg: {
            marginRight: `${theme.space.sm}`,
          },
          '&:hover': {
            color: 'white',
          },
        },
      },
    })}
  `}
  ${space}
`;

export const ContractsNavTabIcon = styled.img`
  ${({ theme }) => css`
    margin-right: ${theme.space.sm};
  `}
`;

export const StyledResultsCounter = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.sm} ${theme.space.md};
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #383838;
    strong {
      padding-right: ${theme.space.sm};
    }
  `}
`;
