import styled, { css } from 'styled-components';
import { Wrapper } from '../Wrapper/Wrapper';

export const StyledNavbar = styled.nav`
	${({ theme }) => css`
		background-color: ${theme.colors.black};
		border-bottom: 1px solid ${theme.colors.grey};
		flex: 0 0 ${theme.navbar.size};
		position: relative;
		z-index: 1;
	`}
`;

export const NavbarTitle = styled(Wrapper)`
	font-weight: 200;
`;

export const MenuWrapper = styled(Wrapper)`
	&:focus {
		outline: 0;
	}
`;

export const NavbarMenu = styled.ul`
	${({ theme }) => css`
		border: 1px solid ${theme.colors.grey};
		position: absolute;
		top: calc(100%);
		right: 0;
		width: auto;
	`}
`;

export const NavbarMenuItem = styled.li`
	${({ theme }) => css`
		background: ${theme.colors.black};
		cursor: pointer;
		padding: ${theme.space.md};
		width: 100%;
		white-space: nowrap;
		&:hover {
			background: ${theme.colors.blackLight};
		}
	`}
`;
