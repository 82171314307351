import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ThemeContext } from 'styled-components';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import {
  ANALYSIS_PRICE_QUERY,
  ANALYSIS_PRICE_YEARS_QUERY,
} from '../../../graphql/queries/analysisPrice/analysisPrice.queries';
import { Theme } from '../../../styles/theme';
import { Select } from '../../Select/Select';
import { SelectOption } from '../../Select/Select.types';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ChartContainer, ChartLegend } from '../Chart.styles';
import { ChartLoader } from '../ChartLoader/ChartLoader';
import { ChartNoData } from '../ChartNoData/ChartNoData';
import { PRICE_CHART_FREQUENCIES, PRICE_CHART_TYPES } from './PriceChart.constants';
import { PriceChartData } from './PriceChart.types';
import { getPriceSeriesData, getPriceYearsOptions } from './PriceChart.utils';

export const PriceChart: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  const { colors } = useContext<Theme>(ThemeContext);
  const [chartData, setChartData] = useState<PriceChartData[]>();
  const [frequency, setFrequency] = useState(PRICE_CHART_FREQUENCIES[0]);
  const [type, setType] = useState(PRICE_CHART_TYPES[0]);
  const { data: yearsData, loading: loadingYears } = useQuery<{ analysisPriceYears: { year: number }[] }>(
    ANALYSIS_PRICE_YEARS_QUERY,
    {
      variables: { analysisId: analysisData.id },
    },
  );
  const [getPriceData, { loading }] = useLazyQuery(ANALYSIS_PRICE_QUERY, {
    onCompleted: (d) => setChartData(d.analysisPrice),
  });
  const [selectedYear, setSelectedYear] = useState<SelectOption>();
  const yearOptions = getPriceYearsOptions(yearsData?.analysisPriceYears);

  useEffect(() => {
    if (yearsData?.analysisPriceYears.length) {
      setSelectedYear({
        label: yearsData?.analysisPriceYears[0].year.toString(),
        value: yearsData?.analysisPriceYears[0].year,
      });
    }
  }, [yearsData?.analysisPriceYears]);

  useEffect(() => {
    if (selectedYear) {
      getPriceData({
        variables: {
          analysisId: analysisData.id,
          year: selectedYear.value,
        },
      });
    }
  }, [selectedYear]);

  const seriesData = getPriceSeriesData({
    data: chartData,
    frequency: frequency.value,
  });

  return (
    <Wrapper display="flex" flexDirection="column" flex="1" overflow="hidden">
      <ChartLegend alignItems="center" justifyContent="flex-end">
        <Wrapper display="flex">
          <Wrapper display="flex" alignItems="center" mr="md">
            <Wrapper as="span" mr="sm">
              Show:
            </Wrapper>
            <Wrapper width="110px">
              <Select onChange={(option) => setType(option)} options={PRICE_CHART_TYPES} value={type} />
            </Wrapper>
          </Wrapper>
          <Wrapper display="flex" alignItems="center" mr="md">
            <Wrapper as="span" mr="sm">
              Period:
            </Wrapper>
            <Wrapper width="90px">
              <Select onChange={(option) => setFrequency(option)} options={PRICE_CHART_FREQUENCIES} value={frequency} />
            </Wrapper>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Wrapper as="span" mr="sm">
              Year:
            </Wrapper>
            <Wrapper width="75px">
              <Select onChange={(option) => setSelectedYear(option)} options={yearOptions} value={selectedYear} />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </ChartLegend>

      <ChartContainer>
        {chartData !== undefined && !chartData.length && !loading && <ChartNoData />}
        {chartData && type.value === 'absolute' && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart margin={{ right: -40 }} data={seriesData}>
              <CartesianGrid vertical={false} stroke={colors.charts.grid} />

              <XAxis
                interval={0}
                dataKey="x"
                tick={{ fontSize: 12, fill: 'white' }}
                stroke={colors.charts.grid}
                tickLine={{ stroke: 'white' }}
                tickFormatter={(tick, index) => {
                  if (frequency.value === 'weekly') {
                    return (index + 1) % 4 === 0 || index === 0 ? tick : '';
                  }

                  return tick;
                }}
                allowDuplicatedCategory={false}
              />
              <YAxis
                stroke={colors.charts.grid}
                tickFormatter={(tick) => (tick === 0 ? '' : tick)}
                yAxisId={0}
                dataKey="y"
                tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
                tickLine={{ stroke: 'white' }}
                orientation="left"
                padding={{ top: 20, bottom: 0 }}
              />
              <YAxis stroke={colors.charts.grid} yAxisId={1} orientation="right" />

              <Line
                animationDuration={600}
                type="monotone"
                dataKey="y"
                fill={colors.black}
                stroke="#fff"
                dot={{ r: 3 }}
                strokeWidth={2}
              />
              <Tooltip
                filterNull={false}
                labelStyle={{ display: 'none' }}
                formatter={(value: number, name: string, props: any) => {
                  if (!value) {
                    return ['N/A', null];
                  }
                  return [`$${value.toFixed(2)}`, null];
                }}
                separator=": "
                isAnimationActive={false}
                contentStyle={{
                  background: colors.black,
                  border: `1px solid ${colors.grey}`,
                  strokeWidth: 1,
                  fontSize: 14,
                  marginBottom: 1,
                  marginTop: 1,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {chartData && type.value === 'change' && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={seriesData} margin={{ right: -40 }}>
              <XAxis
                interval={0}
                dataKey="x"
                tick={{ fontSize: 12, fill: 'white' }}
                stroke={colors.charts.grid}
                tickLine={{ stroke: 'white' }}
                tickFormatter={(tick, index) => {
                  if (frequency.value === 'weekly') {
                    return (index + 1) % 4 === 0 || index === 0 ? tick : '';
                  }

                  return tick;
                }}
              />

              <CartesianGrid vertical={false} stroke={colors.charts.grid} />
              <YAxis
                stroke={colors.charts.grid}
                yAxisId={0}
                tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
                tickLine={{ stroke: 'white' }}
                tickFormatter={(tick) => `${tick}%`}
                orientation="left"
                padding={{ top: 10, bottom: 10 }}
              />
              <YAxis stroke={colors.charts.grid} yAxisId={1} orientation="right" />
              <Bar style={{ cursor: 'pointer' }} dataKey="change">
                {seriesData.map((series, i) => (
                  <Cell key={i} fill={series.change > 0 ? colors.charts.positive : colors.charts.negative} />
                ))}
              </Bar>
              <Tooltip
                isAnimationActive={false}
                cursor={{ fill: 'rgba(255, 255, 255, .1)' }}
                labelStyle={{ display: 'none' }}
                formatter={(value: number) => [`${value.toFixed(2)}%`]}
                contentStyle={{
                  background: colors.black,
                  border: `1px solid ${colors.grey}`,
                  strokeWidth: 1,
                  fontSize: 14,
                  marginBottom: 1,
                  marginTop: 1,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              />

              <ReferenceLine y={0} stroke={colors.grey} strokeWidth={2} />
            </BarChart>
          </ResponsiveContainer>
        )}
        <ChartLoader loading={loading || loadingYears} />
      </ChartContainer>
    </Wrapper>
  );
};
