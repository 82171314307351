const isProd = process.env.NODE_ENV !== 'development';

const awsConfig = {
	Auth: {
		region: 'us-central-1',
		userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
		mandatorySignIn: false,
		cookieStorage: {
			//// domain: isProd ? process.env.REACT_APP_AUTH_COOKIE_DOMAIN : '192.168.55.103',
			domain: isProd ? process.env.REACT_APP_AUTH_COOKIE_DOMAIN : 'localhost',
			path: '/',
			expires: 365,
			secure: isProd,
		},
	},
};

export default awsConfig;
