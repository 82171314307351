import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../slices/store';
import { Wrapper } from '../../Wrapper/Wrapper';
import { InfoLabel, InfoValue, StyledInfo } from './ReportInfo.styles';

export const ReportInfo = () => {
	const { activeProvince } = useSelector((state: RootState) => state.igs.map);
	return (
		<AnimateSharedLayout>
			<StyledInfo p="lg">
				<Wrapper alignContent="center" display="flex" mb="sm">
					<InfoLabel>Province</InfoLabel>
				</Wrapper>
				<AnimatePresence exitBeforeEnter>
					<InfoValue
						initial={{ opacity: 0 }}
						exit={{ opacity: 0, transition: { duration: 0.2 } }}
						animate={{ opacity: 1, transition: { duration: 0.2 } }}
						key={activeProvince}
					>
						{activeProvince}
					</InfoValue>
				</AnimatePresence>
			</StyledInfo>
		</AnimateSharedLayout>
	);
};
