import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { P } from '../Typography/Typography';

export const LoginButton = styled.button`
	${({ disabled, theme }) => css`
		background: transparent;
		border: 1px solid;
		border-color: ${disabled ? theme.colors.brandFontDark : 'white'};
		border-radius: 3px;
		color: ${disabled ? theme.colors.brandFontDark : 'white'};
		cursor: pointer;
		font-size: 14px;
		padding: 8px;
		transition: 0.3s all ease;
	`}
`;

export const LoginLink = styled.a`
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
`;

export const LoginGreetings = styled(motion.custom(P))``;

export const Logout = styled.a`
	${({ theme }) => css`
		font-weight: bold;
		color: ${theme.colors.inputPrimary};
	`}
`;

export const FieldMessage = styled.p<SpaceProps>`
	color: white;
	font-size: 14px;
	${space}
`;

export const LoginWrapper = styled(motion.div)<SpaceProps>`
	${space}
`;
