import { useLazyQuery, useMutation } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
  UpdateFlaggedInput,
  UpdateUserFlaggedResponse,
  UPDATE_FLAGGED_MUTATION,
} from '../../../graphql/mutations/contracts/updateFlagged.mutations';
import { BS_CONTRACTS_QUERY, BS_CONTRACT_DETAILS_QUERY } from '../../../graphql/queries/contracts/contracts.queries';
import { BSContractsData } from '../../../graphql/queries/contracts/contracts.types';
import { updateSelectedContract } from '../../../slices/icm/icm';
import { RootState } from '../../../slices/store';
import { Theme } from '../../../styles/theme';
import { transformContractData } from '../../../utils/contractsUtils';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Loader } from '../../Loader/Loader';
import { Wrapper } from '../../Wrapper/Wrapper';
import ContractsComment from '../ContractsComment/ContractsComment';
import { PurplePinIcon } from '../ContractsIcons/ContractsIcons';
import {
  StyledContractsDetails,
  StyledDetailsArrow,
  StyledDetailSectionDetails,
  StyledDetailSectionFlag,
  StyledDetailSectionFlagTitle,
  StyledDetailSectionTitle,
  StyledDetailsPlaceholder,
  StyledDetailTitle,
} from './StyledContractsDetails.styles';
import { last } from 'lodash';

const ContractsDetails: React.FC = () => {
  const { state } = useLocation<{ projectId: string }>();
  const { params } = useRouteMatch<{ projectId: string }>();
  const projectId = state?.projectId || params.projectId;

  const { colors } = useContext<Theme>(ThemeContext);
  const pageLimit = useSelector((state: RootState) => state.icm.pageLimit);
  const currentPage = useSelector((state: RootState) => state.icm.currentPage);
  const sortBy = useSelector((state: RootState) => state.icm.sortedBy);
  const filters = useSelector((state: RootState) => state.icm.filters);
  const selectedContractUid = useSelector((state: RootState) => state.icm.selectedContractUid);
  const dispatch = useDispatch();
  const [contractData, setContractData] = useState<BSContractsData | undefined>(undefined);

  const [getContractDetails, { loading, data }] = useLazyQuery<{
    contractDetails: BSContractsData;
  }>(BS_CONTRACT_DETAILS_QUERY, {
    onCompleted: (d) => {
      dispatch(updateSelectedContract(d.contractDetails));
      setContractData(d.contractDetails);
    },
    fetchPolicy: 'cache-and-network', // temporary fix to avoid the select/reselect issue where a contract details won't appear if selected twice in a row
    // apollo bug here: https://github.com/apollographql/react-apollo/issues/2177 and here https://github.com/apollographql/react-apollo/issues/3968
  });

  useEffect(() => {
    if (selectedContractUid) {
      getContractDetails({
        variables: {
          contractId: selectedContractUid,
        },
      });
    } else {
      setContractData(undefined);
    }
  }, [selectedContractUid, getContractDetails]);

  const contract = contractData;

  const [updateFlagged] = useMutation<UpdateUserFlaggedResponse, UpdateFlaggedInput>(UPDATE_FLAGGED_MUTATION, {
    //TODO: Handle error (With error boundaries?)
    // Need to refetch all contracts data once a flag is updated in details sidebar in order to keep flag data up to date
    refetchQueries: [
      {
        query: BS_CONTRACTS_QUERY,
        variables: {
          projectId,
          page: currentPage,
          limit: pageLimit,
          sortBy,
          filters,
        },
      },
      {
        query: BS_CONTRACT_DETAILS_QUERY,
        variables: {
          contractId: selectedContractUid,
        },
      },
      // {
      //   query: BS_CONTRACTS_MAP_QUERY,
      //   variables: {
      //     projectId: state?.projectId,

      //     skipPagination: true,
      //     filters,
      //   },
      // },
    ],
  });

  useEffect(() => {
    if (data) {
      setContractData(data.contractDetails);
    }
  }, [data]);

  const isFlagged = contract?.flag?.flagged;
  const handleToggle = (uid: string) => {
    updateFlagged({
      variables: {
        projectId: projectId,
        contractUid: uid,
        flagged: !isFlagged,
      },
    }).then(() => {
      getContractDetails({
        variables: {
          contractId: selectedContractUid,
        },
      });
    });
  };

  const transformedData: any = useMemo(() => transformContractData(contract), [contract]); //TODO: Fix typing and use TransformedBSContractData
  return (
    <StyledContractsDetails>
      <StyledDetailTitle variant={contract ? 'active' : 'inactive'}>
        <PurplePinIcon color={contract ? colors.brandPrimary : colors.grey} width="10px" mr="md" />
        <h3>Selected field information</h3>
      </StyledDetailTitle>
      {selectedContractUid && contract && transformedData ? (
        <>
          <Wrapper
            data-testid="contract-data"
            position="relative"
            display="flex"
            flex={1}
            justifyContent="space-between"
            flexDirection="column"
            alignContent="space-between"
            overflowY="scroll"
          >
            <Wrapper overflowY="scroll">
              <StyledDetailSectionDetails>
                <tbody>
                  {Object.keys(transformedData).map((dataSetName: string) => {
                    return (
                      <React.Fragment key={dataSetName}>
                        <tr>
                          <StyledDetailSectionTitle colSpan={3}>
                            <h3>{dataSetName}</h3>
                          </StyledDetailSectionTitle>
                        </tr>
                        {Object.keys(transformedData?.[dataSetName]).map((datumTitle: string) => {
                          return (
                            <tr key={datumTitle}>
                              <th style={{ whiteSpace: 'nowrap' }}>{datumTitle}</th>
                              <td>{transformedData?.[dataSetName]?.[datumTitle]}</td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </StyledDetailSectionDetails>
            </Wrapper>
            <StyledDetailSectionFlag>
              <StyledDetailSectionFlagTitle>
                <h3>Flag</h3>
                <Wrapper display="flex">
                  <h3>Include </h3>
                  <Checkbox
                    onChange={() => handleToggle(contract?.inference_cm_id)}
                    toggled={contract?.flag?.flagged || false}
                  />
                </Wrapper>
              </StyledDetailSectionFlagTitle>
              <ContractsComment comment={last(contract?.comments)?.comment} disabled={!isFlagged} />
            </StyledDetailSectionFlag>
          </Wrapper>
        </>
      ) : (
        <Wrapper display="flex" flex="1" justifyContent="center" alignItems="center">
          <Wrapper display="flex">
            <StyledDetailsArrow>←</StyledDetailsArrow>
            <StyledDetailsPlaceholder data-testid="no-contract">
              Click on a row to show
              <br />
              the field information.
            </StyledDetailsPlaceholder>
          </Wrapper>
        </Wrapper>
      )}
      <AnimatePresence>
        {loading && (
          <motion.div
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              zIndex: 5,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Loader />
          </motion.div>
        )}
      </AnimatePresence>
    </StyledContractsDetails>
  );
};

export default ContractsDetails;
