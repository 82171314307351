import React from 'react';
import { useHistory } from 'react-router-dom';
import { P } from '../Typography/Typography';
import { Wrapper } from '../Wrapper/Wrapper';
import ArrowIcon from './arrow.svg';
import { NotFoundIcon, NotFoundLink, NotFoundWrapper } from './NotFound.styles';

export const NotFound = () => {
	const history = useHistory();

	const onBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.replace('/');
		}
	};
	return (
		<Wrapper height="100%" width="100%" display="flex">
			<NotFoundWrapper width="50%" height="100%" display="flex" alignItems="center">
				<Wrapper px="xl">
					<P fontSize="36px" mb="lg">
						Snap!
					</P>
					<P fontSize="36px">Probably our fault, but there is nothing to see here.</P>
					<Wrapper style={{ cursor: 'pointer' }} as="span" mt="lg" display="flex" alignItems="center" onClick={onBack}>
						<NotFoundIcon mr="md" src={ArrowIcon} alt="Go back" /> <NotFoundLink>Take me back</NotFoundLink>
					</Wrapper>
				</Wrapper>
			</NotFoundWrapper>
			{/* TODO: implement transition on load */}
			<Wrapper
				width="50%"
				height="100%"
				backgroundImage={`url(${process.env.PUBLIC_URL}/wheatbelt_western_australia.jpg)`}
				backgroundSize="cover"
				backgroundPosition="center"
			></Wrapper>
		</Wrapper>
	);
};
