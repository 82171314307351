import styled from 'styled-components';

export const ContractsCommentBox = styled.textarea`
  &:read-only {
    cursor: initial;
    &:focus {
      outline: none;
    }
  }
`;
