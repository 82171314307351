import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { opacity, OpacityProps } from 'styled-system';
import { Wrapper } from '../../Wrapper/Wrapper';

export const AnalysisMapContainer = styled(Wrapper)<OpacityProps>`
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  height: 100%;
  position: relative;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s 0.2s ease;
  }
  .mapboxgl-canvas-container {
    height: 100%;
  }
  ${opacity}
`;

export const AnalysisMapLoader = styled(motion.div)`
  ${({ theme }) => css`
    background: ${theme.colors.brandSecondary};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    z-index: 1;
  `}
`;
