import { scaleLinear, scaleQuantile } from 'd3-scale';

export const AR_CHANGE_SCALE = (minChange: number, maxChange: number) => {
  // only include 0 as a middle ground if maxChange > 0
  const domain = maxChange > 0 ? [minChange, 0, maxChange] : [minChange, maxChange];
  return scaleQuantile<string>()
    .domain(domain)
    .range([
      '#FF454C',
      '#EC464E',
      '#D9474F',
      '#C74851',
      '#B44952',
      '#A14A54',
      '#8E4B56',
      '#7B4C57',
      '#694D59',
      '#564E5A',
      '#434F5C',
      '#446161',
      '#457265',
      '#46846A',
      '#47956F',
      '#49A773',
      '#4AB978',
      '#4BCA7D',
      '#4CDC82',
      '#4DED86',
      '#4EFF8B',
    ]);
};

export const AR_COLOR_SCALE = scaleLinear<string, string, string>().domain([0, 1]).range(['#595C67', '#8E81FF']);
