import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { FieldMessage } from '../Login/Login.styles';
import { P } from '../Typography/Typography';

export interface NewPasswordForm {
  code: string;
  password: string;
  passwordConfirm: string;
  apiError?: string;
}

export const NewPassword: React.FC<{ username: string }> = ({ username }) => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const { errors, values, handleChange, handleSubmit, setFieldError, touched } = useFormik<NewPasswordForm>({
    initialValues: {
      code: '',
      password: '',
      passwordConfirm: '',
      apiError: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (data: any) => {
      Auth.forgotPasswordSubmit(username, data.code, data.password)
        .then(() => {
          setPasswordUpdated(true);
        })
        .catch((err) => {
          setFieldError('apiError', err.message);
        });
    },
  });

  if (passwordUpdated) {
    return (
      <div>
        <P mb="md">Your password has been updated</P>
        <P fontSize="14px" lineHeight="18px" mt="md" mb="lg">
          You can now login again using your new password.
        </P>
        <Link to="/login">Back to login</Link>
      </div>
    );
  }

  return (
    <div>
      <P mb="md">Reset your password</P>
      <P fontSize="14px" lineHeight="18px" mt="md">
        We sent you an email with a verification code. Please enter it here and choose a new password.
      </P>
      <P fontSize="14px" lineHeight="18px" mt="md" mb="lg">
        Your password needs to contain at least: <b>1 lowercase, 1 uppercase, 8 characters.</b>
      </P>

      <form onSubmit={handleSubmit}>
        <Input
          mb="md"
          status={touched.code && errors.code ? 'error' : undefined}
          name="code"
          label="Verification code"
          value={values.code}
          onChange={handleChange}
          type="text"
        />
        <Input
          mb="md"
          status={touched.password && errors.password ? 'error' : undefined}
          name="password"
          label="New password"
          value={values.password}
          onChange={handleChange}
          type="password"
        />
        <Input
          mb="md"
          name="passwordConfirm"
          label="Confirm your new password"
          type="password"
          status={touched.passwordConfirm && errors.passwordConfirm ? 'error' : undefined}
          value={values.passwordConfirm}
          onChange={handleChange}
        />

        {errors && Object.keys(errors).length > 0 && <FieldMessage my="md">{Object.values(errors)[0]}</FieldMessage>}

        <Button>Submit</Button>
      </form>
    </div>
  );
};
