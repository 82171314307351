import gql from 'graphql-tag';

export const CONTRACT_FILTERS_QUERY = gql`
  query contractManagementFilters($projectId: ID!) {
    contractManagementFilters(projectId: $projectId) {
      contractYears
      factories
      yvLabels
      # TODO: Add the remaining filters
    }
  }
`;

export const BS_CONTRACTS_QUERY = gql`
  query contractManagement(
    $projectId: ID!
    $limit: Int
    $page: Int
    $sortBy: BSSortBy
    $filters: BSContractFiltersInput
  ) {
    contractManagement(projectId: $projectId, limit: $limit, page: $page, sortBy: $sortBy, filters: $filters) {
      contracts {
        inference_cm_id
        created
        updated_at
        contractYear
        contractNumber
        contractManager1
        contractManager2
        fieldId
        fieldName
        fieldArea
        soilType
        latitude
        longitude
        drilledArea
        drilledDate
        treatment
        factory
        harvested
        cropGrown
        areaGrown
        rotation
        gt_yellowVirusPresent
        gt_canopyBurden
        gt_weedBurden
        hsat_cropHealthLevel
        hsat_cropHealthScore
        hsat_yvRiskLevel
        hsat_yvRiskScore
        flag {
          id
          flagged
        }
        comments {
          comment
        }
      }
      pagination {
        count
        page
        maxPage
        sortBy {
          filter
          order
        }
      }
      filters {
        contractYears
        contractNumber
        fieldId
        fieldArea {
          from
          to
        }
        factory
        cropHealth {
          from
          to
        }
        yvRisk
        harvested {
          from
          to
        }
        flag
      }
    }
  }
`;

export const BS_CONTRACTS_MAP_QUERY = gql`
  query contractManagement($projectId: ID!, $skipPagination: Boolean, $filters: BSContractFiltersInput) {
    contractManagement(projectId: $projectId, skipPagination: $skipPagination, filters: $filters) {
      contracts {
        inference_cm_id
        latitude
        longitude
        hsat_yvRiskLevel
      }
    }
  }
`;

export const CONTRACTS_MAP_CONFIG = gql`
  query contractMapConfig($projectId: ID!) {
    contractMapConfig(projectId: $projectId) {
      latitude
      longitude
      zoom
      minZoom
      maxZoom
    }
  }
`;

export const BS_CONTRACT_DETAILS_QUERY = gql`
  query contractDetails($contractId: ID!) {
    contractDetails(contractId: $contractId) {
      inference_cm_id
      created
      updated_at
      contractYear
      contractNumber
      contractManager1
      contractManager2
      fieldId
      fieldName
      fieldArea
      soilType
      latitude
      longitude
      drilledArea
      drilledDate
      treatment
      factory
      harvested
      cropGrown
      areaGrown
      rotation
      gt_yellowVirusPresent
      gt_canopyBurden
      gt_weedBurden
      hsat_cropHealthLevel
      hsat_cropHealthScore
      hsat_yvRiskLevel
      hsat_yvRiskScore
      flag {
        id
        flagged
      }
      comments {
        comment
      }
    }
  }
`;
