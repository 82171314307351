import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { AnalysisMap } from '../../../components/Analysis/AnalysisMap/AnalysisMap';
import { AnalysisNav } from '../../../components/Analysis/AnalysisNav/AnalysisNav';
import { Dashboard } from '../../../components/Dashboard/Dashboard';
import { DashboardMetadata } from '../../../components/Dashboard/DashboardMetadata/DashboardMetadata';
import { NotFound } from '../../../components/NotFound/NotFound';
import { SEO } from '../../../components/SEO/SEO';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { ANALYSIS_QUERY } from '../../../graphql/queries/analysis/analysis.queries';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { updateActiveFilter, updateCurrentYear, updateHistoricalData } from '../../../slices/iar/iar.slice';
import { formatHistoricalData } from './AnalysisPage.utils';

export const AnalysisPage: React.FC = () => {
  const dispatch = useDispatch();
  const [availableYears, setYears] = useState<number[]>();
  const location = useLocation();
  const { params } = useRouteMatch<{ analysisId: string }>();
  const {
    data: analysisData,
    loading,
    error,
  } = useQuery<{ analysis: AnalysisData }, { id: string }>(ANALYSIS_QUERY, {
    variables: {
      id: params.analysisId,
    },
  });
  const [selectedYear, updateYear] = useState<number | undefined>(undefined);

  useEffect(() => {
    // reset filter & data on unmount
    return () => {
      dispatch(updateActiveFilter(undefined));
      dispatch(updateHistoricalData({}));
    };
  }, []);

  useEffect(() => {
    if (analysisData?.analysis) {
      const historicalData = formatHistoricalData(analysisData.analysis);
      const years = analysisData.analysis.level3.map((d) => new Date(d.date).getFullYear()).sort((a, b) => b - a);
      setYears(years);
      dispatch(updateCurrentYear(years[0]));
      dispatch(updateHistoricalData(historicalData));
    }
  }, [analysisData?.analysis, dispatch]);

  if (loading) {
    return null;
  }

  if (!analysisData?.analysis || error) {
    return <NotFound />;
  }

  return (
    <>
      <SEO title="Analysis & Review" />
      <AnalysisNav coverage={analysisData.analysis.coverage} years={availableYears} />
      <Wrapper display="flex" height="calc(100% - 33px)" width="100%">
        <Wrapper flex="1 1 80%">
          <Switch>
            <Route path={`/analysis/${params.analysisId}/dashboard`}>
              <Dashboard analysisData={analysisData.analysis} />
            </Route>
            <Route path={`/analysis/${params.analysisId}/map`}>
              <AnalysisMap analysisData={analysisData.analysis} onYearUpdate={(year?: number) => updateYear(year)} />
            </Route>
            <Route exact path={`/analysis/:analysisId/`}>
              <Redirect to={`/analysis/${params.analysisId}/dashboard`} />
            </Route>
          </Switch>
        </Wrapper>
        <Wrapper flex="1 1 20%" overflow="auto">
          <DashboardMetadata
            analysisData={analysisData.analysis}
            useAsFilter={location.pathname.endsWith('map')}
            selectedYear={selectedYear}
          />
        </Wrapper>
      </Wrapper>
    </>
  );
  // return (
  // 	<>
  // 		<SEO title="Analysis & Review" />
  // 		<AnalysisNav coverage={analysisData.analysis.coverage} />
  // 		<Switch>
  // 			<Route path={`/analysis/${params.analysisId}/dashboard`}>
  // 				<motion.div
  // 					key="dashboard"
  // 					style={{ height: `calc(100% - 33px)` }}
  // 					initial={{ opacity: 0, x: -10 }}
  // 					animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
  // 					exit={{ opacity: 0, x: -10 }}
  // 					transition={{ duration: 0.3 }}
  // 				>
  // 					<Dashboard analysisData={analysisData.analysis} />
  // 				</motion.div>
  // 			</Route>

  // 			<Route path={`/analysis/${params.analysisId}/map`}>
  // 				<motion.div
  // 					key="map"
  // 					style={{ height: `calc(100% - 33px)` }}
  // 					initial={{ opacity: 0, x: -10 }}
  // 					animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
  // 					exit={{ opacity: 0, x: -10 }}
  // 					transition={{ duration: 0.3 }}
  // 				>
  // 					<AnalysisMap analysisData={analysisData.analysis} />
  // 				</motion.div>
  // 			</Route>
  // 			{/* Redirect default to dashboard*/}
  // 			<Route exact path={`/analysis/:analysisId/`}>
  // 				<Redirect to={`/analysis/${params.analysisId}/dashboard`} />
  // 			</Route>
  // 		</Switch>
  // 	</>
  // );
};
