import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { numberWithCommas } from '../../../App.utils';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { BSContractFilters } from '../../../graphql/queries/contracts/contracts.types';
import { updateActiveFilter } from '../../../slices/iar/iar.slice';
import ContractsFilters from '../ContractsFilters/ContractsFilters';
import { FilterIcon, MapIcon, TableIcon } from '../ContractsIcons/ContractsIcons';
import {
  ContractsNavTab,
  StyledContractsNav,
  StyledContractsNavFilters,
  StyledResultsCounter,
} from './ContractsNav.styles';

const ContractsNav: React.FC<{ coverage?: string; filters?: BSContractFilters; resultsCount?: number }> = ({
  coverage,
  filters,
  resultsCount,
}) => {
  const { params } = useRouteMatch<{ projectId: string }>();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <StyledContractsNav>
      <StyledContractsNavFilters>
        <>
          <Wrapper px="md">
            <FilterIcon height="12px" width="12px" />
          </Wrapper>
          <ContractsFilters filters={filters} />
        </>
      </StyledContractsNavFilters>
      <Wrapper display="flex">
        <StyledResultsCounter>
          <strong>{numberWithCommas(resultsCount || 0)}</strong> results
        </StyledResultsCounter>
        <ContractsNavTab
          onClick={() => dispatch(updateActiveFilter(undefined))}
          as={Link}
          to={{ pathname: `/contracts/${params.projectId}/table`, state: { projectId: params.projectId } }}
          variant={location.pathname.endsWith('table') ? 'active' : 'inactive'}
        >
          <TableIcon
            color={location.pathname.endsWith('table') ? 'grey' : 'white'}
            bg={location.pathname.endsWith('table') ? 'white' : 'grey'}
          />
          Table
        </ContractsNavTab>
        <ContractsNavTab
          onClick={() => dispatch(updateActiveFilter(undefined))}
          as={Link}
          to={{ pathname: `/contracts/${params.projectId}/map`, state: { projectId: params.projectId } }}
          variant={location.pathname.endsWith('map') ? 'active' : 'inactive'}
        >
          <MapIcon color={location.pathname.endsWith('map') ? 'grey' : 'white'} />
          {/* <ContractsNavTabIcon
            src={MapIcon}
            alt="View map"
            color={location.pathname.endsWith('map') ? 'grey' : 'white'}
          /> */}
          Map
        </ContractsNavTab>
      </Wrapper>
    </StyledContractsNav>
  );
};

export default ContractsNav;
