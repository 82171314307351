import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { APP_ROUTES } from '../../App.routes';
import { CognitoUserExt } from '../../App.types';
import { AuthState, updateAuthStatus } from '../../slices/auth/auth';
import { RootState } from '../../slices/store';

export const PrivateRoute: React.FC<{ path: string }> = ({ children, ...rest }) => {
	const dispatch = useDispatch();
	const { authStatus } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((userData: CognitoUserExt) => {
				dispatch(updateAuthStatus(AuthState.SIGNED_IN));
			})
			.catch(() => {
				dispatch(updateAuthStatus(AuthState.SIGNED_OUT));
			});
	}, [dispatch]);

	// authStatus will be PENDING on mount - wait until data resolves
	if (authStatus === AuthState.PENDING) {
		return null;
	}

	return (
		<Route
			{...rest}
			render={({ location }) =>
				authStatus === AuthState.SIGNED_IN ? (
					children
				) : (
					<Redirect
						to={{
							pathname: APP_ROUTES.LOGIN.path,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};
