import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

export const StyledContractsMapLegend = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    padding: ${theme.space.md};
    z-index: 1;
  `}
`;

export const ContractsLegend = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.blackLight};
    border: 1px solid ${theme.colors.grey};
    display: flex;
  `}
`;

export const ContractsLegendTitle = styled.div`
  ${({ theme }) => css`
    border-right: 1px solid ${theme.colors.grey};
    padding: ${theme.space.sm};
  `}
`;

export const ContractsLegendItem = styled.div<{ variant?: 'low' | 'medium' | 'high' }>`
  ${({ theme }) => css`
    background: ${theme.colors.greyLight};
    height: 8px;
    width: 8px;
    margin-right: ${theme.space.sm};
    ${variant({
      variants: {
        low: {
          background: theme.colors.map.riskLow,
        },
        medium: {
          background: theme.colors.map.riskMed,
        },
        high: {
          background: theme.colors.map.riskHigh,
        },
      },
    })}
  `}
`;
