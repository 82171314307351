//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'; // exclude mapbox from transpilation - see https://github.com/mapbox/mapbox-gl-js/issues/10173
import { Deck, FlyToInterpolator, Layer, WebMercatorViewport } from '@deck.gl/core';
import { PickInfo } from 'deck.gl';
import { noop } from '../../App.utils';
import { MapConfig } from '../../graphql/queries/mapConfig/mapConfig.types';
import { GS } from '../GroundSurveyReport/GroundSurveyReport.constants';
import { InitialViewState, INITIAL_VIEW_STATE, MAPBOX_ACCESS_TOKEN, MAP_STYLE } from './InteractiveMap.constants';

export interface InteractiveMapProps {
  mapRef: HTMLDivElement;
  canvasRef: HTMLCanvasElement;
  config?: MapConfig;
  showPointer?: boolean;
  onClick?: (d: PickInfo<any>) => void;
}

export class InteractiveMap {
  private map: mapboxgl.Map;
  private layers: Layer<any, any>[];
  private viewState: InitialViewState;
  private mapRef: HTMLDivElement;
  private canvasRef: HTMLCanvasElement;
  private deck: Deck;
  private config: MapConfig | undefined;
  private showPointer: boolean;
  private onClick: (d: PickInfo<any>) => void;
  private debugMode: boolean = false;

  public onMapReady = noop;

  constructor({ canvasRef, mapRef, config, showPointer = false, onClick = noop }: InteractiveMapProps) {
    this.debugMode = window.location.search.includes('debug');
    this.mapRef = mapRef;
    this.canvasRef = canvasRef;
    this.config = config;
    this.layers = [];
    this.onClick = onClick;
    this.showPointer = showPointer;

    this.init();
  }

  private init() {
    const { width, height } = this.canvasRef.getBoundingClientRect();

    this.viewState = {
      ...INITIAL_VIEW_STATE,
      // altitude: INITIAL_VIEW_STATE.altitude,
      // bearing: INITIAL_VIEW_STATE.bearing,
      latitude: this.config?.latitude || INITIAL_VIEW_STATE.latitude,
      longitude: this.config?.longitude || INITIAL_VIEW_STATE.longitude,
      // maxPitch: INITIAL_VIEW_STATE.maxPitch,
      maxZoom: this.config?.maxZoom || INITIAL_VIEW_STATE.maxZoom,
      minZoom: this.config?.minZoom || INITIAL_VIEW_STATE.minZoom,
      zoom: this.config?.zoom || INITIAL_VIEW_STATE.zoom,
      width,
      height,
    };

    this.map = new mapboxgl.Map({
      container: this.mapRef,
      attributionControl: false,
      accessToken: MAPBOX_ACCESS_TOKEN,
      preserveDrawingBuffer: true,
      style: MAP_STYLE,
      // Note: deck.gl will be in charge of interaction and event handling
      interactive: false,
      center: [this.viewState.longitude, this.viewState.latitude],
      zoom: this.viewState.zoom,
      bearing: this.viewState.bearing,
      pitch: this.viewState.pitch,
    });

    this.deck = new Deck({
      onClick: this.onClick,
      //@ts-ignore
      getCursor: ({ isHovering, isDragging }) =>
        isDragging ? 'grabbing' : isHovering && this.showPointer ? 'pointer' : 'grab',
      canvas: this.canvasRef,
      width: '100%',
      height: '100%',
      initialViewState: this.viewState,
      controller: {
        //@ts-ignore
        inertia: 1,
      },
      layers: this.layers,
      effects: [],
      onViewStateChange: ({ viewState }) => {
        if (this.debugMode) {
          console.log(`Latitude: ${viewState.latitude}, Longitude: ${viewState.longitude}, Zoom: ${viewState.zoom}`);
        }
        // const vState = { ...viewState };
        // if (vState.longitude > VIEW_STATE_CONSTRAINTS.longitude[1]) {
        // 	vState.longitude = VIEW_STATE_CONSTRAINTS.longitude[1];
        // }
        // if (vState.longitude < VIEW_STATE_CONSTRAINTS.longitude[0]) {
        // 	vState.longitude = VIEW_STATE_CONSTRAINTS.longitude[0];
        // }

        // if (vState.latitude > VIEW_STATE_CONSTRAINTS.latitude[1]) {
        // 	vState.latitude = VIEW_STATE_CONSTRAINTS.latitude[1];
        // }
        // if (vState.latitude < VIEW_STATE_CONSTRAINTS.latitude[0]) {
        // 	vState.latitude = VIEW_STATE_CONSTRAINTS.latitude[0];
        // }
        const vState = {
          ...viewState,
          // longitude: Math.min(
          // 	VIEW_STATE_CONSTRAINTS.longitude[1],
          // 	Math.max(VIEW_STATE_CONSTRAINTS.longitude[0], viewState.longitude),
          // ),
          // latitude: Math.min(
          // 	VIEW_STATE_CONSTRAINTS.latitude[1],
          // 	Math.max(VIEW_STATE_CONSTRAINTS.latitude[0], viewState.latitude),
          // ),
        };

        this.map.jumpTo({
          center: [vState.longitude, vState.latitude],
          zoom: vState.zoom,
          bearing: vState.bearing,
          pitch: vState.pitch,
        });

        this.viewState = vState;
        return vState;
      },
    });

    //only run once - mock "loaded" event
    this.map.once('idle', () => {
      this.onMapReady();
    });
  }

  public setLayers(layers: Layer<any, any>[]) {
    this.layers = layers;
    this.render();
  }

  public flyToStart() {
    this.viewState = {
      ...this.viewState,
      latitude: this.config?.latitude || INITIAL_VIEW_STATE.latitude,
      longitude: this.config?.longitude || INITIAL_VIEW_STATE.longitude,
      zoom: this.config?.zoom || INITIAL_VIEW_STATE.zoom,
      //@ts-ignore
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 'auto',
    };

    this.deck.setProps({ initialViewState: this.viewState });
  }

  public flyTo(bbox: [number, number, number, number]) {
    const [minLng, minLat, maxLng, maxLat] = bbox;
    const viewport = new WebMercatorViewport(this.viewState);
    //@ts-ignore
    const { longitude, latitude, zoom } = viewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: { left: GS.menuSize, top: 120, bottom: 120, right: 120 },
      },
    );

    this.viewState = {
      ...this.viewState,
      latitude,
      longitude,
      zoom,
      //@ts-ignore
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 'auto',
    };

    this.deck.setProps({ initialViewState: this.viewState });
  }

  private render() {
    this.deck.setProps({ layers: this.layers });
  }
}
