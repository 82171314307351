import { CONTRACTS_FILTER_TYPES } from '../Contracts/Contracts.constants';

export const FILTERS_LABELS = {
  [CONTRACTS_FILTER_TYPES.ContractYears]: 'Contract years',
  [CONTRACTS_FILTER_TYPES.ContractNumber]: 'Contract Number',
  [CONTRACTS_FILTER_TYPES.FieldId]: 'Field ID',
  [CONTRACTS_FILTER_TYPES.Factory]: 'Factory',
  [CONTRACTS_FILTER_TYPES.FieldArea]: 'Field Area',
  [CONTRACTS_FILTER_TYPES.CropHealth]: 'Crop Health',
  [CONTRACTS_FILTER_TYPES.YvRisk]: 'YV Risk',
  [CONTRACTS_FILTER_TYPES.Harvested]: 'Harvested',
  [CONTRACTS_FILTER_TYPES.Flag]: 'Flagged',
};
