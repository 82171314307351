import { Variants } from 'framer-motion';

export const SUMMARY_VARIANTS: Variants = {
	hide: {
		opacity: 0,
		x: -20,
	},
	show: (i) => ({
		opacity: 1,
		x: 0,
		transition: {
			duration: 0.4,
			delay: 0.2 * i,
		},
	}),
};
