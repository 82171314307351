import { AnimateSharedLayout, useAnimation, Variants } from 'framer-motion';
import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { FeatureData } from '../../../../graphql/queries/report/report.types';
import { toggleFeature } from '../../../../slices/igs/igs';
import { RootState } from '../../../../slices/store';
import { ReportSidebarItem } from '../ReportSidebarItem/ReportSidebarItem';
import { StyledReportSidebarMenu } from './ReportSidebarMenu.styles';

const menuVariants: Variants = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			delayChildren: 0.5,
			staggerChildren: 0.25,
		},
	},
};

export const ReportSidebarMenu: React.FC<{ features: FeatureData[] }> = ({ features }) => {
	const dispatch = useDispatch();
	const { map } = useSelector((state: RootState) => state.igs);
	const controls = useAnimation();

	const sortedFeatures = features.slice().sort((a, b) => (a.order || 0) - (b.order || 0));

	useEffect(() => {
		// trigger animation on mount, then toggle 1st feature onComplete
		const showMenu = async () => {
			await controls.start('show');
			const firstFeature = sortedFeatures[0].id;

			if (firstFeature) {
				dispatch(toggleFeature(firstFeature));
			}
		};

		showMenu();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onMenuClick = (feature: FeatureData) => {
		dispatch(toggleFeature(feature.id));
		ReactGA.event({ category: 'IGS Report', action: 'Clicked on menu', label: feature.name });
	};

	return useMemo(
		() => (
			<AnimateSharedLayout>
				<StyledReportSidebarMenu layout variants={menuVariants} initial="hidden" animate={controls}>
					{sortedFeatures?.map((feature) => (
						<ReportSidebarItem
							active={feature.id === map.activeFeature}
							key={feature.id}
							name={feature.name}
							onClick={() => onMenuClick(feature)}
							value={feature.description || ''}
						/>
					))}
				</StyledReportSidebarMenu>
			</AnimateSharedLayout>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[map.activeFeature],
	);
};
