import React from 'react';
import { ReportData } from '../../../../graphql/queries/report/report.types';
import { Button } from '../../../Button/Button';
import { Wrapper } from '../../../Wrapper/Wrapper';

export const ReportSidebarFooter: React.FC<{ reportData: ReportData }> = ({ reportData }) => {
  return (
    <Wrapper as="footer" display="flex">
      {/* <Button mr="xs" as="a" ext="GeoJSON" download href={reportData?.geoJsonReport?.url || ''}>
				Data
			</Button> */}
      <Button as="a" target="_blank" ext="PDF" download href={reportData?.pdfReportUrl || ''}>
        Report
      </Button>
    </Wrapper>
  );
};
