import styled, { css } from 'styled-components';
import { compose, flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

export const StyledChart = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grey};
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
`;

export const ChartWrapper = styled.div`
  ${({ theme }) => css`
    flex: 1 1 auto;
    height: 50%;
    overflow: hidden;
    padding: ${theme.space.md} ${theme.space.md} 0 ${theme.space.md};
    &:last-of-type {
      padding-bottom: ${theme.space.md};
    }
  `}
`;

export const ChartLegend = styled.div<FlexboxProps>`
  ${({ theme }) => css`
    background: ${theme.colors.black};
    border-bottom: 1px solid ${theme.colors.grey};
    display: flex;
    font-size: 12px;
    padding-left: ${theme.space.md};
    ${flexbox}
  `}
`;

export const ChartHeader = styled.header`
  ${({ theme }) => css`
    background: ${theme.colors.blackLight};
    border-bottom: 1px solid ${theme.colors.grey};
    /* padding: ${theme.space.md} ${theme.space.lg}; */
  `}
`;

// export const ChartHeaderValue = styled.h1`
//   font-size: 35px;
//   letter-spacing: 0.53px;
// `;

// export const ChartHeaderChange = styled.span<{ variant?: 'success' | 'error' }>`
//   ${({ theme }) => css`
//     font-size: 18px;
//     letter-spacing: 1.35px;
//     line-height: 23px;
//     margin-left: ${theme.space.md};
//     ${variant({
//       variants: {
//         success: {
//           color: theme.colors.dashboardCardGreen,
//         },
//         error: {
//           color: theme.colors.dashboardCardRed,
//         },
//       },
//     })}
//   `}
// `;

// export const ChartHeaderInfo = styled.div`
//   ${({ theme }) => css`
//     align-items: center;
//     border-bottom: 1px solid ${theme.colors.grey};
//     display: flex;
//     justify-content: space-between;
//     padding: ${theme.space.md};
//   `}
// `;

// export const ChartHeaderTitle = styled(Wrapper)`
//   display: flex;
//   font-size: 14px;
// `;

// export const ChartHeaderIcon = styled.img<SpaceProps>`
//   ${({ theme }) => css`
//     height: 14px;
//     margin-right: ${theme.space.md};
//     ${space}
//   `}
// `;

export const ChartLegendItem = styled.span<SpaceProps & FlexboxProps>`
  display: flex;
  font-size: 12px;
  letter-spacing: 0.18px;
  line-height: 15px;
  ${compose(flexbox, space)}
`;

export const ChartLegendIcon = styled.img<SpaceProps>`
  ${({ theme }) => css`
    margin-right: ${theme.space.xs};
  `}
  ${space}
`;

export const ChartContainer = styled.div`
  flex: 1 1 100%;
  overflow: hidden;
  position: relative;
`;

export const NoDataMessage = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${theme.colors.black};
    padding: ${theme.space.md};
    z-index: 2;
    font-weight: bold;
  `}
`;
