import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  UpdateCommentInput,
  UpdateCommentResponse,
  UPDATE_COMMENT_MUTATION,
} from '../../../graphql/mutations/contracts/updateComments.mutations';
import { BS_CONTRACTS_QUERY, BS_CONTRACT_DETAILS_QUERY } from '../../../graphql/queries/contracts/contracts.queries';
import { RootState } from '../../../slices/store';
import { Wrapper } from '../../Wrapper/Wrapper';
import { StyledCommentDisabled, StyledDetailSectionComment } from '../ContractsDetails/StyledContractsDetails.styles';
import { ContractsCommentBox } from './ContractsComment.styles';

const ContractsComment: React.FC<{ comment?: string; disabled?: boolean }> = ({ comment, disabled }) => {
  const { state } = useLocation<{ projectId: string }>();
  const { params } = useRouteMatch<{ projectId: string }>();
  const projectId = state?.projectId || params.projectId;

  const pageLimit = useSelector((state: RootState) => state.icm.pageLimit);
  const currentPage = useSelector((state: RootState) => state.icm.currentPage);
  const selectedContractUid = useSelector((state: RootState) => state.icm.selectedContractUid);
  const sortBy = useSelector((state: RootState) => state.icm.sortedBy);
  const filters = useSelector((state: RootState) => state.icm.filters);

  const [message, setMessage] = useState<string>(comment || '');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [updateComment, { loading: updatingComment }] = useMutation<UpdateCommentResponse, UpdateCommentInput>(
    UPDATE_COMMENT_MUTATION,
    {
      refetchQueries: [
        {
          query: BS_CONTRACTS_QUERY,
          variables: {
            projectId,
            page: currentPage,
            limit: pageLimit,
            sortBy,
            filters,
          },
        },
        {
          query: BS_CONTRACT_DETAILS_QUERY,
          variables: {
            contractId: selectedContractUid,
          },
        },
      ],
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        toast.success('Comment successfully updated.');
        setIsEditing(false);
      },
    },
  );

  useEffect(() => {
    setMessage(comment || '');
    setIsEditing(false);
  }, [selectedContractUid, comment, setMessage]);

  const handleCommentSubmit = (event: any) => {
    if (!disabled) {
      event.preventDefault();
      updateComment({
        variables: {
          projectId,
          contractUid: selectedContractUid || '',
          comment: message,
        },
      });
    } else {
      toast.error(`Flag must be enabled to save a comment`);
    }
  };
  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };
  return (
    <StyledDetailSectionComment key="text-area" data-testid="is-flagged">
      {/* TODO: Animate presence and style appropriately */}
      {disabled && <StyledCommentDisabled />}
      <form action="" onSubmit={handleCommentSubmit}>
        <ContractsCommentBox
          readOnly={!isEditing}
          value={message}
          onChange={handleChange}
          name="flag-message"
          id="flag-message"
          cols={30}
          rows={10}
        ></ContractsCommentBox>
        <hr />
        <Wrapper display="flex" justifyContent="flex-end">
          {isEditing ? (
            <input type="submit" disabled={updatingComment} value={`${updatingComment ? 'Saving...' : 'Save'}`} />
          ) : (
            <button
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </button>
          )}
        </Wrapper>
      </form>
    </StyledDetailSectionComment>
  );
};

export default ContractsComment;
