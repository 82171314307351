import React from 'react';
import { Legend, Mono } from '../../../Typography/Typography';
import { ReportMetric } from '../../ReportSummary/ReportSummary.types';
import { formatMetric } from '../ReportMetrics.utils';
import { StyledMetric } from './MetricCard.styles';

export type MetricVariant = 'light' | 'dark';
export const MetricCard: React.FC<Pick<ReportMetric, 'name' | 'value'> & { variant?: MetricVariant }> = ({
	name,
	value,
	variant = 'light',
}) => {
	return (
		<StyledMetric px="md" py={{ default: 'md' }} variant={variant}>
			<Legend fontSize={{ default: '10px', sm: '12px' }}>{name}</Legend>
			<Mono mt="xs" mb="0" fontSize={{ default: '30px', sm: '42px' }}>
				{formatMetric(value)}
			</Mono>
		</StyledMetric>
	);
};
