import React from 'react';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../slices/icm/icm';
import SelectCheckboxes from '../../SelectCheckboxes/SelectCheckboxes';
import { Wrapper } from '../../Wrapper/Wrapper';

const ContractYearFilter: React.FC<{ filtersData: { contractYears: number[] } }> = ({ filtersData }) => {
	const contractYears: number[] = filtersData?.contractYears
	const dispatch = useDispatch();
	const handleChange = (selectedOptions: { title: string, value: number }[]) => {
		const values = selectedOptions.map(option => option.value);
		dispatch(updateFilters({ contractYears: values }))
	}

	return (
		<Wrapper width='130px'>
			<SelectCheckboxes
				placeholder="Contract Year"
				portalSelectorId='contracts-page-wrapper'
				onChange={handleChange}
				variant="filter"
				options={contractYears.map((year) => {
					return { label: year.toString(), value: year }
				})
				}
			/>
		</Wrapper>
	);
};

export default ContractYearFilter;