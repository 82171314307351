export const removeUselessCookies = () => {
	// delete amplifyUI cookie
	document.cookie = 'amplify-signin-with-hostedUI=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const removeAllCookies = () => {
	const allCookies = document.cookie.split(';');
	allCookies.forEach((cookie) => {
		document.cookie = `${cookie}=; max-age=-0; path=/;`;
	});
};
