import gql from 'graphql-tag';

export const GET_REPORT_QUERY = gql`
  query report($id: ID!) {
    report(id: $id) {
      id
      createdAt
      deliveryDate
      updatedAt
      name
      cropType
      coverage
      active
      features {
        id
        name
        order
        description
        type
        bbox
        featureDataUrl
        featureData {
          name
          url
        }
      }
      mapConfig {
        latitude
        longitude
        boundaries
        shapeFile {
          id
          url
        }
        zoom
        minZoom
        maxZoom
      }
      metrics {
        id
        name
        value
        unit
        focus
      }
      pdfReportUrl
      geoJsonReportUrl
    }
  }
`;
