import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

export const StyledContractsTable = styled.table`
  ${({ theme }) => css`
    width: 98%;
    text-align: left;
    border-right: 1px solid ${theme.colors.tables.borders};
    /* margin-top: 1%; */
    margin-left: 1%;
    border-collapse: separate;
    &::last-child {
      border-right: none;
    }
    thead {
      position: sticky;
      top: 49px; // Magic number, offsetting the height of the filters above. TODO: find a better solution
      z-index: 1;
      background: white;
      box-shadow: 0px -7px 0px 1px ${theme.colors.black}; // Hack in order to hide the content of the table when scrolling up past thead
    }
    tbody {
      overflow: visible !important;
      height: 200px;
    }
    th,
    td {
      font-size: 14px;
    }
    th {
      padding: ${theme.space.sm};
      border-left: solid 1px ${theme.colors.tables.headerBorders};
      border-right: solid 1px ${theme.colors.tables.headerBorders};
      color: ${theme.colors.greyLight};
      font-weight: bolder;
      /* box-shadow: 1px 1px 1px ${theme.colors.blackLighter}; */
    }
    tr {
      background: ${theme.colors.tables.cell};
      cursor: pointer;
      position: relative;
    }
    td {
      padding: ${theme.space.md} ${theme.space.sm};
      color: white;
      /* border-left: 1px solid ${theme.colors.grey};
		border-right: 1px solid ${theme.colors.grey}; */
      border-bottom: 1px solid ${theme.colors.tables.borders};
    }
  `}
`;

export const StyledRowClose = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    height: 100%;
    width: 30px;
    background: ${theme.colors.brandPrimary};
  `}
`;

export const StyledContractsTableHeader = styled.th<{ variant?: 'active' }>`
  ${({ theme }) => css`
    background: ${theme.colors.blackLighter};
    ${variant({
      variants: {
        active: {
          background: `${theme.colors.tables.selecetedHeader}`,
        },
      },
    })}
  `}
`;

export const StyledContractsTableHeaderContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin: ${theme.space.sm} 0;
    align-items: center;
  `}
`;
