import { gql } from '@apollo/client';

export interface UpdateFlaggedInput {
	projectId: string,
  contractUid:string,
  flagged: boolean
}

export interface UpdateUserFlaggedResponse {
	updateFlag: {
		flagged: boolean
	};
}

export const UPDATE_FLAGGED_MUTATION = gql`
	mutation updateFlag(
		$projectId: ID!, 
		$contractUid: ID!, 
		$flagged:Boolean!) 
		{
			updateFlag(
				projectId:$projectId, 
				contractUid:$contractUid, 
				flagged: $flagged
			)
			{
				flagged
			}
		}
`;
