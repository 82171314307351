import { RGBAColor } from '@deck.gl/core';
import { IconLayer, ScatterplotLayer } from '@deck.gl/layers';
import { PickInfo } from 'deck.gl';
import { parseToRgb } from 'polished';
import { noop } from '../../App.utils';
import { BSContractsData } from '../../graphql/queries/contracts/contracts.types';
import { MapConfig } from '../../graphql/queries/mapConfig/mapConfig.types';
import { colors } from '../../styles/colors';
import { InteractiveMap } from '../InteractiveMap/InteractiveMap';
import { CM_COLOUR_SCALE } from './CMMap.constants';

export class CMMap {
  // private layers: any[];
  private config: MapConfig;
  // private projectId: string;
  private contracts: BSContractsData[] = [];
  private map: InteractiveMap;
  private onReady: () => void = noop;
  private selectedField: BSContractsData | undefined;
  private onClick: (name: string | undefined) => void = noop;

  // private shapeFile: any;
  // private metadata: ARMapMetadata[];
  // private controls: ARMapControls;
  constructor({ canvasRef, mapRef, onClick, onReady, onMapClick, config }: any) {
    this.onReady = onReady;
    this.onClick = onClick;
    // console.log(config);
    this.map = new InteractiveMap({ mapRef, canvasRef, config, showPointer: true, onClick: onMapClick });
    this.map.onMapReady = () => this.init();
  }

  private init() {
    this.onReady();
  }

  private getFieldColor(d: BSContractsData): RGBAColor {
    if (CM_COLOUR_SCALE[d.hsat_yvRiskLevel]) {
      const colour = CM_COLOUR_SCALE[d.hsat_yvRiskLevel];
      //TODO: parse colours only once
      const { red, green, blue } = parseToRgb(colour);
      return [red, green, blue];
    } else {
      return [255, 255, 255];
    }
  }

  private onFieldClick = (d: PickInfo<BSContractsData>) => {
    const fieldId = d?.object.inference_cm_id;
    this.onClick(fieldId);
  };

  public updateContracts(contracts: BSContractsData[]) {
    this.contracts = contracts;
    this.generateLayers();
  }

  public updateSelectedField(field: BSContractsData | undefined) {
    this.selectedField = field;
    this.generateLayers();
  }

  private generateLayers() {
    const layers: any[] = [];
    const color = parseToRgb(colors.brandPrimary);
    const scatterplotLater = new ScatterplotLayer<BSContractsData>({
      data: this.contracts,
      getPosition: (d) => [d.longitude, d.latitude],
      id: 'scatterplot-layer',
      pickable: true,
      stroked: false,
      onClick: this.onFieldClick,
      filled: true,
      getFillColor: (d) => this.getFieldColor(d),
      getRadius: 3,
      radiusUnits: 'pixels',
      //@ts-ignore
      // parameters: {
      //   // prevent flicker from z-fighting
      //   [GL.DEPTH_TEST]: false,
      //   // turn on additive blending to make them look more glowy
      //   [GL.BLEND]: true,
      //   [GL.BLEND_SRC_RGB]: GL.ONE,
      //   [GL.BLEND_DST_RGB]: GL.ONE,
      //   [GL.BLEND_EQUATION]: GL.FUNC_ADD,
      // },
    });
    layers.push(scatterplotLater);

    if (this.selectedField) {
      const layer = new IconLayer({
        id: 'IconLayer',
        data: [this.selectedField],
        getColor: (d) => [color.red, color.green, color.blue],
        getIcon: (d) => 'marker',
        //@ts-ignore
        getPosition: (d) => [d.longitude, d.latitude, 10],
        getSize: (d) => 5,
        //TODO: use local icon
        iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
        iconMapping: {
          marker: {
            x: 0,
            y: 0,
            width: 128,
            height: 128,
            anchorY: 128,
            mask: true,
          },
        },
        sizeScale: 8,
        pickable: true,
      });

      layers.push(layer);
    }

    this.map.setLayers(layers);
  }
}
