import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Loader } from '../../Loader/Loader';
import { Wrapper } from '../../Wrapper/Wrapper';

export const ChartLoader: React.FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ zIndex: 5 }}
        >
          <Wrapper position="absolute" top="50%" left="50%" style={{ zIndex: 5, transform: 'translate(-50%, -50%)' }}>
            <Loader />
          </Wrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
