import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

export const StyledContractsDetails = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    border-top: 1px solid ${theme.colors.tables.borders};
    margin-top: 39px; /* Match the height of the nav in the adjacent component */
  `}
`;
export const StyledDetailSectionTitle = styled.th`
  ${({ theme }) => css`
    background: ${theme.colors.tables.sectionTitle};
    padding: ${theme.space.sm};
  `}
`;

export const StyledDetailTitle = styled.div<{ variant: 'active' | 'inactive' }>`
  ${({ theme }) => css`
    padding: 12px;
    display: flex;
    align-items: center;
    h3 {
      font-size: 14px;
    }
    ${variant({
      variants: {
        active: {
          background: `${theme.colors.tables.rowHighlight}`,
          borderLeft: `2px solid ${theme.colors.brandPrimary}`,
          color: 'white',
        },
        inactive: {
          background: `${theme.colors.tables.cell}`,
          borderLeft: `2px solid ${theme.colors.tables.cell}`,
          color: `${theme.colors.grey}`,
          borderBottom: `1px solid ${theme.colors.tables.borders}`,
        },
      },
    })}
  `}
`;

export const StyledDetailsArrow = styled.h2`
  ${({ theme }) => css`
    font-size: 32px;
    line-height: 18px;
    margin-right: ${theme.space.md};
  `}
`;
export const StyledDetailsPlaceholder = styled.h2`
  font-size: 16px;
`;
export const StyledDetailSectionFlag = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    bottom: 0;
    background: ${theme.colors.black};
    border-top: 1px solid ${theme.colors.tables.borders};
  `}
`;
export const StyledDetailSectionFlagTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.space.sm};
    background: ${theme.colors.tables.sectionTitle};
  `}
`;

export const StyledDetailSectionComment = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.space.md};
    textarea {
      width: 100%;
      background: ${theme.colors.black};
      color: white;
      border: none;
    }
    button,
    input {
      background: none;
      border: none;
      color: ${theme.colors.greyLight};
    }
  `}
`;
export const StyledCommentDisabled = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    cursor: not-allowed;
  `}
`;
export const StyledDetailSectionDetails = styled.table`
  ${({ theme }) => css`
    width: 100%;
    text-align: left;
    tr {
      border-top: 1px solid ${theme.colors.tables.borders};
    }
    th {
      font-size: 12px;
      padding-left: ${theme.space.sm};
      color: ${theme.colors.tables.detailTitle};
    }
    td {
      font-size: 14px;
      width: 70%;
    }
    tr,
    td {
      padding: ${theme.space.md};
    }
  `}
`;
