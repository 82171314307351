import { useLazyQuery, useQuery } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { noop } from '../../../App.utils';
import { CMMap } from '../../../classes/CMMap/CMMap';
import {
  BS_CONTRACTS_MAP_QUERY,
  BS_CONTRACT_DETAILS_QUERY,
  CONTRACTS_MAP_CONFIG,
} from '../../../graphql/queries/contracts/contracts.queries';
import { BSContractFilters } from '../../../graphql/queries/contracts/contracts.types';
import { useCallbackRef } from '../../../hooks/useCallbackRef';
import { updateSelectedContractUid } from '../../../slices/icm/icm';
import { RootState } from '../../../slices/store';
import { AnalysisMapContainer, AnalysisMapLoader } from '../../Analysis/AnalysisMap/AnalysisMap.styles';
import { Loader } from '../../Loader/Loader';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ContractsMapLegend } from './ContractsMapLegend/ContractsMapLegend';

const ContractsMap: React.FC<{ filters: BSContractFilters }> = ({ filters }) => {
  const { state } = useLocation<{ projectId: string }>();
  // Map refs
  const Map = useRef<CMMap>();
  const [mapReady, setMapReady] = useState(false);
  const [mapboxRef, setMapboxRef] = useCallbackRef();
  const [deckglRef, setDeckGlRef] = useCallbackRef();

  const selectedField = useSelector((state: RootState) => state?.icm?.selectedContract);
  const dispatch = useDispatch();

  const { data: mapConfig } = useQuery(CONTRACTS_MAP_CONFIG, { variables: { projectId: state?.projectId } });
  const [getContracts, { data, loading }] = useLazyQuery(BS_CONTRACTS_MAP_QUERY);
  const [getContractDetails, { data: contractData, loading: contractLoading }] =
    useLazyQuery(BS_CONTRACT_DETAILS_QUERY);
  const fetchContracts = async () => {
    await getContracts({
      variables: {
        projectId: state?.projectId,
        skipPagination: true,
        filters,
      },
    });
  };

  useEffect(() => {
    dispatch(updateSelectedContractUid());
  }, [dispatch]); // Reset sidebar details when map is mounted

  useEffect(() => {
    if (Map.current) {
      fetchContracts();
    }
  }, [filters, Map]);

  useEffect(() => {
    if (Map.current && data?.contractManagement) {
      Map.current.updateContracts(data.contractManagement.contracts);
    }
  }, [data]);

  useEffect(() => {
    if (mapboxRef.current && deckglRef.current && !Map.current && mapConfig) {
      Map.current = new CMMap({
        canvasRef: deckglRef.current,
        mapRef: mapboxRef.current,
        config: mapConfig?.contractMapConfig,
        onClick: (fieldId?: string) => {
          getContractDetails({ variables: { contractId: fieldId } });
        },
        onReady: () => {
          setMapReady(true);
          fetchContracts();
          Map?.current?.updateSelectedField(selectedField);
        },
      });
    }
  }, [mapboxRef, deckglRef, Map.current, mapConfig]);

  useEffect(() => {
    dispatch(updateSelectedContractUid(contractData?.contractDetails?.inference_cm_id));
  }, [contractData, dispatch]);

  useEffect(() => {
    if (Map.current && selectedField) {
      Map.current.updateSelectedField(selectedField);
    }
  }, [selectedField]);

  return (
    <Wrapper display="flex" height="100%" onKeyDown={noop}>
      <Wrapper flex="1 1 75%" position="relative">
        {/* Show loader while loads */}
        <AnimatePresence>
          {(!mapReady || loading) && (
            <AnalysisMapLoader exit={{ opacity: 0 }} initial={{ opacity: 1 }}>
              <Loader />
            </AnalysisMapLoader>
          )}
        </AnimatePresence>

        <ContractsMapLegend />

        <AnalysisMapContainer>
          <div style={{ opacity: mapReady ? 1 : 0 }} ref={setMapboxRef}></div>
          <canvas style={{ opacity: mapReady ? 1 : 0 }} ref={setDeckGlRef}></canvas>
        </AnalysisMapContainer>
      </Wrapper>
    </Wrapper>
  );
};

export default ContractsMap;
