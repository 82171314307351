import styled, { css } from 'styled-components';
import { Wrapper } from '../../../Wrapper/Wrapper';

export const StyledAnalysisMapControls = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  z-index: 1;
`;

export const AnalysisControlPanel = styled.span`
  ${({ theme }) => css`
    background: ${theme.colors.blackLight};
    border: 1px solid ${theme.colors.grey};
    display: flex;
    padding: 0;
    pointer-events: all;
  `}
`;

export const AnalysisControlWrapper = styled(Wrapper)<{ hideBorder?: boolean }>`
  ${({ hideBorder, theme }) => css`
    border-left: ${hideBorder ? 'none' : `1px solid ${theme.colors.grey}`};
  `}
`;

export const AnalysisLegendValues = styled(Wrapper)`
  ${({ theme }) => css`
    display: inline-block;
    font-size: 10px;
    ${theme.mediaQueries.xl} {
      font-size: 12px;
    }
  `}
`;
