export const formatCardValue = (value: number): string => {
	// init empty sign
	let sign = '';

	if (value === 0 || value === Infinity || isNaN(value)) {
		return '--';
	}

	if (value >= 0) {
		sign = '+';
	}

	const formattedValue = parseFloat(value.toFixed(2));

	return `${sign}${formattedValue.toLocaleString('en-US')}`;
};

export const formatCurrentValue = (currentValue: number, previousValue: number) => {
	if (currentValue) {
		return `${currentValue.toLocaleString('en-US', { maximumFractionDigits: 2 })}`;
	}
	if (!currentValue && previousValue) {
		return `0`;
	}
	return 'N/A';
};
