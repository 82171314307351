import React from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { updateFilters } from '../../../slices/icm/icm';
import { Select } from '../../Select/Select';
import SelectCheckboxes from '../../SelectCheckboxes/SelectCheckboxes';
import { Wrapper } from '../../Wrapper/Wrapper';

const FlagFilter: React.FC = () => {
	const flags: { label: string, value: boolean }[] = [
		{
			label: "False",
			value: false
		}, {
			label: "True",
			value: true
		}]

	const dispatch = useDispatch();
	const handleChange = (selectedOption: { label: string, value: boolean }) => {
		dispatch(updateFilters({ flag: selectedOption.value }))
	}

	const Option: React.FC<any> = (props) => (
		<div>
			<components.Option {...props}>
				<input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);

	const ValueLabel: React.FC<any> = (props) => {
		const valuesLength = props.selectProps?.values?.length;
		return (
			<>
				<Wrapper display="flex" flexDirection="row">
					<span>{props.data.label},</span>
				</Wrapper>
			</>
		)
	}

	const IndicatorsContainer: React.FC<any> = (props) => (
		null
	);
	const Placeholder: React.FC<any> = (props) => (
		null
	);
	const ValueContainer = (props: any) => (
		<components.ValueContainer {...props}>
			<Wrapper display="flex" flexDirection="column" >
				<h4>Flagged</h4>
				<Wrapper display='flex' flexDirection="row" height={props.hasValue ? 'auto' : 0}>
					{props.children}
				</Wrapper>

			</Wrapper>
		</components.ValueContainer>
	)

	return (
		<Wrapper width='130px'>
			<Select
				portalSelectorId={'contracts-page-wrapper'}
				defaultMenuIsOpen={true}
				closeMenuOnSelect={false}
				variant={"filter"}
				components={{ Option, ValueContainer, IndicatorsContainer, Placeholder }}
				options={flags}
				onChange={handleChange}
			/>
		</Wrapper>
	);
};

export default FlagFilter;