import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportData } from '../../../graphql/queries/report/report.types';
import { RootState } from '../../../slices/store';
import { Wrapper } from '../../Wrapper/Wrapper';
import { CoverageLegend, DotLegend, HealthLegend, StyledReportLegend } from './ReportLegend.styles';

//TODO: improve markup, store feature type in state
export const ReportLegend: React.FC<{ reportData: ReportData }> = ({ reportData }) => {
	const { activeFeature } = useSelector((state: RootState) => state.igs.map);
	const feature = reportData.features.find((feature) => feature.id === activeFeature);

	return (
		<StyledReportLegend>
			<AnimatePresence exitBeforeEnter>
				{feature?.type === 'location' && (
					<motion.div
						initial={{ opacity: 0 }}
						exit={{ opacity: 0, transition: { duration: 0.2 } }}
						animate={{ opacity: 1, transition: { duration: 0.2 } }}
						key={activeFeature}
					>
						<Wrapper as="span" justifyContent="flex-end" display="flex" alignItems="center">
							<Wrapper mr="sm">Sample location</Wrapper>
							<DotLegend />
						</Wrapper>
						<Wrapper as="span" mt="sm" justifyContent="flex-end" display="flex" alignItems="center">
							<Wrapper mr="sm">{reportData.cropType} identified</Wrapper>
							<DotLegend variant="white" />
						</Wrapper>
					</motion.div>
				)}
				{feature?.type === 'coverage' && (
					<motion.div
						initial={{ opacity: 0 }}
						exit={{ opacity: 0, transition: { duration: 0.2 } }}
						animate={{ opacity: 1, transition: { duration: 0.2 } }}
						key={activeFeature}
					>
						<Wrapper display="flex" alignItems="center">
							<Wrapper mr="sm">Provinces sampled</Wrapper> <CoverageLegend />
						</Wrapper>
					</motion.div>
				)}
				{(feature?.type === 'healthIndex' || feature?.type === 'curated:') && (
					<motion.div
						initial={{ opacity: 0 }}
						exit={{ opacity: 0, transition: { duration: 0.2 } }}
						animate={{ opacity: 1, transition: { duration: 0.2 } }}
						key={activeFeature}
					>
						<Wrapper display="flex" justifyContent="flex-end" alignItems="center" height="44px">
							<Wrapper
								display="flex"
								height="100%"
								justifyContent="space-between"
								alignItems="flex-end"
								flexDirection="column"
								mr="sm"
							>
								<Wrapper>High quality</Wrapper>
								<Wrapper>Low quality</Wrapper>
							</Wrapper>
							<HealthLegend>-</HealthLegend>
						</Wrapper>
						<Wrapper as="span" mt="sm" justifyContent="flex-end" display="flex" alignItems="center">
							<Wrapper mr="sm">No data</Wrapper>
							<DotLegend variant="stroked" />
						</Wrapper>
					</motion.div>
				)}
			</AnimatePresence>
		</StyledReportLegend>
	);
};
