import gql from 'graphql-tag';

export const ANALYSIS_HEALTH_QUERY = gql`
  query analysisHealth($analysisId: ID!, $area: String!, $year: Int!) {
    analysisHealth(analysisId: $analysisId, area: $area, year: $year) {
      health
      date
      area
    }
  }
`;
