import { useQuery } from '@apollo/client';
import format from 'date-fns/format';
import { AnimatePresence } from 'framer-motion';
import React, { useMemo } from 'react';
import { ReportData } from '../../../graphql/queries/report/report.types';
import { ME_QUERY } from '../../../graphql/queries/user/me';
import { UserData } from '../../../graphql/queries/user/user.types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { Button } from '../../Button/Button';
import { Loader } from '../../Loader/Loader';
import { Legend } from '../../Typography/Typography';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ReportMetrics } from '../ReportMetrics/ReportMetrics';
import { SUMMARY_VARIANTS } from './ReportSummary.constants';
import {
  MetadataTitle,
  MetadataValue,
  ReportTitle,
  StyledSummary,
  SummaryIntro,
  SummaryWrapper,
} from './ReportSummary.styles';

export const ReportMetadata: React.FC<{ title: string }> = ({ children, title }) => {
  return useMemo(
    () => (
      <Wrapper display="flex" flexDirection="column" mr={{ default: 'md', sm: 'xl' }}>
        <MetadataTitle mb="sm" fontSize={{ default: '10px', sm: '12px', xl: '14px' }}>
          {title}
        </MetadataTitle>
        <MetadataValue fontSize={{ default: '14px', lg: '18px', xl: '24px' }}>{children}</MetadataValue>
      </Wrapper>
    ),
    [title, children],
  );
};

export const ReportSummary: React.FC<{
  closeSummary: () => void;
  onAnimationComplete: () => void;
  mapReady: boolean;
  reportData?: ReportData;
}> = ({ closeSummary, mapReady, onAnimationComplete, reportData }) => {
  const isMounted = useIsMounted();
  const { data: userData } = useQuery<{ me: UserData }>(ME_QUERY, { skip: !isMounted });
  return (
    <StyledSummary p={{ default: 'md', sm: 'lg' }}>
      {reportData && (
        <>
          <Wrapper>
            <ReportTitle mb="md">
              {reportData.name} {!reportData.active && `(draft)`}
            </ReportTitle>
            <SummaryWrapper custom={1} variants={SUMMARY_VARIANTS} initial="hide" animate="show" display="flex" mt="sm">
              <ReportMetadata title="Coverage">{reportData.coverage}</ReportMetadata>
              {reportData.deliveryDate && (
                <ReportMetadata title="Delivery">{format(new Date(reportData.deliveryDate), 'MM/yyyy')}</ReportMetadata>
              )}
              {/* <ReportMetadata title="Survey">-</ReportMetadata> */}
            </SummaryWrapper>
          </Wrapper>
          <SummaryWrapper custom={2} variants={SUMMARY_VARIANTS} initial="hide" animate="show" my={{ sm: 'md' }}>
            <SummaryIntro>Hi {userData?.me.firstName}.</SummaryIntro>
            <SummaryIntro mt="0">We've completed the latest ground survey and here are the highlights:</SummaryIntro>

            <SummaryWrapper
              mt="lg"
              custom={3}
              variants={SUMMARY_VARIANTS}
              initial="hide"
              animate="show"
              onAnimationComplete={onAnimationComplete}
            >
              <ReportMetrics metrics={reportData.metrics} />
            </SummaryWrapper>
          </SummaryWrapper>

          <Wrapper display="flex" alignItems="center" mt="sm">
            <AnimatePresence exitBeforeEnter>
              {!mapReady && (
                <SummaryWrapper
                  height="100%"
                  mr="md"
                  alignItems="center"
                  key="summaryLoading"
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1, transition: { duration: 0.3 } }}
                  exit={{ opacity: 0, transition: { duration: 0.3, delay: 0.3 } }}
                  display="flex"
                >
                  <Loader mr="xs" size={20} />
                  <Legend opacity=".8">Loading the deepdive</Legend>
                </SummaryWrapper>
              )}
              {mapReady && (
                <SummaryWrapper
                  height="100%"
                  mr="xs"
                  key="summaryLoaded"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
                >
                  <Button px="md" variant="light" as="button" onClick={() => closeSummary()}>
                    Enter the deepdive
                  </Button>
                </SummaryWrapper>
              )}
            </AnimatePresence>
            {/* {reportData.geoJsonReport?.url && (
							<Button
								download
								mr="xs"
								as="a"
								display={{ default: 'none', sm: 'flex' }}
								href={reportData.geoJsonReport?.url}
								ext="GeoJSON"
							>
								Data
							</Button>
						)} */}
            {reportData?.pdfReportUrl && (
              <Button target="_blank" download as="a" href={reportData?.pdfReportUrl} ext="PDF">
                Report
              </Button>
            )}
          </Wrapper>
        </>
      )}
    </StyledSummary>
  );
};
