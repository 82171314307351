import React from 'react';
import { Navbar } from '../Navbar/Navbar';
import { Sidebar } from '../Sidebar/Sidebar';
import { LayoutMain, LayoutWrapper, StyledLayout } from './Layout.styles';

export const Layout: React.FC = ({ children }) => {
	return (
		<StyledLayout>
			<Sidebar />
			<LayoutWrapper display="flex" flexDirection="column" width="100%" flex="1" overflow="auto">
				<Navbar />
				<LayoutMain>{children}</LayoutMain>
			</LayoutWrapper>
		</StyledLayout>
	);
};
