import { AnalysisLevel3 } from '../../../graphql/queries/analysis/analysis.types';

export const formatLevel2Filters = (level2Data: any[]): string[] => {
	const keys = level2Data.reduce((acc, data) => {
		Object.keys(data).forEach((key) => {
			if (!acc.includes(key) && key !== 'Year') {
				acc.push(key);
			}
		});

		return acc;
	}, [] as string[]);

	return keys.sort((a: string, b: string) => a.localeCompare(b)) || [];
};

export const formatLevel3Filters = (level3Data: AnalysisLevel3[]): string[] => {
	return level3Data.reduce((acc, row) => {
		row.data.map((d) => {
			if (!acc.includes(d['Province Name'])) {
				acc.push(d['Province Name']);
			}
		});
		return acc;
	}, [] as string[]);
};
