import styled, { css } from 'styled-components';

export const CheckboxLabel = styled.label`
	${({ theme }) => css`
		box-sizing: border-box;
		cursor: pointer;
		border: 1px solid ${theme.colors.grey};
		font-size: 0;
		text-indent: -9999px;
		width: 24px;
		height: 10px;
		background: ${theme.colors.black};
		display: block;
		border-radius: 100px;
		position: relative;
		transition: all ${theme.transitions.default};
		&:after {
			content: '';
			position: absolute;
			top: -2px;
			left: -2px;
			width: 12px;
			height: 12px;
			background: #fff;
			border-radius: 90px;
			transition: all ${theme.transitions.default};
		}
		&:active:after {
			width: 10px;
		}
	`}
`;

export const StyledCheckbox = styled.input`
	${({ theme }) => css`
		height: 0;
		width: 0;
		visibility: hidden;
		&:checked + ${CheckboxLabel} {
			background: ${theme.colors.brandPrimary};
		}
		&:checked + ${CheckboxLabel}:after {
			left: calc(100% + 2px);
			transform: translateX(-100%);
		}
	`}
`;
