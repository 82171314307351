import React from 'react';

export const AnalysisMapAbsoluteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 80 12">
      <defs>
        <linearGradient id="linear-gradient" x2="0.982" y1="0.5" y2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#8e81ff"></stop>
          <stop offset="1" stopColor="#595c67"></stop>
        </linearGradient>
        <clipPath id="clip-Absolute">
          <path d="M0 0H80V12H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Absolute)">
        <path fill="transparent" d="M0 0H80V12H0z"></path>
        <g data-name="Group 172038" transform="rotate(90 869.75 -480.25)">
          <rect
            width="80"
            height="12"
            fill="url(#linear-gradient)"
            data-name="Rectangle 201757"
            rx="6"
            transform="rotate(90 526.25 835.75)"
          ></rect>
        </g>
      </g>
    </svg>
  );
};
