import { SelectOption } from '../../../Select/Select.types';
import { TerracottaDataset } from '../AnalysisMap.types';
import { ANALYSIS_MAP_FREQUENCY_OPTIONS, ARMapFrequency } from './AnalysisMapControls.constants';

export const getTiffTypes = (tiffData: TerracottaDataset[]): SelectOption[] => {
  const types = tiffData.reduce((acc, tiff) => {
    if (!acc.includes(tiff.type)) {
      acc.push(tiff.type);
    }

    return acc;
  }, [] as string[]);

  return types.map((type) => {
    return {
      label: type,
      value: type,
    };
  });
};

const getMonth = (month: string) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthIndex = parseInt(month);
  if (!isNaN(monthIndex)) {
    return months[monthIndex - 1];
  }

  return month;
};
export const getFilteredTiffs = (
  tiffs: TerracottaDataset[],
  frequency: ARMapFrequency,
  type?: string,
): SelectOption[] => {
  if (!type) {
    return [];
  }

  const tiffsByFrequency =
    frequency === 'monthly' ? tiffs.filter((t) => t.month !== '00') : tiffs.filter((t) => t.month === '00');
  const tiffsByType = tiffsByFrequency.filter((tiff) => tiff.type === type);

  return tiffsByType.map((tiff) => ({
    label: tiff.month !== '00' ? `${getMonth(tiff.month)} ${tiff.year}` : tiff.year,
    value: tiff.id,
  }));
};

export const formatFrequencyOptions = (tiffs: TerracottaDataset[], type?: string): SelectOption<ARMapFrequency>[] => {
  if (!type) {
    return ANALYSIS_MAP_FREQUENCY_OPTIONS;
  }

  const monthlyCount = tiffs.filter((t) => t.month !== '00' && t.type === type).length;
  const yearlyCount = tiffs.filter((t) => t.month === '00' && t.type === type).length;
  return [
    { label: `Yearly (${yearlyCount})`, value: 'yearly' },
    { label: `Monthly (${monthlyCount})`, value: 'monthly' },
  ];
};
