import curriedTransparentize from 'polished/lib/color/transparentize';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ResetPassword } from '../../../components/ResetPassword/ResetPassword';
import { SEO } from '../../../components/SEO/SEO';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { LoginCredits, LoginLogo } from '../LoginPage.styles';

export const ForgotPasswordPage = () => {
	const { colors } = useContext(ThemeContext);

	return (
		<Wrapper height="100vh" background={colors.loginBg} display="flex">
			<SEO title="Reset password" />
			<Wrapper
				alignItems="center"
				display="flex"
				position={{ default: 'absolute', md: 'relative' }}
				height="100%"
				justifyContent="center"
				zIndex={1}
				px={{ default: 'md', sm: 'xxl', md: 'md' }}
				width={{ default: '100vw', md: '50vw' }}
			>
				<Wrapper display="flex" flexDirection="column" width={{ default: '100%', md: '50%' }}>
					<ResetPassword />
				</Wrapper>
			</Wrapper>
			<Wrapper
				width={{ default: '100vw', md: '50vw' }}
				backgroundSize="cover"
				backgroundImage="url('./mekong_delta.jpg')"
			>
				<Wrapper
					alignItems="center"
					position="relative"
					background={{
						default: curriedTransparentize(0.15, colors.loginBg),
						md: curriedTransparentize(0.3, colors.loginBg),
					}}
					display="flex"
					height="100%"
					justifyContent="center"
				>
					<LoginLogo display={{ default: 'none', md: 'initial' }} src="./inference.png" />
					<LoginCredits>
						© ESA,
						<a href="https://creativecommons.org/licenses/by-sa/3.0/igo/" target="_blank" rel="noreferrer">
							CC BY-SA 3.0 IGO
						</a>
					</LoginCredits>
				</Wrapper>
			</Wrapper>
		</Wrapper>
	);
};
