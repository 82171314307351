import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

export const StyledContractsPage = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    width: 100%;
    .select__menu {
      margin-top: 0;
    }
    /* .select__control {
		min-height: 39px
	}; */
    /* .select__control--is-disabled .select__single-value, .select__placeholder {
		color: ${theme.colors.grey};
	}; */
    .select__option,
    .select__option label {
      color: white;
      background: ${theme.colors.blackLight};
    }
    .select__option {
      font-size: 12px;
      display: flex;
      align-items: center;
      label {
        margin-left: ${theme.space.sm};
      }
    }

    .select__indicator {
      svg {
        fill: white;
      }
    }
    .select__option:hover {
      cursor: 'pointer';
      background: ${theme.colors.tables.filterOptionHover};
      label {
        background: ${theme.colors.tables.filterOptionHover};
      }
    }
    .select__menu-list {
      border: 1px solid ${theme.colors.tables.borders};
      padding: 0;
    }
    /* .select__value-container{
		padding: ${theme.space.xs} ${theme.space.sm},
		h4: {
			color: ${theme.colors.greyLight}
		}
	}; */
    /* .select__multi-value, .select__single-value {
		font-size: 0.6rem;
		color: ${theme.colors.brandPrimary};
		background: none;
		span {
			color: white;
		}
	}; */
    .input-range-selector {
      color: ${theme.colors.black};
    }
    ${variant({
      variants: {
        filters: {
          '.select__single-value': {
            color: `${theme.colors.blackLight}`,
          },
        },
      },
    })}
  `}
`;

export const StyledContractsDetailsWrapper = styled.div`
  ${({ theme }) => css`
    width: 30%;
    max-height: 100%;
    display: flex;
    border: 1px solid ${theme.colors.tables.borders};
  `}
`;
