import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Level3Data } from '../../../graphql/queries/analysis/analysis.types';
import { updateMetadataFilter } from '../../../slices/iar/iar.slice';
import { RootState } from '../../../slices/store';
import { Wrapper } from '../../Wrapper/Wrapper';
import { AnalysisMetadata } from '../DashboardMetadata/DashboardMetadata.types';
import {
	DashboardCardDiff,
	DashboardCardHeader,
	DashboardCardMenu,
	DashboardCardValue,
	StyledDashboardCard,
} from './DashboardCard.styles';
import { formatCardValue, formatCurrentValue } from './DashboardCard.utils';

export const DashboardCard: React.FC<{
	metadata: AnalysisMetadata;
	factor?: number;
	title: string;
	unit?: string;
	type?: keyof Level3Data;
	useAsFilter?: boolean;
	selectedYear: number;
	hideChange?: boolean;
}> = ({ title, metadata, hideChange = false, factor = 1, unit = '', type, useAsFilter, selectedYear }) => {
	const dispatch = useDispatch();
	const { metadataFilter } = useSelector((state: RootState) => state.iar);
	const currentValue = metadata.currentValue * factor;
	const previousValue = metadata.previousValue * factor;

	const onClick = () => {
		if (useAsFilter) {
			dispatch(updateMetadataFilter(type));
		}
	};

	return (
		<StyledDashboardCard
			active={useAsFilter ? type === metadataFilter : false}
			variant={useAsFilter ? 'filter' : undefined}
			onClick={onClick}
		>
			<DashboardCardHeader active={useAsFilter ? type === metadataFilter : false}>
				{title} {unit}
			</DashboardCardHeader>
			<DashboardCardMenu>
				<Wrapper as="span" mr="md">
					Data source: <strong>HSAT</strong>
				</Wrapper>
				{selectedYear && (
					<span>
						<strong>{selectedYear}</strong> compared to <strong>{selectedYear - 1}</strong>
					</span>
				)}
			</DashboardCardMenu>
			<Wrapper p="md">
				<DashboardCardValue as="strong">{formatCurrentValue(currentValue, previousValue)}</DashboardCardValue>
				<Wrapper display="flex" mt="sm" flexWrap="wrap">
					{!hideChange && (
						<DashboardCardDiff as="strong" mr="md" variant={metadata.change < 0 ? 'red' : undefined}>
							{formatCardValue(metadata.change)}%
						</DashboardCardDiff>
					)}
					<DashboardCardDiff variant={metadata.change < 0 ? 'red' : undefined}>
						{formatCardValue(currentValue - previousValue)}
					</DashboardCardDiff>
				</Wrapper>
			</Wrapper>
		</StyledDashboardCard>
	);
};
