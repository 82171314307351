import styled, { css } from 'styled-components';
import { Wrapper } from '../Wrapper/Wrapper';

export const LayoutWrapper = styled(Wrapper)`
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const LayoutMain = styled.main`
	${({ theme }) => css`
		background: ${theme.colors.black};
		margin-top: -1px;
		flex: 1 1 calc(100vh - ${theme.navbar.size});
		overflow: scroll;
		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	`}
`;

export const StyledLayout = styled.div`
	display: flex;
	height: 100vh;
	width: 100vw;
`;
