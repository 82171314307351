import styled, { css } from 'styled-components';

export const StyledDashboardMetadata = styled.div`
  ${({ theme }) => css`
    /* background: ${theme.colors.blackLight}; */
    /* flex: 1 1 20%; */
    border: 1px solid ${theme.colors.grey};
    border-bottom: 0;
    overflow: auto;
    border-right: 0;
    /* width: 20%; */
  `}
`;
