import styled, { css, keyframes } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export const electron = keyframes`
  100% { transform: translate(30px, -5px); }
`;

export const neutron = keyframes`
  50%  { opacity: 0 }
  51%  { opacity: 1 }
  99%  {opacity: 1}
  100% { opacity: 0 }
`;

export const StyledLoader = styled.div<SpaceProps & { scale: number }>`
	${({ scale, theme }) => css`
		background-color: ${theme.colors.buttonLight};
		border-radius: 50%;
		height: 32px;
		position: relative;
		transform: scale(${scale});
		width: 32px;
		&:before {
			position: absolute;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: white;
			transform: translate(-10px, 30px);
			animation: ${electron} 0.5s infinite alternate ease;
			content: '';
		}
		&:after {
			position: absolute;
			display: block;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: ${theme.colors.buttonLight};
			//box-shadow: inset 0 0 0 6px #1abc9c, inset 0 0 0 10px white;
			opacity: 0;
			animation: ${neutron} 1s infinite;
			content: '';
		}
		${space}
	`}
`;
