import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Auth } from 'aws-amplify';
import { removeAllCookies } from '../components/Login/SignIn/SignIn.utils';

const errorLink = onError(({ graphQLErrors }) => {
	if (!graphQLErrors) {
		return;
	}

	graphQLErrors.forEach(({ extensions }) => {
		if (extensions?.code === 'UNAUTHENTICATED') {
			Auth.signOut({ global: true }).finally(() => {
				removeAllCookies();
				localStorage.clear();
				window.location.replace('/login');
			});
		}
	});
});

const authLink = setContext(async () => {
	try {
		const session = await Auth.currentSession();

		return {
			headers: {
				//@ts-ignore
				Authorization: session.idToken.jwtToken,
			},
		};
	} catch (e) {
		// No current user
		return {};
	}
});

const fileUploadLink = createUploadLink({
	uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const cache = new InMemoryCache({
	typePolicies: {},
});
const link = ApolloLink.from([errorLink, authLink, fileUploadLink]);

export const client = new ApolloClient({
	cache,
	link,
	connectToDevTools: true,
});
