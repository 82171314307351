import { combineReducers, configureStore } from '@reduxjs/toolkit';
//TODO: add .slice to files
import auth from './auth/auth';
import iar from './iar/iar.slice';
import igs from './igs/igs';
import icm from './icm/icm';
import nav from './nav/nav';

// all reducers should be imported here
const reducers = combineReducers({
	auth,
	igs,
	icm,
	nav,
	iar,
});

export const rootReducer = reducers;
export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
	reducer: rootReducer,
});
export type AppDispatch = typeof store.dispatch;
