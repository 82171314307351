import gql from 'graphql-tag';

export const ANALYSIS_QUERY = gql`
  query analysis($id: ID!) {
    analysis(id: $id) {
      id
      coverage
      cropType
      level2
      level3 {
        id
        data
        date
      }
      charts {
        health
        yield
        ndvi
        price
      }
      mapConfig {
        boundaries
        shapeFileUrl
        latitude
        longitude
        zoom
        minZoom
        maxZoom
      }
      project {
        id
      }
    }
  }
`;
