import styled, { css } from 'styled-components';
import { LayoutProps, variant, width } from 'styled-system';

export const StyledSelect = styled.div<{ variant?: 'transparent' | 'filter' | 'tableFooter' } & LayoutProps>`
  ${({ theme }) => css`
    > * {
      font-size: 12px;
    }
    .select__control--is-disabled .select__single-value {
      color: ${theme.colors.blackLight};
    }
    .select__control {
      background: ${theme.colors.brandSecondary};
      border: 0;
      border-radius: 0;
      cursor: pointer;
      min-height: 32px;
      &:focus,
      &:hover,
      &:active {
        outline: 0;
        border: 0;
        box-shadow: none;
      }
    }
    .select__control--is-focused {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
    .select__value-container {
      padding: 0 ${theme.space.sm};
      overflow: visible;
    }
    .select__single-value {
      color: white;
      margin: 0;
      padding: 0 ${theme.space.sm};
      line-height: 2;
      /* overflow: visible; */
    }
    .select__indicator {
      padding: ${theme.space.sm};
      svg {
        fill: white;
        height: 8px;
        width: 8px;
      }
    }
    .select__menu {
      box-shadow: none;
      margin: 0;
      background: none;
      border-radius: 0;
    }
    .select__menu-list {
      border: 2px solid ${theme.colors.brandSecondary};
      padding: 0;
    }
    .select__option {
      background: ${theme.colors.black};
      &:hover {
        cursor: pointer;
        background: ${theme.colors.brandSecondary};
      }
    }

    ${variant({
      variants: {
        transparent: {
          '.select__control': {
            background: 'transparent',
          },
          '.select__menu-list': {
            border: `1px solid ${theme.colors.blackLight}`,
            margin: '0 -1px',
          },
        },
        filter: {
          '.select__control': {
            minHeight: '39px',
          },
          '.select__control--is-disabled .select__single-value, .select__placeholder': {
            // color: `${theme.colors.blackLight}`
            color: 'white',
          },
          '.select__control, .select__option, .select__option label': {
            color: `white`,
            background: `${theme.colors.brandPrimary}`,
          },
          '.select__single-value': {
            color: 'white',
            background: `${theme.colors.brandPrimary}`,
            padding: 0,
            margin: '2px§',
            top: 0,
            transform: 'none',
            position: 'relative',
            lineHeight: 1,
          },
          '.select__indicator': {
            svg: {
              fill: `white`,
            },
          },
          '.select__option: hover': {
            cursor: 'pointer',
            // background: `${theme.colors.tables.filterOptionHover}`,
            '.select__option label': {},
          },
          '.select__menu-list': {
            borderWidth: '1px',
          },
          '.select__value-container': {
            padding: `${theme.space.xs} ${theme.space.sm}`,
            maxWidth: '130px',
            width: '130px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxHeight: '39px',
            h4: {
              fontWeight: 'bolder',
              color: `${theme.colors.tables.filterLabelColor}`,
            },
            p: {
              color: 'white',
            },
          },
          '.select__multi-value': {
            fontSize: '0.6rem',
            color: 'white',
            background: `${theme.colors.brandPrimary}`,
            flex: 'none',
            span: {
              color: 'white',
            },
          },
          '.input-range-selector': {
            color: `${theme.colors.black}`,
          },
        },
        tableFooter: {
          '.select__control': {
            height: `${theme.space.lg}`,
            background: `${theme.colors.black}`,
          },
          '.select__single-value': {
            padding: 0,
          },
          '.select__value-container': {
            padding: 0,
            width: '22px',
            display: 'flex',
            overflow: 'visible',
            justifyContent: 'center',
          },
        },
      },
    })}
  `}
  ${width}
`;
