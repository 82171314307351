import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export const StyledDatePicker = styled.div<LayoutProps>`
  ${({ theme }) => css`
    .react-datepicker {
      border: 1px solid black;
      border-radius: 0;
    }
    .react-datepicker__header {
      padding: ${theme.space.sm};
    }
    .react-datepicker__input-container {
      input {
        border: 1px solid ${theme.colors.tables.borders};
        color: white;
        font-size: 9px;
        padding: ${theme.space.sm};
        width: 100%;
        &:focus {
          border-color: ${theme.colors.brandPrimary};
          outline: 1px solid ${theme.colors.brandPrimary};
        }
      }
    }
    ${layout}
  `}
`;
