import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { color, compose, opacity, OpacityProps, space, SpaceProps } from 'styled-system';

export const StyledReportSidebarItem = styled(motion.li)`
  ${({ theme }) => css`
    list-style: none;
    padding: 6px 0;
  `}
`;

export const ReportSidebarItemTitle = styled(motion.h2)<OpacityProps & SpaceProps>`
  ${({ theme }) => css`
    color: white;
    cursor: pointer;
    display: inline-block;
    pointer-events: all;
    font-size: 14px;
    font-weight: bold;
    //TODO: add pseudo elements so we can control the color and position
    text-decoration: underline;
    line-height: 18px;
    text-transform: capitalize;
    ${theme.mediaQueries.lg} {
      font-size: 18px;
      line-height: 23px;
    }
    &:hover {
      color: white;
    }
    ${compose(color, opacity, space)}
  `}
`;

export const ReportSidebarItemContent = styled(motion.p)<SpaceProps>`
  ${({ theme }) => css`
    font-weight: 300;
    color: white;
    font-size: 14px;
    line-height: 20px;

    ${theme.mediaQueries.xl} {
      font-size: 18px;
      line-height: 23px;
    }

    strong {
      color: ${theme.colors.brandPrimary};
    }

    ${space}
  `}
`;
