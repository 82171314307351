import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { AnalysisHistoricalData } from '../../../slices/iar/iar.slice';

export const formatHistoricalData = (analysisData?: AnalysisData) => {
  if (!analysisData) {
    return {};
  }

  const historicalData: AnalysisHistoricalData = {};
  analysisData.level2?.forEach((level2) => {
    const year = level2['Year'] as number;
    if (!year) {
      return;
    }

    if (!historicalData[year]) {
      historicalData[year] = [];
    }

    for (const [key, value] of Object.entries(level2)) {
      if (key !== 'Year') {
        historicalData[year].push({ name: key, type: 'level2', value: value as any });
      }
    }
  });

  analysisData.level3?.forEach((level3) => {
    const year = new Date(level3.date).getFullYear();

    if (!historicalData[year]) {
      historicalData[year] = [];
    }

    level3.data.forEach((row) => {
      historicalData[year].push({ name: row['Province Name'], value: row['Specific Crop Area'], type: 'level3' });
    });
  });

  return historicalData;
};
