import React from 'react';
import { ReportData } from '../../../graphql/queries/report/report.types';
import { StyledReportSidebar } from './ReportSidebar.styles';
import { ReportSidebarFooter } from './ReportSidebarFooter/ReportSidebarFooter';
import { ReportSidebarHeader } from './ReportSidebarHeader/ReportSidebarHeader';
import { ReportSidebarMenu } from './ReportSidebarMenu/ReportSidebarMenu';

export const ReportSidebar: React.FC<{ reportData: ReportData }> = ({ reportData }) => {
	return (
		<StyledReportSidebar p="lg">
			<ReportSidebarHeader reportData={reportData} />
			<ReportSidebarMenu features={reportData.features} />
			<ReportSidebarFooter reportData={reportData} />
		</StyledReportSidebar>
	);
};
