import React from 'react';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../slices/icm/icm';
import SelectCheckboxes from '../../SelectCheckboxes/SelectCheckboxes';
import { Wrapper } from '../../Wrapper/Wrapper';

const YvRiskFilter: React.FC<{ filtersData: { yvLabels: string[] }, fieldLabel: string }> = ({ filtersData, fieldLabel }) => {
	const yvRisk: string[] = filtersData?.yvLabels
	const dispatch = useDispatch();
	const handleChange = (selectedOptions: { title: string, value: string }[]) => {
		const values = selectedOptions?.map(option => option?.value);
		dispatch(updateFilters({ yvRisk: values }))
	}

	return (
		<Wrapper width='130px'>
			<SelectCheckboxes
				placeholder="YV Risk"
				fieldLabel={fieldLabel}
				portalSelectorId='contracts-page-wrapper'
				onChange={handleChange}
				variant="filter"
				options={yvRisk.map((riskLevel) => {
					return { label: riskLevel, value: riskLevel }
				})
				}
			/>
		</Wrapper>
	);
};

export default YvRiskFilter;