import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroundSurveyState } from '../../components/Report/GroundSurvey.types';

const initialState: GroundSurveyState = {
	report: undefined,
	map: { activeFeature: undefined, activeProvince: undefined, layersReady: false },
	summary: { hidden: false, loaded: false },
};
export const igsSlice = createSlice({
	name: 'igs',
	initialState,
	reducers: {
		toggleFeature(state, action: PayloadAction<string | undefined>) {
			state.map.activeFeature = action.payload;
		},
		updateActiveProvince(state, action: PayloadAction<string | undefined>) {
			state.map.activeProvince = action.payload;
		},
	},
});
export const { toggleFeature, updateActiveProvince } = igsSlice.actions;
export default igsSlice.reducer;
