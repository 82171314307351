import { useQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { noop } from 'lodash';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ME_QUERY } from '../../graphql/queries/user/me';
import { UserData } from '../../graphql/queries/user/user.types';
import { useIsMounted } from '../../hooks/useIsMounted';
import { AuthState, updateAuthStatus } from '../../slices/auth/auth';
import { RootState } from '../../slices/store';
import { Wrapper } from '../Wrapper/Wrapper';
import { MenuWrapper, NavbarMenu, NavbarMenuItem, NavbarTitle, StyledNavbar } from './Navbar.styles';
import { getInitials } from './Navbar.utils';

export const StyledAvatar = styled.span`
	${({ theme }) => css`
		align-items: center;
		cursor: pointer;
		border-left: 1px solid ${theme.colors.grey};
		display: flex;
		height: 100%;
		font-weight: bold;
		justify-content: center;
		min-width: 49px;
		padding: 0 ${theme.space.md};
	`}
`;

export const Avatar: React.FC<{ onClick?: () => void }> = ({ onClick = noop }) => {
	const isMounted = useIsMounted();
	const { data: userData } = useQuery<{ me: UserData }>(ME_QUERY, {
		skip: !isMounted,
		fetchPolicy: 'cache-and-network',
	});
	const initials = getInitials(userData?.me);
	return <StyledAvatar onClick={onClick}>{initials}</StyledAvatar>;
};

export const Navbar = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { title } = useSelector((state: RootState) => state.nav);
	const [showMenu, setShowMenu] = useState(false);

	const signOut = async () => {
		await Auth.signOut();
		ReactGA.event({ category: 'User', action: 'Logged out' });
		dispatch(updateAuthStatus(AuthState.SIGNED_OUT));
		history.push('/login');
	};

	return (
		<StyledNavbar>
			<Wrapper display="flex" width="100%" height="100%" alignItems="center" justifyContent="space-between">
				<NavbarTitle px="md">
					Inference <strong>{title}</strong>
				</NavbarTitle>
				<MenuWrapper position="relative" height="100%" tabIndex={0} onBlur={() => setShowMenu(false)}>
					<Avatar onClick={() => setShowMenu(!showMenu)} />
					{showMenu && (
						<NavbarMenu>
							<NavbarMenuItem onClick={signOut}>Sign out</NavbarMenuItem>
						</NavbarMenu>
					)}
				</MenuWrapper>
			</Wrapper>
		</StyledNavbar>
	);
};
