import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ThemeContext } from 'styled-components';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { ANALYSIS_YIELD_QUERY } from '../../../graphql/queries/analysisYield/analysisYield.queries';
import { AnalysisYieldData, AnalysisYieldInput } from '../../../graphql/queries/analysisYield/analysisYield.types';
import { RootState } from '../../../slices/store';
import { Theme } from '../../../styles/theme';
import { Wrapper } from '../../Wrapper/Wrapper';
import { ChartContainer, ChartLegend, ChartLegendItem } from '../Chart.styles';
import { BarIcon } from '../ChartIcons/ChartIcons';
import { ChartLoader } from '../ChartLoader/ChartLoader';
import { ChartNoData } from '../ChartNoData/ChartNoData';

export const YieldChart: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  const { colors } = useContext<Theme>(ThemeContext);
  const [chartData, setChartData] = useState<AnalysisYieldData[]>([]);

  // get active filter from redux
  const activeFilter = useSelector((state: RootState) => state.iar.activeFilter);
  const [getYieldData, { loading }] = useLazyQuery<{ analysisYield: AnalysisYieldData[] }, AnalysisYieldInput>(
    ANALYSIS_YIELD_QUERY,
    {
      onCompleted: (d) => setChartData(d.analysisYield),
    },
  );

  useEffect(() => {
    getYieldData({
      variables: {
        analysisId: analysisData.id,
        area: activeFilter?.label || analysisData.coverage,
      },
    });
  }, [activeFilter?.label]);

  return (
    <Wrapper display="flex" flexDirection="column" flex="1" overflow="hidden">
      <ChartLegend alignItems="center" justifyContent="space-between">
        <Wrapper py="sm" display="flex" alignItems="center">
          <ChartLegendItem mr="md">
            <BarIcon color={colors.greyLight} height="13px" width="13px" mr="xs" />
            {chartData?.length ? chartData[0].yieldSource : 'Third Party'}
          </ChartLegendItem>
          <ChartLegendItem mr="md">
            <BarIcon color={colors.brandPrimary} height="13px" width="13px" mr="xs" />
            HSAT (Yield Index)
          </ChartLegendItem>
        </Wrapper>
      </ChartLegend>
      <ChartContainer>
        <>
          {!chartData.length && !loading && <ChartNoData showTicks={false} />}

          {!!chartData?.length && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart barGap={0} width={600} height={300} margin={{ right: -10 }} data={chartData}>
                <CartesianGrid vertical={false} stroke={colors.charts.grid} />
                <XAxis
                  dataKey="year"
                  padding={{ left: 10, right: 10 }}
                  tick={{ fontSize: 12, fill: 'white' }}
                  stroke={colors.charts.grid}
                  tickLine={{ stroke: 'white' }}
                />
                <YAxis
                  padding={{ top: 20 }}
                  tick={{ fontSize: 12, fill: 'white', transform: 'translate(-3, 0)' }}
                  stroke={colors.charts.grid}
                  tickLine={{ stroke: 'white' }}
                  dataKey="yield"
                  orientation="left"
                  yAxisId="0"
                />
                <YAxis
                  padding={{ top: 20 }}
                  tick={{ fontSize: 12, fill: colors.brandPrimary, fontWeight: 'bold' }}
                  stroke={colors.charts.grid}
                  tickLine={{ stroke: colors.brandPrimary }}
                  dataKey="yieldIndex"
                  orientation="right"
                  yAxisId="1"
                />
                <Bar dataKey="yield" name="Yield" unit=" t/ha" fill={colors.greyLight} />
                <Bar dataKey="yieldIndex" name="Yield Index" unit="/100" fill={colors.brandPrimary} />

                <Tooltip
                  isAnimationActive={false}
                  separator=": "
                  cursor={{ fill: 'rgba(255, 255, 255, .1)' }}
                  labelStyle={{ display: 'none' }}
                  filterNull
                  contentStyle={{
                    background: colors.black,
                    border: `1px solid ${colors.grey}`,
                    strokeWidth: 1,
                    fontSize: 14,
                    marginBottom: 1,
                    marginTop: 1,
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          <ChartLoader loading={loading} />
        </>
      </ChartContainer>
    </Wrapper>
  );
};
