export enum CONTRACTS_FILTER_TYPES {
	ContractYears = 'contractYears',
	ContractNumber = 'contractNumber',
	FieldId = 'fieldId',
	Factory = 'factory',
	FieldArea = 'fieldArea',
	CropHealth = 'cropHealth',
	YvRisk = 'yvRisk',
	Harvested = 'harvested',
	Flag = 'flag',
}
