import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONTRACTS_FILTER_TYPES } from '../../components/Contracts/Contracts.constants';
import {
  BSContractsData,
  ContractsManagementState,
  BSContractFilters,
} from '../../graphql/queries/contracts/contracts.types';

const initialState: ContractsManagementState = {
  selectedContract: undefined,
  selectedContractUid: undefined,
  sortedBy: undefined,
  pageLimit: 25,
  currentPage: 1,
  filters: {},
};

export const icmSlice = createSlice({
  name: 'icm',
  initialState,
  reducers: {
    updateSelectedContract(state, action: PayloadAction<BSContractsData | undefined>) {
      state.selectedContract = action?.payload;
    },
    updateSelectedContractUid(state, action: PayloadAction<string | undefined>) {
      if (!action?.payload) {
        state.selectedContractUid = undefined;
      } else {
        state.selectedContractUid = action?.payload;
      }
    },
    updatePageLimit(state, action: PayloadAction<number>) {
      state.pageLimit = action.payload;
    },
    updateSortedBy(state, action: PayloadAction<CONTRACTS_FILTER_TYPES | undefined>) {
      state.currentPage = 1;
      state.sortedBy = action.payload
        ? {
            filter: action.payload,
            order: state.sortedBy?.order === 'asc' ? 'desc' : 'asc',
          }
        : undefined;
    },
    updateCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    updateFilters(state, action: PayloadAction<BSContractFilters>) {
      state.currentPage = 1; // Reset results page to first when filters are updated to avoid being stuck on a non-existent page
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const {
  updateSelectedContract,
  updateSelectedContractUid,
  updatePageLimit,
  updateCurrentPage,
  updateSortedBy,
  updateFilters,
} = icmSlice.actions;
export default icmSlice.reducer;
