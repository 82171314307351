import React from 'react';
import { AnalysisData } from '../../../graphql/queries/analysis/analysis.types';
import { Chart } from '../../Chart/Chart';
import { CHART_TYPES } from '../../Chart/Chart.constants';
import { StyledDashboardCharts } from './DashboardCharts.styles';

export const DashboardCharts: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  return (
    <StyledDashboardCharts>
      <Chart analysisData={analysisData} defaultChart={CHART_TYPES.Area} />
      <Chart analysisData={analysisData} defaultChart={CHART_TYPES.Precipitation} />
    </StyledDashboardCharts>
  );
};
