import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const MapContainer = styled(motion.div)`
	box-shadow: 5px 5px 15px #0000003b;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	> * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.mapboxgl-canvas-container {
		height: 100%;
	}
	#deckgl-overlay {
		/* mix-blend-mode: lighten; */
	}
`;

export const StyledMap = styled.div`
	${({ theme }) => css`
		background-color: ${theme.colors.black};
		height: calc(100vh - ${theme.navbar.size});
		position: relative;
		width: 100%;
	`}
`;
