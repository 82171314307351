import React from 'react';
import ReactSelect from 'react-select';
import { StyledSelect } from './Select.styles';
import { SelectProps } from './Select.types';

export const Select: React.FC<SelectProps> = ({
  disabled = false,
  onChange,
  maxHeight = undefined,
  placeholder,
  options,
  value,
  variant,
  width,
  components = { IndicatorSeparator: null },
  closeMenuOnSelect = true,
  isMulti,
  hideSelectedOptions,
  backspaceRemovesValue,
  portalSelectorId,
  defaultMenuIsOpen = false
}) => {
  return (
    <>
      <StyledSelect width={width} variant={variant}>
        <ReactSelect
          // menuIsOpen={true}
          menuPortalTarget={portalSelectorId ? document.getElementById(portalSelectorId) : null} // TODO: Decide if portalling selects
          menuShouldBlockScroll={true}
          defaultMenuIsOpen={defaultMenuIsOpen}
          placeholder={placeholder}
          maxMenuHeight={maxHeight}
          isDisabled={disabled}
          components={components}
          classNamePrefix="select"
          options={options}
          isSearchable={false}
          value={value}
          onChange={onChange}
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti={isMulti}
          hideSelectedOptions={hideSelectedOptions}
          backspaceRemovesValue={backspaceRemovesValue}
          menuPlacement="auto"
          styles={{
            menuPortal: (provided, state) => {
              return { ...provided, background: 'pink' }
            }
          }}
        />
      </StyledSelect>
    </>
  );
};
