import React from 'react';
import { Wrapper } from '../Wrapper/Wrapper';
import { CheckboxLabel, StyledCheckbox } from './Checkbox.styles';

export const Checkbox: React.FC<{ onChange: (isToggled: boolean) => void; toggled: boolean }> = ({
	onChange,
	toggled,
}) => {
	return (
		<Wrapper display="flex" alignItems="center">
			<StyledCheckbox onChange={(e) => onChange(!toggled)} id="toggle" type="checkbox" checked={toggled} />
			<CheckboxLabel htmlFor="toggle">Toggle</CheckboxLabel>
		</Wrapper>
	);
};
