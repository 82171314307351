import React from 'react';
import { CONTRACTS_FILTER_TYPES } from '../../Contracts/Contracts.constants';
import { Select } from '../../Select/Select';
import { Wrapper } from '../../Wrapper/Wrapper';

const SelectFilter: React.FC<{
	selectFilterType: (value: CONTRACTS_FILTER_TYPES) => void,
	existingFilters: string[],
	portalSelectorId?: string
}> = ({
	selectFilterType,
	existingFilters,
	portalSelectorId
}) => {
		const options = [
			{
				label: 'Contract Year', value: CONTRACTS_FILTER_TYPES.ContractYears
			},
			{
				label: 'Contract Number', value: CONTRACTS_FILTER_TYPES.ContractNumber
			},
			{
				label: 'Field Area', value: CONTRACTS_FILTER_TYPES.FieldArea
			},
			{
				label: 'Field ID', value: CONTRACTS_FILTER_TYPES.FieldId
			},
			{
				label: 'Factory', value: CONTRACTS_FILTER_TYPES.Factory
			},
			{
				label: 'Crop Health', value: CONTRACTS_FILTER_TYPES.CropHealth
			},
			{
				label: 'YV Risk', value: CONTRACTS_FILTER_TYPES.YvRisk
			},
			{
				label: 'Harvested', value: CONTRACTS_FILTER_TYPES.Harvested
			},
			{ label: 'Flag', value: CONTRACTS_FILTER_TYPES.Flag },
		].filter(option => !existingFilters.includes(option.value))

		return (
			<Wrapper width="130px">
				<Select variant="filter"
					portalSelectorId={portalSelectorId}
					defaultMenuIsOpen={true}
					options={options}
					onChange={
						({ value, label }: { value: CONTRACTS_FILTER_TYPES, label: string }): void => {
							selectFilterType(value)
						}
					} />
			</Wrapper>
		);
	};

export default SelectFilter;