import { format } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../slices/icm/icm';
import DatePicker from '../../DatePicker/DatePicker';
import { Wrapper } from '../../Wrapper/Wrapper';
import { StyledRangeDropdown, StyledRangeInputContainer } from '../ContractsRangeFilter/ContractsRangeFilter.styles';
import GenericFilter from '../GenericFilter/GenericFilter';

const ContractsDateRangeFilter: React.FC<{ fieldLabel: string, values: any, fieldSuffix?: string, children: any }> = ({ fieldLabel, values, fieldSuffix, children }) => {
  const [fieldValues, setFieldValues] = useState<{ from?: string, to?: string }>({ from: values?.from, to: values?.to })

  const dispatch = useDispatch();
  const onClose = () => {
    // Currently updating the filters on the closing of the GenericFilter dropdown
    dispatch(updateFilters({ [fieldLabel]: fieldValues }))
  };

  const getFieldLabel = (fieldLabel: string) => {
    switch (fieldLabel) {
      case 'harvested':
        return "Harvested";
        break;
      default:
        return '';
    }
  }

  return (
    <GenericFilter variant='range' values={fieldValues} fieldLabel={getFieldLabel(fieldLabel)} onClose={onClose}>
      <Wrapper width="160px" display="flex" >
        <StyledRangeDropdown>
          <StyledRangeInputContainer>
            <label htmlFor="field-area-from">From</label>
            <DatePicker
              key={"contract-date-range-from-input"}
              value={fieldValues.from}
              onChange={(newDate: Date) => {
                setFieldValues({ from: format(newDate, 'yyyy-MM-dd'), to: fieldValues.to })
              }}
              dateFormat="dd/MM/yyyy"
            />
          </StyledRangeInputContainer>
          <p style={{ color: 'black' }}>-</p>
          <StyledRangeInputContainer>
            <label htmlFor="field-area-to">To</label>
            <DatePicker
              key={"contract-date-range-to-input"}
              value={fieldValues.to}
              onChange={(newDate: Date) => {
                setFieldValues({ from: fieldValues.from, to: format(newDate, 'yyyy-MM-dd') })
              }}
              dateFormat="dd/MM/yyyy"
            />
          </StyledRangeInputContainer>
        </StyledRangeDropdown>
      </Wrapper>
    </GenericFilter>
  );
};

export default ContractsDateRangeFilter;