import styled, { css } from "styled-components";

export const StyledContractsTableFooter = styled.div`
  ${({ theme }) => css`
		display:flex; 
		justify-content: flex-end; 
		position: sticky;
		bottom: 0;
		background:${theme.colors.grey};
		box-shadow: 0px 0px 10px 0px ${theme.colors.blackLighter};
  `}
`;

export const StyledContractsTableFooterInput = styled.div`
  ${({ theme }) => css`
		margin: 0 ${theme.space.sm};
		padding-left: ${theme.space.sm};
		display: flex;
		font-size: 12px;
		justify-content:flex-start;
		align-items: center;
		width: 40px;
		height: 32px;
		background: ${theme.colors.black};
		cursor: pointer;
  `}
`;

export const StyledContractsTableFooterItem = styled.div`
  ${({ theme }) => css`
		display:flex;
		align-items: center;
		padding:${theme.space.sm} ${theme.space.md};
		border-left: 1px solid ${theme.colors.blackLighter};
		input {
			background: ${theme.colors.black};
			width: 40px; /*TODO: Size this in keeping with theme sizes */
			border: none;
			color: white;
			line-height: 2rem;
			height: ${theme.space.lg};
			margin: 0 ${theme.space.sm};
			text-align: center;
		}
  `}
`;