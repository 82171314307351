import { AnalysisHealthData } from '../../../graphql/queries/analysisHealth/analysisHealth.types';
import { HealthSeriesData } from './HealthChart.types';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getHealthSeriesData = (chartData: AnalysisHealthData[]): HealthSeriesData[] => {
  if (!chartData.length) {
    return [];
  }

  const formattedData = chartData.map((data) => {
    const monthIndex = new Date(data.date).getMonth();
    return {
      y: data.health,
      x: months[monthIndex],
    };
  });

  return months.map((month) => {
    const match = formattedData.find((d) => d.x === month);
    return {
      x: month,
      y: match?.y || null,
    };
  });
};
