import styled, { css } from 'styled-components';
import { FlexboxProps, variant } from 'styled-system';

export const StyledGenericFilter = styled.div<FlexboxProps & { variant?: 'range' }>`
  ${({ theme }) => css`
    background: ${theme.colors.blackLight};
    color: white;
    position: absolute;
    width: 130px;
    display: flex;
    padding: ${theme.space.sm};
    border: 1px solid ${theme.colors.tables.borders};
    ${variant({
      variants: {
        range: {
          width: '160px',
        },
      },
    })}
  `}
`;

export const StyledInputContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    label {
      color: white;
      font-size: 12px;
      padding-bottom: ${theme.space.sm};
    }
    input {
      padding: ${theme.space.sm};
      background: ${theme.colors.blackLight};
      color: white;
      border: 1px solid ${theme.colors.tables.borders};
    }
  `}
`;
