import React, { useState, useRef } from 'react';
import { Wrapper } from '../../Wrapper/Wrapper';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { StyledRangeDropdownTrigger } from '../ContractsRangeFilter/ContractsRangeFilter.styles';
import { StyledGenericFilter } from './GenericFilter.styles';
import ReactSelect from 'react-select';
import { components } from 'react-select';
import { StyledSelect } from '../../Select/Select.styles';
import { EditFilterIcon, TickIcon } from '../../Contracts/ContractsIcons/ContractsIcons';

const GenericFilter: React.FC<{
  fieldLabel: string;
  values: any;
  fieldSuffix?: string;
  children: any;
  onClose?: () => void;
  variant?: 'range';
}> = ({ fieldLabel, values, fieldSuffix, children, onClose, variant }) => {
  // TODO: Allow menu to be open by default. Setting this to "true" and/or setting defaultMenuIsOpen on
  // the react-select component don't seem to work, could be related to the component being portalled
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    if (isOpen && onClose) {
      onClose();
    }
    setIsOpen(!isOpen);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (isOpen) {
      toggleOpen();
    }
  });

  const ValueContainer = (props: any) => (
    <components.ValueContainer {...props}>
      <StyledRangeDropdownTrigger variant={variant}>
        {/* Check if component is a range filter */}
        {values.hasOwnProperty('to') ? (
          <>
            {!values?.to && values?.to === values?.from ? (
              <h4>{fieldLabel}</h4>
            ) : (
              <Wrapper display="flex" flexDirection="column">
                <h4>{fieldLabel}</h4>
                <p>
                  {values.from} {fieldSuffix} - {values.to} {fieldSuffix}{' '}
                </p>
              </Wrapper>
            )}
          </>
        ) : (
          <>
            {values ? (
              <Wrapper display="flex" flexDirection="column">
                <h4>{fieldLabel}</h4>
                <p>{values}</p>
              </Wrapper>
            ) : (
              <h4>{fieldLabel}</h4>
            )}
          </>
        )}
      </StyledRangeDropdownTrigger>
    </components.ValueContainer>
  );
  const Control = (props: any) => (
    <components.Control {...props}>
      <Wrapper
        display="flex"
        onClick={(e: any) => {
          toggleOpen();
          e.target.focus();
        }}
      >
        {props.children}
      </Wrapper>
    </components.Control>
  );

  const CustomMenuComponent = (props: any) => (
    <components.Menu {...props}>
      <Wrapper width="130px" display="flex">
        <StyledGenericFilter variant={variant}>{children}</StyledGenericFilter>
      </Wrapper>
    </components.Menu>
  );
  const Placeholder = () => null;
  const IndicatorsContainer = (props: any) => {
    return (
      <components.IndicatorsContainer {...props}>
        <Wrapper px="sm">{isOpen ? <TickIcon width="10px" /> : <EditFilterIcon width="10px" />}</Wrapper>
      </components.IndicatorsContainer>
    );
  };

  return (
    <div ref={ref}>
      <StyledSelect width="100%" variant="filter">
        <ReactSelect
          closeMenuOnScroll
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          backspaceRemovesValue={false}
          menuIsOpen={isOpen}
          classNamePrefix="select"
          menuPortalTarget={document.getElementById('contracts-page-wrapper')} // TODO: Decide if portalling selects
          onKeyDown={(e: React.KeyboardEvent) => {
            // catch the code of the key pressed
            if (e.key === 'Enter') {
              // Return key pressed
              toggleOpen();
            }
          }}
          components={{
            Menu: CustomMenuComponent,
            ValueContainer,
            Placeholder,
            IndicatorsContainer,
            Control,
          }}
        ></ReactSelect>
      </StyledSelect>
    </div>
  );
};

export default GenericFilter;
