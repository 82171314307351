import React from 'react';
import { AnalysisData } from '../../graphql/queries/analysis/analysis.types';
import { StyledDashboard } from './Dashboard.styles';
import { DashboardCharts } from './DashboardCharts/DashboardCharts';
import { DashboardFilters } from './DashboardFilters/DashboardFilters';

export const Dashboard: React.FC<{ analysisData: AnalysisData }> = ({ analysisData }) => {
  return (
    <StyledDashboard>
      <DashboardFilters analysisData={analysisData} />
      <DashboardCharts analysisData={analysisData} />
    </StyledDashboard>
  );
};
