import React from 'react';
import { DisplayProps, SpaceProps } from 'styled-system';
import { Wrapper } from '../Wrapper/Wrapper';
import { ButtonImage, ButtonType, StyledButton } from './Button.styles';
import DownloadLogo from './download.svg';

export const Button: React.FC<
	{
		as?: 'button' | 'a';
		download?: boolean;
		ext?: string;
		href?: string;
		onClick?: () => void;
		target?: string;
		type?: string;
		variant?: 'light' | 'dark';
		width?: string;
	} & DisplayProps &
		SpaceProps
> = ({
	as = 'button',
	ext,
	download,
	href,
	target,
	children,
	onClick,
	type,
	variant = 'dark',
	width = 'auto',
	...rest
}) => {
	return (
		<StyledButton
			//@ts-ignore
			target={target}
			type={type}
			variant={variant}
			{...(download && { download })}
			as={as}
			{...(href && { href })}
			{...(onClick && { onClick })}
			width={width}
			{...rest}
		>
			{download && <ButtonImage src={DownloadLogo} alt="Download file" />}
			<Wrapper as="span">
				{children}
				{ext && <ButtonType>{ext}</ButtonType>}
			</Wrapper>
		</StyledButton>
	);
};
