import styled, { css } from 'styled-components';
import { FlexboxProps, variant } from 'styled-system';

export const StyledRangeDropdown = styled.div<FlexboxProps>`
  ${({ theme }) => css`
    /* width: 160px; */
    display: flex;
  `}
`;
export const StyledRangeInputContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 50%;
    flex-direction: column;
    label {
      color: white;
      font-size: 12px;
      padding-bottom: ${theme.space.sm};
    }
    input {
      width: 100%;
      padding: ${theme.space.sm};
      background: ${theme.colors.blackLight};
      color: white;
      border: 1px solid ${theme.colors.tables.borders};
    }
  `}
`;
export const StyledRangeInputSeparator = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.blackLight};
  `}
`;
export const StyledRangeDropdownTrigger = styled.div<{ variant?: 'range' }>`
  ${({ theme }) => css`
    width: 100%;
    /* min-height: 39px; Add this height as constant for all filters */
    background: ${theme.colors.white};
    border-radius: none;
    padding: ${theme.space.xs} ${theme.space.sm};
    color: ${theme.colors.blackLight};
    display: flex;
    align-items: center;
    h4,
    p {
      color: white;
    }
    p {
      font-size: 12px;
      color: white;
      background: none;
    }
    input {
      width: 50%;
      background: ${theme.colors.blackLight};
      color: white;
      border: 1px solid ${theme.colors.tables.borders};
    }
    ${variant({
      variants: {
        range: {
          p: {
            fontSize: '9px',
          },
        },
      },
    })}
  `}
`;
