import { SelectOption } from '../../../Select/Select.types';
import { ARMapVisualisation } from '../AnalysisMap';

export const ANALYSIS_MAP_VISUALISATION_OPTIONS: SelectOption<ARMapVisualisation>[] = [
  { label: 'Absolute', value: 'absolute' },
  { label: 'Change', value: 'change' },
];

export type ARMapFrequency = 'monthly' | 'yearly';

export const ANALYSIS_MAP_FREQUENCY_OPTIONS: SelectOption<ARMapFrequency>[] = [
  { label: 'Yearly', value: 'yearly' },
  { label: 'Monthly', value: 'monthly' },
];
