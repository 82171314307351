import React from 'react';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../slices/icm/icm';
import SelectCheckboxes from '../../SelectCheckboxes/SelectCheckboxes';
import { Wrapper } from '../../Wrapper/Wrapper';

const FactoryFilter: React.FC<{ filtersData: { factories: string[] } }> = ({ filtersData }) => {
	const factories: string[] = filtersData?.factories
	//TODO: consolidate all select checkbox filters into 1 component
	const dispatch = useDispatch();
	const handleChange = (selectedOptions: { title: string, value: string }[]) => {
		const values = selectedOptions.map(option => option.value);
		dispatch(updateFilters({ factory: values }))
	}

	return (
		<Wrapper width='130px'>
			<SelectCheckboxes
				placeholder="Factory"
				portalSelectorId='contracts-page-wrapper'
				onChange={handleChange}
				variant="filter"
				options={
					factories.map(factory => {
						return { label: factory, value: factory }
					})
				}
			/>
		</Wrapper>
	);
};

export default FactoryFilter;
