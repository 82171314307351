import React, { useContext } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ThemeContext } from 'styled-components';
import { NoDataMessage } from '../Chart.styles';

export const ChartNoData: React.FC<{ showTicks?: boolean }> = ({ showTicks = true }) => {
  const { colors } = useContext(ThemeContext);
  const noData = [
    // "N"
    [
      { x: 'Jan', y: null },
      { x: 'Feb', y: 1 },
      { x: 'Mar', y: 6 },
      { x: 'Apr', y: 1 },
      { x: 'May', y: 6 },
      { x: 'Jun', y: null },
      { x: 'Jul', y: null },
      { x: 'Aug', y: null },
      { x: 'Sep', y: null },
      { x: 'Oct', y: null },
      { x: 'Nov', y: null },
      { x: 'Dec', y: null },
    ],
    // "/"
    [
      { x: 'Jan', y: null },
      { x: 'Feb', y: null },
      { x: 'Mar', y: null },
      { x: 'Apr', y: null },
      { x: 'May', y: null },
      { x: 'Jun', y: 1 },
      { x: 'Jul', y: 6 },
      { x: 'Aug', y: null },
      { x: 'Sep', y: null },
      { x: 'Oct', y: null },
      { x: 'Nov', y: null },
      { x: 'Dec', y: null },
    ],
    // "/\"
    [
      { x: 'Jan', y: null },
      { x: 'Feb', y: null },
      { x: 'Mar', y: null },
      { x: 'Apr', y: null },
      { x: 'May', y: null },
      { x: 'Jun', y: null },
      { x: 'Jul', y: null },
      { x: 'Aug', y: 1 },
      { x: 'Sep', y: 6 },
      { x: 'Oct', y: 1 },
      { x: 'Nov', y: null },
      { x: 'Dec', y: null },
    ],
    // "--"
    [
      { x: 'Jan', y: null },
      { x: 'Feb', y: null },
      { x: 'Mar', y: null },
      { x: 'Apr', y: null },
      { x: 'May', y: null },
      { x: 'Jun', y: null },
      { x: 'Jul', y: null },
      { x: 'Aug', y: 3 },
      { x: 'Sep', y: 3 },
      { x: 'Oct', y: 3 },
      { x: 'Nov', y: null },
      { x: 'Dec', y: null },
    ],
  ];

  return (
    <>
      <ResponsiveContainer height="100%" width="100%">
        <LineChart margin={{ right: -40, top: -25 }}>
          <XAxis
            tickFormatter={(tick) => (showTicks ? tick : '')}
            dataKey="x"
            allowDuplicatedCategory={false}
            tick={{ fontSize: 12, fill: 'white' }}
            stroke={colors.charts.grid}
            tickLine={{ stroke: 'white' }}
          />
          <YAxis
            ticks={[0, 1, 2, 3, 4, 5, 6, 7]}
            dataKey="y"
            tick={{ fontSize: 12, fill: 'white' }}
            stroke={colors.charts.grid}
            tickLine={{ stroke: 'white' }}
            orientation="left"
            tickFormatter={() => ''}
          />
          <YAxis
            dataKey="y"
            yAxisId="1"
            tick={{ fontSize: 12, fill: 'white' }}
            stroke={colors.charts.grid}
            tickLine={{ stroke: 'white' }}
            orientation="right"
          />

          <CartesianGrid vertical={false} stroke={colors.charts.grid} />
          <Line
            stroke="white"
            strokeDasharray="5 5"
            strokeWidth={1}
            dot={{ display: 'none' }}
            data={noData[0]}
            dataKey="y"
          />
          <Line
            stroke="white"
            strokeDasharray="5 5"
            strokeWidth={1}
            dot={{ display: 'none' }}
            data={noData[1]}
            dataKey="y"
          />
          <Line
            stroke="white"
            strokeDasharray="5 5"
            strokeWidth={1}
            dot={{ display: 'none' }}
            data={noData[2]}
            dataKey="y"
          />
          <Line
            stroke="white"
            strokeDasharray="5 5"
            strokeWidth={1}
            dot={{ display: 'none' }}
            data={noData[3]}
            dataKey="y"
          />
        </LineChart>
      </ResponsiveContainer>
      <NoDataMessage>No data available!</NoDataMessage>
    </>
  );
};
