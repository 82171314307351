import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer, GeoJsonLayer } from '@deck.gl/layers';
import { ScaleQuantile } from 'd3-scale';
import { PickInfo, RGBAColor } from 'deck.gl';
import { noop } from 'lodash';
import { parseToRgb } from 'polished';
import { ARMapControls } from '../../components/Analysis/AnalysisMap/AnalysisMap';
import { ARMapMetadata } from '../../components/Analysis/AnalysisMap/AnalysisMap.types';
import { MapConfig } from '../../graphql/queries/mapConfig/mapConfig.types';
import { InteractiveMap } from '../InteractiveMap/InteractiveMap';
import { AR_CHANGE_SCALE, AR_COLOR_SCALE } from './ARMap.constants';

interface ARMapProps {
  mapRef: HTMLDivElement;
  metadata: ARMapMetadata[];
  canvasRef: HTMLCanvasElement;
  controls: ARMapControls;
  onClick: (name: string | undefined) => void;
  onReady: () => void;
  projectId: string;
  config: MapConfig;
  onMapClick?: (d: PickInfo<any>) => void;
}

export class ARMap {
  private layers: any[];
  private config: MapConfig;
  private projectId: string;
  private map: InteractiveMap;
  private onReady: () => void = noop;
  private changeScale: ScaleQuantile<string>;
  private onClick: (name: string | undefined) => void = noop;

  private shapeFile: any;
  private metadata: ARMapMetadata[];
  private controls: ARMapControls;

  constructor({ controls, canvasRef, mapRef, onClick, onReady, onMapClick, projectId, config, metadata }: ARMapProps) {
    this.onReady = onReady;
    this.onClick = onClick;
    this.projectId = projectId;
    this.metadata = metadata;
    this.config = config;
    this.controls = controls;

    this.map = new InteractiveMap({ mapRef, canvasRef, config, showPointer: true, onClick: onMapClick });
    this.map.onMapReady = () => this.init();
  }

  private async init() {
    // TODO: make better fallback
    if (this.config?.shapeFileUrl) {
      const shapeData = await fetch(this.config.shapeFileUrl);
      this.shapeFile = await shapeData.json();
    } else {
      const shapeData = await fetch('/data/thailand.json');
      this.shapeFile = await shapeData.json();
    }

    this.generateLayers();

    //TODO: ensure no memory leak possible
    setTimeout(() => {
      this.onReady();
    }, 500);
  }

  private updateChangeScale() {
    const selectedYearData = this.metadata.find((m) => m.year === this.controls.selectedYear);
    if (selectedYearData && this.controls.metadataFilter) {
      const minChange = Math.min(
        ...selectedYearData.data.map((d) => {
          //@ts-ignore
          if (d[this.controls.metadataFilter]) {
            //@ts-ignore
            return d[this.controls.metadataFilter].change;
          }
          return 0;
        }),
      );
      //@ts-ignore
      const maxChange = Math.min(
        Math.max(
          ...selectedYearData.data.map((d) => {
            //@ts-ignore
            if (d[this.controls.metadataFilter]) {
              //@ts-ignore
              return d[this.controls.metadataFilter].change;
            }
            return 0;
          }),
        ),
      );

      this.changeScale = AR_CHANGE_SCALE(minChange, maxChange);
    }
  }

  public updateControls(controls: ARMapControls): void {
    this.controls = controls;
    this.updateChangeScale();

    this.generateLayers();
  }

  private getFillColor = (polygon: any): RGBAColor => {
    // if no metadata is applied, return transparent colour
    if (!this.controls.metadataFilter) {
      return [0, 0, 0, 0];
    }

    const selectedYearData = this.metadata.find((m) => m.year === this.controls.selectedYear);

    if (selectedYearData) {
      const currentData = selectedYearData.data.find((d) => d['Province Name'].name === polygon.properties.NAME_1);

      if (currentData) {
        const currentValueByKey = currentData[this.controls.metadataFilter];
        let color;

        if (this.controls.selectedVis === 'change') {
          color = this.changeScale(currentValueByKey.change);
        } else {
          color = AR_COLOR_SCALE(currentValueByKey.current);
        }

        const { red, green, blue } = parseToRgb(color);

        // if mask is visible, turn opacity out but keep values so next transition makes visual sense
        if (this.controls.showMask) {
          return [red, green, blue, 0];
        }

        // if no label is on, return color with full oapcity
        if (!this.controls.activeFilter?.label) {
          return [red, green, blue, 255];
        }

        // otherwise, update opacity based on whether current polygon is active
        const opacity = this.controls.activeFilter.label === polygon.properties.NAME_1 ? 255 : 50;

        return [red, green, blue, opacity];
      }
    }

    return [0, 0, 0, 0];
  };

  private onPolygonClick = (d: any) => {
    this.onClick(d?.object?.properties?.NAME_1);
  };

  private async generateLayers() {
    const { activeFilter, metadataFilter, selectedVis, selectedYear, showMask, selectedTif } = this.controls;
    // const tiffs: string[] = selectedYear ? tiffCollection[selectedYear] : [];
    const layers: any[] = [];

    const geoJSONLayer = new GeoJsonLayer({
      id: 'geojson-layer',
      data: this.shapeFile,
      getLineColor: showMask ? [255, 255, 255, 50] : [255, 255, 255, 255],
      getFillColor: (d) => this.getFillColor(d),
      material: false,
      lineJointRounded: true,
      lineCapRounded: true,
      getLineWidth: 100,
      pickable: true,
      onClick: (d: PickInfo<any>) => this.onPolygonClick(d),
      lineWidthMinPixels: 1,
      updateTriggers: {
        getLineColor: [showMask, this.metadata],
        getFillColor: [selectedYear, showMask, metadataFilter, activeFilter, selectedVis],
      },
      transitions: {
        getLineColor: {
          duration: 300,
          type: 'interpolation',
        },
        getFillColor: {
          duration: 300,
          type: 'interpolation',
        },
      },
    });

    layers.push(geoJSONLayer);

    if (selectedTif) {
      const colourmap = selectedTif.colourmap === 'default' ? 'greens' : selectedTif.colourmap;
      const tifLayer = new TileLayer({
        id: `tif-layer`,
        data: [
          `${process.env.REACT_APP_TILLING_SERVER_ENDPOINT}/singleband/${this.projectId}/${selectedTif.year}/${selectedTif.month}/${selectedTif.type}/${selectedTif.label}/${selectedTif.colourmap}/{z}/{x}/{y}.png?colormap=${colourmap}`,
        ],
        visible: showMask,
        tileSize: 256,
        renderSubLayers: (props) => {
          const {
            bbox: { west, south, east, north },
          } = props.tile;

          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      });
      layers.push(tifLayer);
    }

    this.layers = layers;
    this.map.setLayers(this.layers);
  }
}
